import BgImageRight from "../../../assets/images/gradients_light_teal.png";
const styles = {
  content: {
    height: "100vh",
    "&::before": {
      content: "''",
      backgroundImage: `url(${BgImageRight})`,
      backgroundPosition: 0,
      backgroundRepeat: "no-repeat",
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: "-1",
      transform: "scale(-1)",
    },
  },
};
export default styles;
