import { useNavigate } from "react-router-dom";
import { Footer } from "@client/shared/components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import styles from "./style";

const LoginView = ({
  email = "",
  password = "",
  errors = [],
  isFormValid = false,
  isButtonLoading = false,
  handleFieldChange = () => {},
  handleLogin = () => {},
}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundImage:
          "linear-gradient(60deg, #6DACD7 0%, #94B7BD 30%, #94B7BD 60%, #6DACD7 90%);",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        component="main"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          minHeight: "95%",
        }}
      >
        <Grid
          item
          sm={12}
          md={6}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 6,
            color: "common.white",
          }}
        >
          <Typography variant="h3" sx={{ fontSize: "56px" }}>
            Welcome back!
          </Typography>
          <Box sx={{ p: 7 }}>
            <TextField
              value={email}
              onChange={handleFieldChange}
              margin="dense"
              error={!!errors?.email?.trim()}
              helperText={errors?.email || " "}
              autoComplete="new-password"
              name="email"
              placeholder="Username"
              variant="outlined"
              fullWidth
              sx={styles.input}
            />
            <TextField
              type="password"
              value={password}
              onChange={handleFieldChange}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !!password) {
                  handleLogin(email, password);
                }
              }}
              margin="dense"
              error={!!errors?.password?.trim()}
              helperText={errors?.password || " "}
              autoComplete="new-password"
              name="password"
              placeholder="Password"
              variant="outlined"
              fullWidth
              sx={styles.input}
            />{" "}
            <Button
              sx={{
                textDecoration: "underline",
                color: "common.white",
                display: "inline",
                mb: 1,
              }}
              onClick={() => navigate("/forgot-password")}
              size="large"
            >
              {" "}
              Forgot password?
            </Button>{" "}
            <Button
              variant="contained"
              onClick={() => handleLogin(email, password)}
              fullWidth
              disabled={isFormValid || !email || !password || isButtonLoading}
              sx={{ bgcolor: "primary.dark" }}
              size="large"
            >
              {isButtonLoading ? (
                <CircularProgress color="inherit" size="2rem" />
              ) : (
                "Log In"
              )}
            </Button>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Don't have an account?
              <Button
                sx={{
                  textDecoration: "underline",
                  color: "common.white",
                  display: "inline",
                  mb: "4px",
                }}
                onClick={() => navigate("/")}
                size="large"
              >
                {" "}
                Sign Up
              </Button>{" "}
            </Typography>
          </Box>{" "}
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default LoginView;
