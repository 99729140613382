export * from './styles';

export const THEME_VARIANT = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const CHART_ELEM_TYPE = {
  START: 'start',
  STEP: 'step',
  CONDITION: 'condition',
  STOP: 'stop',
};

export const TOKEN_STORAGE_KEY = 'ac';
export const USER_TOKEN_KEY = 'userToken';
export const REFRESH_TOKEN_STORAGE_KEY = 'refreshToken';
export const USER_CONFIG_STORAGE_KEY = 'usc';
export const noop = () => {};
export const sessionExpireMsg = 'Session expired! Redirecting to login screen..';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please contact system administrator.';
export const DEFAULT_WAIT_MESSAGE = 'It is taking longer than expected, please wait.';
export const ACCESS_DENIED_MESSAGE =
  "You don't have permission to perform this action. Please contact system administrator";

export const PhoneRegexE164 = /^$|^\+\d{11,13}$/;

export const VALIDATIONS = {
  ALPHANUMERIC: 'alphanumeric',
  MAX_LENGTH: 'maxLength',
  MIN_LENGTH: 'minLength',
  MAX: 'max',
  MIN: 'min',
  INTEGER: 'integer',
  FLOAT: 'float',
  NUMBER: 'number',
  REQUIRED: 'required',
  EMAILS: 'emails',
  REGEX: 'regex',
  PHONE: 'phone',
};

export const PAGE_KEYS = {
  ADMIN_USER: 'admin_user',
  ACTIVITY: 'activity',
  USERS: 'users',
  SETTINGS: 'settings',
};

export const REGEX = {
  PHONE: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
  URL: /^$|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
};
export const roleList = [
  'Executive Coach',
  'Principal Consultant',
  'Coach',
  'Admin',
  'Principal Consultant and Executive Coach',
];
export const statesList = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
];
