import http, { jwksApi } from "./api-communicator";
import { responseFormatter } from "@client/utils";
export { default as http } from "./api-communicator";

// let appData = {};

function logoutUser(token) {
  return responseFormatter(
    http.post(`logout`, null, {
      headers: {
        Authorization: token,
      },
    })
  );
}

const getVersion = async () => {
  return responseFormatter(http.get("/version"));
};

const updateUserData = (userId, data) => {
  return responseFormatter(
    http.patch(`/user/${userId}`, data, { setAuthHeader: true })
  );
};
const sync = () => {
  return responseFormatter(http.post(`/sync`, {}, { setAuthHeader: true }));
};

const refreshAccessToken = async (token) => {
  return await responseFormatter(
    http.get("refresh-token", { headers: { Authorization: token } })
  );
};

export const AppService = {
  getVersion,
  logoutUser,
  updateUserData,
  refreshAccessToken,
  sync,
};
