import { Footer } from "@client/shared/components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BgImage from "../../assets/images/kajabi-banner.png";

const ResourcesView = () => {
  return (
    <Box sx={{ height: "100vh" }}>
      <Box sx={{ minHeight: "95%" }}>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <img src="/assets/kajabi-banner.png" height="100%" width="100%" />
          <Box
            sx={{
              position: "absolute",
              bottom: -50,
              right: "40%",
              borderRadius: 2,
            }}
          >
            <img alt="" src="/assets/excelia-x-kajabi.png" height={94} />
          </Box>
        </Box>
        <Box
          sx={{
            p: 6,
            display: "flex",
            alignItems: "center",
            maxWidth: "70%",
            justifyContent: "space-between",
            mt: 4,
          }}
        >
          <Box sx={{ maxWidth: "50%" }}>
            <Typography variant="h3" color="primary" sx={{ fontSize: "50px" }}>
              Resources
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "20px", color: "#3E5060" }}
            >
              Access a variety of resources designed specifically for coaches
              within our Kajabi platform.
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.300",
              color: "primary.dark",
              px: 4,
            }}
            size="large"
            onClick={() =>
              window.open("https://myexcelia.mykajabi.com/login", "_blank")
            }
          >
            ACCESS KAJABI
          </Button>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default ResourcesView;
