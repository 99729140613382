import axios from "axios";
import config from "@client/config";
import {
  getToken,
  getUserToken,
  getRefreshToken,
  replaceToken,
  replaceRefreshToken,
  isJWTExpired,
} from "@client/utils";
import { sessionExpireMsg } from "@client/shared/constants";
import { AppService } from "./index";

let isRefreshing = false;
let refreshSubscribers = [];

const apiInstance = axios.create({
  baseURL: config.apiBaseUrl,
  timeout: 4 * 60000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

apiInstance.interceptors.request.use((config) => {
  if (config.setAuthHeader) {
    const token = getToken();
    config.headers.common["Authorization"] = token;
  }
  if (config.setUserAuthHeader) {
    const userToken = getUserToken();
    config.headers.common["Authorization"] = userToken;
  }
  if (!!config.rcAccountId) {
    config.headers.common["RcAccountId"] = config.rcAccountId;
  }
  if (config.setTimeout) {
    config.timeout = config.setTimeout;
  }

  return config;
});

// Add a request interceptor
apiInstance.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    const originalRequest = config;
    let token = getToken();
    let refreshToken = getRefreshToken();
    if (
      isJWTExpired(token, false, 5) &&
      !/login/i.test(originalRequest.url) &&
      !/profile-image/i.test(originalRequest.url) &&
      !/version/i.test(originalRequest.url) &&
      !/products/i.test(originalRequest.url) &&
      !/signup/i.test(originalRequest.url) &&
      !/subscription/i.test(originalRequest.url) &&
      !/payment-method/i.test(originalRequest.url) &&
      !/reset-password/i.test(originalRequest.url)
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        const { data } = await AppService.refreshAccessToken(refreshToken);

        if (!data || !data.accessToken)
          return Promise.reject({ message: sessionExpireMsg });

        replaceToken(data.accessToken);
        isRefreshing = false;
        onRrefreshed(data.accessToken);
        if (data.refreshToken) {
          replaceRefreshToken(data.refreshToken);
        }
      }

      subscribeTokenRefresh((token) => {
        // replace the expired token and retry
        originalRequest.headers["Authorization"] = token;
        return originalRequest;
      });

      return config;
    } else {
      return config;
    }
  },
  (error) => {
    // Do something with request error
    return Promise.reject(sessionExpireMsg);
  }
);

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRrefreshed = (token) => {
  refreshSubscribers.forEach((cb) => {
    cb(token);
  });
  refreshSubscribers = [];
};

const http = {
  ...apiInstance,
};

export default http;
