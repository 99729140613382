import { noop } from "@client/shared/constants";
import { Footer } from "@client/shared/components";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PaymentMethod from "./payment-method";
import BgImage from "../../assets/images/background-top.png";

let paymentReference = null;
const AddPaymentView = ({
  isPaymentButtonLoading = false,
  handleAttatchPaymentMethod = noop,
}) => {
  return (
    <Box sx={{ height: "100vh" }}>
      <Box sx={{ minHeight: "95%" }}>
        <Box
          sx={{
            height: "200px",
            backgroundImage: `url(${BgImage})`,
            backgroundSize: "100% 100%",
            position: "relative",
          }}
        ></Box>
        <Box
          sx={{
            padding: "36px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography color="primary" variant="h3" sx={{ fontSize: "38px" }}>
            Add payment method to MyExcelia.
          </Typography>
          <Box
            component={Paper}
            elevation={2}
            sx={{
              backgroundColor: "grey.light",
              minHeight: "500px",
              maxWidth: "700px",
              minWidth: "700px",
              mt: "50px",
              padding: "24px 42px 32px 24px",
            }}
          >
            <Typography sx={{ fontWeight: "medium" }} color="primary">
              Payment Method{" "}
            </Typography>
            <Box>
              <PaymentMethod
                handleAttatchPaymentMethod={handleAttatchPaymentMethod}
                isPaymentButtonLoading={isPaymentButtonLoading}
              />
            </Box>
          </Box>
        </Box>{" "}
      </Box>
      <Footer />
    </Box>
  );
};

export default AddPaymentView;
