import { http } from "@client/shared/services";
import { responseFormatter } from "@client/utils";

const createUser = (payload) => {
  return responseFormatter(http.post(`/signup`, payload));
};
const createSubscription = (payload, isLoggedIn = false) => {
  const header = isLoggedIn
    ? {
        setAuthHeader: true,
      }
    : {
        setUserAuthHeader: true,
      };
  return responseFormatter(http.post(`/subscription`, payload, header));
};

const Services = {
  createUser,
  createSubscription,
};

export default Services;
