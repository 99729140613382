import { http } from '@client/shared/services';
import { responseFormatter } from '@client/utils';

const getOrganizations = (queryString = '') => {
  return responseFormatter(
    http.get(`/organizations${queryString}`, {
      setAuthHeader: true,
    })
  );
};
const getAnalysisType = () => {
  return responseFormatter(
    http.get(`/analysis-type`, {
      setAuthHeader: true,
    })
  );
};

const create = (payload) => {
  return responseFormatter(
    http.post(`/organization/internal`, payload, {
      setAuthHeader: true,
    })
  );
};

const patch = (payload, orgId = '') => {
  return responseFormatter(
    http.patch(`/organization/${orgId}`, payload, {
      setAuthHeader: true,
    })
  );
};

const Service = {
  getOrganizations,
  create,
  patch,
  getAnalysisType,
};

export default Service;
