import { Grid, Footer } from "@client/shared/components";
import { noop } from "@client/shared/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import BgImage from "../../assets/images/background-top.png";
import CloseIcon from "@mui/icons-material/Close";
import SurveySuccessView from "./success";
import SurveyViewModule from "./view-survey";
import RescheduleSuccess from "./reschedule-success";
import MoneyTalkView from "./money-talk";
import PendingLinksDialog from "./pending-links";
const SurveysView = ({
  rows = [],
  columnConfig = [],
  pageNumber = 1,
  pageSize = 5,
  order = null,
  orderBy = "",
  totalRows = 0,
  isLoading = false,
  isSurveyLoading = false,
  isButtonLoading = false,
  isSurveySuccess = false,
  isSurveySent = false,
  surveyLink = "",
  availableSurveyCount = 0,
  isLinkCopied = false,
  isConfirmationOpen = false,
  isViewOpen = false,
  isRescheduleOpen = false,
  rescheduleDetails = {},
  currentRow = {},
  isRescheduleButtonLoading = false,
  viewColumnConfig = [],
  surveyDetails = [],
  viewPageNumber = 1,
  viewPageSize = 5,
  viewTotalRows = 0,
  viewOrder = null,
  viewOrderBy = "",
  isMoneyTalkOpen = false,
  moneyTalkData = [],
  isMoneyTalkLoading = false,
  isLinksDialogOpen = false,
  surveyLinks = [],
  linksColumnConfig = [],
  isLinksLoading = false,
  handleConfirmationDialog = noop,
  handlePageNumberChange = noop,
  handlePageSizeChange = noop,
  handleSortChange = noop,
  handleSelectionChange = noop,
  handleGridReady = noop,
  handleSendSurvey = noop,
  handleSuccessClose = noop,
  handleCopiedLink = noop,
  handleFieldChange = noop,
  handleRescheduleDialog = noop,
  handleRescheduleSurvey = noop,
  handleViewSurvey = noop,
  handleViewPageNumberChange = noop,
  handleViewPageSizeChange = noop,
  handleViewSortChange = noop,
  handleMoneyTalkDialog = noop,
  handleLinksDialog = noop,
}) => {
  return (
    <Box sx={{ height: "100vh" }}>
      <Box sx={{ minHeight: "95%" }}>
        <Box
          sx={{
            height: "200px",
            backgroundImage: `url(${BgImage})`,
            backgroundSize: "100% 100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              backgroundColor: "grey.light",
              boxShadow: 1,
              borderRadius: 2,
              position: "absolute",
              right: "10%",
              bottom: "-50%",
            }}
          >
            <Typography color="primary" variant="body1">
              REMAINING SURVEYS IN YOUR PLAN
            </Typography>
            <Typography
              color="primary"
              // variant="title"
              sx={{ mt: 2, mb: 2, fontSize: "48px" }}
            >
              {isLoading ? (
                <Skeleton height={54} width={32} />
              ) : (
                availableSurveyCount
              )}
            </Typography>{" "}
            {availableSurveyCount >= "1" ? (
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  boxShadow: 2,
                  backgroundColor: "white",
                  maxWidth: "90%",
                  mb: 1,
                }}
                disabled={isButtonLoading || !availableSurveyCount}
                onClick={handleSendSurvey}
              >
                {isButtonLoading ? (
                  <CircularProgress color="inherit" size="2rem" />
                ) : (
                  "SEND NEW SURVEY"
                )}
              </Button>
            ) : (
              <>
                {isLoading ? (
                  <Skeleton height={54} width="100%" />
                ) : (
                  <Typography
                    color="primary"
                    sx={{ textAlign: "center", fontWeight: "medium" }}
                  >
                    Need additional surveys?
                    <br /> Contact us at{" "}
                    <a href="mailto:support@myexcelia.com">
                      <br /> support@myexcelia.com
                    </a>
                  </Typography>
                )}
              </>
            )}{" "}
            <Tooltip
              title="Surveys that were created, but haven't been sent yet."
              placement="top"
            >
              <Typography
                color="primary"
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  pt: 1,
                }}
                onClick={() => handleLinksDialog(true)}
              >
                Pending Surveys
              </Typography>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={{
            pt: 6,
            pl: 10,
            pr: 10,
          }}
        >
          <Typography color="primary" variant="h3">
            Surveys
          </Typography>
          <Typography
            color="primary"
            variant="body1"
            sx={{ color: "#3E5060", mt: 1 }}
          >
            When your surveys have been completed, email{" "}
            <a
              href="mailto:support@myexcelia.com"
              style={{ textDecoration: "none" }}
            >
              {" "}
              support@myexcelia.com
            </a>{" "}
            to request your report.
          </Typography>
          <Box
            component={Paper}
            elevation={2}
            sx={{
              mt: 6,
              p: 3,
              borderRadius: 2,
            }}
          >
            <Grid
              hasPagination={true}
              hasSelection={false}
              rows={rows}
              totalRows={totalRows}
              isLoading={isLoading}
              columns={columnConfig}
              pageSize={pageSize}
              pageNumber={pageNumber}
              order={order}
              orderBy={orderBy}
              onPageNumberChange={handlePageNumberChange}
              onPageSizeChange={handlePageSizeChange}
              onSortChange={handleSortChange}
              onSelectionChange={handleSelectionChange}
              onReady={handleGridReady}
              hasSelectAll={false}
              hasSelectMultiple={false}
              classes={{
                container: { "max-height": "440px !important" },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Footer />
      {/* Success Survey Dialog */}
      <SurveySuccessView
        isSurveySent={isSurveySent}
        surveyLink={surveyLink}
        isLinkCopied={isLinkCopied}
        handleSuccessClose={handleSuccessClose}
        handleCopiedLink={handleCopiedLink}
      />
      {/* Cancel Survey Dialog */}
      {!!isConfirmationOpen && (
        <Dialog open={isConfirmationOpen} maxWidth="lg">
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {" "}
            <Typography sx={{ mt: 1, fontWeight: "bold" }}>
              CANCEL SURVEY
            </Typography>
            <IconButton onClick={() => handleConfirmationDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              minHeight: "100px",
            }}
          >
            <Typography variant="h5" sx={{ mt: 2, color: "common.black" }}>
              Are you sure you want to cancel the selected Survey?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button
              sx={{ color: "grey.500" }}
              onClick={() => handleConfirmationDialog(false)}
            >
              Close
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "error.main" }}
              disabled={isRescheduleButtonLoading}
              onClick={() => handleRescheduleSurvey({}, currentRow, "Cancel")}
            >
              {" "}
              {isRescheduleButtonLoading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                "Cancel Survey"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* Reschedule Survey Dialog */}
      {!!isRescheduleOpen && (
        <Dialog open={isRescheduleOpen} maxWidth="lg">
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {" "}
            <Typography sx={{ mt: 1, fontWeight: "bold" }}>
              RESCHEDULE SURVEY
            </Typography>
            <IconButton onClick={() => handleRescheduleDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              minWidth: "500px",
              minHeight: "300px",
              mt: 2,
              p: 4,
            }}
          >
            <Typography sx={{ mb: 2 }} variant="body1">
              Reschedule date:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Select Reschedule date"
                inputFormat="MM/dd/yyyy"
                value={
                  rescheduleDetails.rescheduleDate
                    ? rescheduleDetails.rescheduleDate
                    : null
                }
                onChange={(date) =>
                  handleFieldChange(
                    {
                      name: "rescheduleDate",
                      value: date,
                    },
                    "date"
                  )
                }
                minDate={new Date()}
                fullWidth
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
            <Typography sx={{ mt: 3, mb: 1 }} variant="body1">
              Notes
            </Typography>
            <TextField
              size="small"
              margin="dense"
              placeholder="Notes"
              value={rescheduleDetails.notes || ""}
              name="notes"
              fullWidth
              multiline
              rows={3}
              onChange={(event) => handleFieldChange(event)}
            />
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button
              sx={{ color: "grey.500" }}
              onClick={() => handleRescheduleDialog(false)}
            >
              Close
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "primary.main" }}
              disabled={
                isRescheduleButtonLoading || !rescheduleDetails.rescheduleDate
              }
              onClick={() =>
                handleRescheduleSurvey(rescheduleDetails, currentRow)
              }
            >
              {isRescheduleButtonLoading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                "Reschedule Survey"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/*View each Survey Dialog */}
      <SurveyViewModule
        isViewOpen={isViewOpen}
        currentRow={currentRow}
        isSurveyLoading={isSurveyLoading}
        surveyDetails={surveyDetails}
        handleViewSurvey={handleViewSurvey}
        columnConfig={viewColumnConfig}
        viewPageNumber={viewPageNumber}
        viewPageSize={viewPageSize}
        viewOrder={viewOrder}
        viewOrderBy={viewOrderBy}
        viewTotalRows={viewTotalRows}
        handleViewPageNumberChange={handleViewPageNumberChange}
        handleViewPageSizeChange={handleViewPageSizeChange}
        handleViewSortChange={handleViewSortChange}
      />
      {/*Money Talk Link Dialog */}
      <MoneyTalkView
        isMoneyTalkOpen={isMoneyTalkOpen}
        isSurveyLoading={isSurveyLoading}
        moneyTalkData={moneyTalkData}
        isMoneyTalkLoading={isMoneyTalkLoading}
        isLinkCopied={isLinkCopied}
        handleMoneyTalkDialog={handleMoneyTalkDialog}
        handleCopiedLink={handleCopiedLink}
      />

      {/* Reschedule Success Dialog */}
      <RescheduleSuccess
        isSurveySuccess={isSurveySuccess}
        handleSuccessClose={handleSuccessClose}
      />
      {isLinksDialogOpen && (
        <PendingLinksDialog
          isLinksDialogOpen={isLinksDialogOpen}
          handleLinksDialog={handleLinksDialog}
          surveyLinks={surveyLinks}
          columnConfig={linksColumnConfig}
          isLoading={isLinksLoading}
        />
      )}
    </Box>
  );
};

export default SurveysView;
