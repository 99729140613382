import { noop } from "@client/shared/constants";
import { Footer } from "@client/shared/components";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MyClientsGrid from "./clients-grid";
import BgImageLeft from "../../assets/images/gradients_light_teal.png";
import BgImageTop from "../../assets/images/gradients_bright_blue.png";
const MyClientsView = ({
  rows = [],
  isLoading = false,
  pageNumber = 1,
  pageSize = 5,
  order = null,
  orderBy = "",
  totalRows = 0,
  handlePageNumberChange = noop,
  handlePageSizeChange = noop,
  handleSortChange = noop,
  handleSelectionChange = noop,
  handleGridReady = noop,
  handleSearch = noop,
}) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${BgImageLeft}), url(${BgImageTop})`,
        backgroundPosition: "left bottom, right top",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        pt: 6,
        pl: 10,
      }}
    >
      <Box sx={{ minHeight: "95%" }}>
        <Typography color="primary" variant="h3">
          My Clients
        </Typography>
        <MyClientsGrid
          isClientsPage={true}
          rows={rows}
          pageNumber={pageNumber}
          pageSize={pageSize}
          order={order}
          orderBy={orderBy}
          isLoading={isLoading}
          totalRows={totalRows}
          handlePageNumberChange={handlePageNumberChange}
          handlePageSizeChange={handlePageSizeChange}
          handleSortChange={handleSortChange}
          handleSelectionChange={handleSelectionChange}
          handleGridReady={handleGridReady}
          handleSearch={handleSearch}
        />{" "}
      </Box>
      <Footer />
    </Box>
  );
};

export default MyClientsView;
