const styles = {
  input: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white !important",
    },
    borderRadius: "10px",
    marginBottom: "18px",
  },
};
export default styles;
