import { Footer } from "@client/shared/components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import styles from "./style";

const ResetPassowordView = ({
  password = "",
  confirmPassword = "",
  isButtonLoading = false,
  errors = {},
  isFormValidated = false,
  handleFieldChange = () => {},
  handleResetPassword = () => {},
}) => {
  return (
    <Box
      sx={{
        backgroundImage:
          "linear-gradient(60deg, #6DACD7 0%, #94B7BD 30%, #94B7BD 60%, #6DACD7 90%);",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        component="main"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          minHeight: "95%",
        }}
      >
        <Grid
          item
          sm={12}
          md={6}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            color: "common.white",
          }}
        >
          <Typography variant="h4" sx={{ fontSize: "42px" }}>
            Reset Password
          </Typography>
          <Box sx={{ p: 3 }}>
            <TextField
              type="password"
              value={password}
              onChange={handleFieldChange}
              margin="dense"
              name="password"
              placeholder="Password"
              variant="outlined"
              autoComplete="new-password"
              error={!!errors.password?.trim()}
              helperText={errors.password || " "}
              fullWidth
              sx={styles.input}
            />
            <TextField
              type="password"
              value={confirmPassword}
              onChange={handleFieldChange}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !!confirmPassword) {
                  handleResetPassword(password, confirmPassword);
                }
              }}
              margin="dense"
              name="confirmPassword"
              placeholder="Confirm Password"
              variant="outlined"
              autoComplete="new-password"
              error={!!errors.confirmPassword?.trim()}
              helperText={errors.confirmPassword || " "}
              fullWidth
              sx={styles.input}
            />{" "}
            <Button
              variant="contained"
              onClick={() => handleResetPassword(password, confirmPassword)}
              fullWidth
              disabled={
                !confirmPassword ||
                !password ||
                isButtonLoading ||
                !isFormValidated
              }
              sx={{ mt: 2, bgcolor: "primary.dark" }}
              size="large"
            >
              {isButtonLoading ? (
                <CircularProgress color="inherit" size="2rem" />
              ) : (
                "Reset Password"
              )}
            </Button>
          </Box>{" "}
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default ResetPassowordView;
