import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { VALIDATIONS, USER_CONFIG_STORAGE_KEY } from '@client/shared/constants';
import { validate as validator } from '@client/utils';
import MyAccountView from './view';
import Services from './service';
import ProductServices from '../subscriptions/service';
import SubscriptionServices from '../details/service';
import { REGEX } from 'src/client/shared/constants';

const defaultState = {
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role: '',
    website: '',
  },
  fullName: '',
  billingDetails: {
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    state: '',
  },
  initialBillingDetails: {},
  errors: {},
  billingErrors: {},
  isLoading: false,
  isUploading: false,
  isBillingDialogOpen: false,
  isSaveLoading: false,
  isCancelLoading: false,
  isEditPlanOpen: false,
  isProductsLoading: false,
  products: [],
  choosenPlan: {},
  isFormChanged: false,
  isConfirmationOpen: false,
  paymentMethods: {},
  isSwitchConfirmationOpen: false,
  isSwitchConfirmationLoading: false,
  isRemoveLoading: false,
  hasBillingFormChanged: false,
  isPaymentSettled: false,
  showChangePasswordDialog: false,
  isLeavingPage: false,
  isCancelConfirmationOpen: false,
  isCancelConfirmed: false,
};

const MyAccountModule = () => {
  const [state, setState] = useState(defaultState);
  const navigate = useNavigate();

  //Fetch User Details
  const fetchUser = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { data, error } = await Services.get();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    if (data.user.id) {
      localStorage.setItem(USER_CONFIG_STORAGE_KEY, JSON.stringify(data.user));
    }
    return setState((prevState) => ({
      ...prevState,
      userDetails: data.user,
      fullName: data.user?.firstName + ' ' + data.user?.lastName,
      billingDetails: data.user?.address,
      initialBillingDetails: data.user?.address,
      isLoading: false,
    }));
  }, []);

  //Fetch Subscriptions
  const fetchProducts = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isProductsLoading: true,
    }));
    const { data, error } = await ProductServices.getSubscriptions();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isProductsLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      products: data.products,
      isProductsLoading: false,
    }));
  }, []);

  //Fetch Payment Mthods
  const fetchPaymentMethods = useCallback(async () => {
    const { data, error } = await Services.getPaymentMethods();
    if (error) {
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      paymentMethods: data.stripePaymentMethod,
    }));
  }, []);

  //Edit User Details (Basic Info and Billing Info)
  const handleEditUser = useCallback(async (payload) => {
    setState((prevState) => ({
      ...prevState,
      isSaveLoading: true,
    }));
    const { confirmPassword, ...payloadData } = payload;
    const { data, error } = await Services.patchUser(payloadData);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isSaveLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      isSaveLoading: false,
      isBillingDialogOpen: false,
      isFormChanged: false,
    }));
    fetchUser();
    return toast.success('User Details updated successfully.');
  }, []);

  //Update Subscription plan
  const handleUpdatePlan = useCallback(async (product, firstTimeUser = false, hasNoPlan = false) => {
    localStorage.setItem('productId', JSON.stringify(product));
    if (hasNoPlan) {
      return handleCreateSubscription(product);
    }
    const payload = { productId: product.id };
    if (firstTimeUser) {
      return navigate('/details/existing');
    }
    setState((prevState) => ({
      ...prevState,
      isSaveLoading: true,
      choosenPlan: product,
      isSwitchConfirmationLoading: true,
    }));
    const { data, error } = await Services.patchPlan(payload);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isSaveLoading: false,
        isSwitchConfirmationLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    if (data?.upgradedSubscription?.clientSecret && data?.upgradedSubscription?.paymentMethodId) {
      sessionStorage.setItem('subscription', JSON.stringify(data?.upgradedSubscription));
      return navigate('/details/upgrade');
    }
    setState((prevState) => ({
      ...prevState,
      isSaveLoading: false,
      isEditPlanOpen: false,
      isSwitchConfirmationOpen: false,
      isSwitchConfirmationLoading: false,
      choosenPlan: defaultState.choosenPlan,
    }));
    fetchUser();
    toast.success(
      `Your plan has been switched to ${product.productName}. New plan will be activated once the current plan expires.`
    );
  }, []);

  const handleCreateSubscription = useCallback(async (product) => {
    setState((prevState) => ({
      ...prevState,
      isSwitchConfirmationLoading: true,
    }));
    const payload = {
      productId: product.id,
    };
    const { data, error } = await SubscriptionServices.createSubscription(payload, true);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isSwitchConfirmationLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    if (!data.subscription?.clientSecret && data.subscription.amount == 0) {
      setTimeout(() => fetchUser(), 3000);
      fetchProducts();
      return setState((prevState) => ({
        ...prevState,
        isPaymentSettled: true,
        isEditPlanOpen: false,
        isSwitchConfirmationOpen: false,
        isSwitchConfirmationLoading: false,
        choosenPlan: product,
      }));
    }
    if (data.subscription?.clientSecret && data.subscription?.paymentMethodId) {
      sessionStorage.setItem('subscription', JSON.stringify(data?.subscription));
    }
    setState((prevState) => ({
      ...prevState,
      isSaveLoading: false,
      isEditPlanOpen: false,
      isSwitchConfirmationOpen: false,
      choosenPlan: defaultState.choosenPlan,
    }));
    return navigate('/details/new');
  });

  //Reactivate Subscription plan
  const handleReactivatePlan = useCallback(async (id) => {
    setState((prevState) => ({
      ...prevState,
      isSwitchConfirmationLoading: true,
    }));
    const { data, error } = await Services.reactivatePlan({ id });
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isSwitchConfirmationLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      isEditPlanOpen: false,
      isSwitchConfirmationOpen: false,
      isSwitchConfirmationLoading: false,
    }));
    fetchUser();
    toast.success(`Your plan has been reactivated.`);
  }, []);

  //Cancel Subscription plan
  const handleCancelPlan = useCallback(async (id, isCancellingScheduled = false) => {
    setState((prevState) => ({
      ...prevState,
      isCancelLoading: true,
    }));
    const api = isCancellingScheduled ? 'cancelScheduledPlan' : 'cancelPlan';
    const { data, error } = await Services[api]({ id });
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isCancelLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      isCancelLoading: false,
      isEditPlanOpen: false,
    }));
    fetchUser();
    handleCancelDialog();
    return toast.success(
      `Your ${isCancellingScheduled ? 'Scheduled' : ''} subscription has been cancelled successfully!`
    );
  }, []);

  //Endpoint to remove payment method
  const handleRemoveCard = useCallback(async (id = '') => {
    setState((prevState) => ({
      ...prevState,
      isRemoveLoading: true,
    }));
    const { data, error } = await Services.removePaymentMethod({ id });
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isRemoveLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    fetchPaymentMethods();
    // fetchUser();
    setState((prevState) => ({
      ...prevState,
      isRemoveLoading: false,
      isConfirmationOpen: false,
      // isBillingDialogOpen: false,
    }));

    return toast.success('Payment Method removed successfully!');
  }, []);

  const handleUploadPicture = useCallback(async (event) => {
    let selectedFile = null;
    if (event.currentTarget.files && event.currentTarget.files?.length > 0) {
      selectedFile = event.currentTarget.files[0];
    }
    if (!selectedFile) {
      return;
    }
    var formData = new FormData();
    formData.append('file', selectedFile);
    setState((prevState) => ({
      ...prevState,
      isUploading: true,
    }));
    const { data, error } = await Services.uploadPicture(formData);
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error);
      setState((prevState) => ({
        ...prevState,
        isUploading: false,
      }));
    } else {
      toast.success('File Uploaded successfully.');
      setState((prevState) => ({
        ...prevState,
        isUploading: false,
      }));
      fetchUser();
    }
  }, []);

  const handleFieldChange = (evt, type = '') => {
    const { name, value } = type === 'dropdown' ? evt : evt.currentTarget || evt.target;
    let errorMessage = validateFields(name, value) || ' ';
    // if (
    //   name === "confirmPassword" &&
    //   value &&
    //   state.userDetails?.password !== value
    // ) {
    //   errorMessage = "Password and Confirm Password must match";
    // }
    setState((prevState) => ({
      ...prevState,
      userDetails: {
        ...prevState.userDetails,
        [name]: value,
      },
      isFormChanged: true,
      errors: {
        ...prevState.errors,
        [name]: errorMessage,
      },
    }));
  };
  const handleBillingDialog = (value) => {
    setState((prevState) => ({
      ...prevState,
      isBillingDialogOpen: value,
      hasBillingFormChanged: false,
      billingDetails: state.initialBillingDetails,
      billingErrors: defaultState.billingErrors,
    }));
  };
  const handleCancelDialog = (value) => {
    setState((prevState) => ({
      ...prevState,
      isCancelConfirmationOpen: value,
    }));
  };
  const handleNext = (value) => {
    setState((prevState) => ({
      ...prevState,
      isCancelConfirmed: value,
    }));
  };
  const handleEditPlanDialog = (value) => {
    value && fetchProducts();
    setState((prevState) => ({
      ...prevState,
      isEditPlanOpen: value,
    }));
  };

  const handleBillingFieldChange = (evt, type = '') => {
    const { name, value } = type === 'dropdown' ? evt : evt.currentTarget || evt.target;
    let errorMessage = validateFields(name, value, true) || ' ';
    setState((prevState) => ({
      ...prevState,
      billingDetails: {
        ...prevState.billingDetails,
        [name]: value,
      },
      billingErrors: {
        ...prevState.errors,
        [name]: errorMessage,
      },
      hasBillingFormChanged: true,
    }));
  };
  const handleConfirmationDialog = (value) => {
    setState((prevState) => ({
      ...prevState,
      isConfirmationOpen: value,
      isPaymentSettled: false,
    }));
  };
  const handleSwitchConfirmation = (value, product) => {
    setState((prevState) => ({
      ...prevState,
      isSwitchConfirmationOpen: value,
      choosenPlan: product,
    }));
  };

  const validateFields = (field, value, isBilling = false) => {
    let errorMessage = '';
    const fieldValidatorMap = {
      firstName: [{ type: VALIDATIONS.REQUIRED, value: true }],
      lastName: [{ type: VALIDATIONS.REQUIRED, value: true }],
      addressLine1: [{ type: VALIDATIONS.REQUIRED, value: true }],
      addressLine2: [],
      zipCode: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 10 },
      ],
      state: [{ type: VALIDATIONS.REQUIRED, value: true }],
      phoneNumber: [
        { type: VALIDATIONS.REGEX, value: new RegExp(REGEX.PHONE) },
        // { type: VALIDATIONS.MIN_LENGTH, value: 10 },
      ],
      website: [{ type: VALIDATIONS.REGEX, value: new RegExp(REGEX.URL) }],
      role: [],
    };
    if (fieldValidatorMap[field]) {
      const validationResult = fieldValidatorMap[field].map((validation) =>
        validator(value, validation.type, validation.value, validation.inputType || 'string', validation.message)
      );
      errorMessage = validationResult.filter((error) => !error?.isValid).map((error) => error?.errorMessage)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validateFields(key, !isBilling ? state.userDetails[key] : state.billingDetails[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }
    return errorMessage;
  };

  const handleChangePasswordDialog = (show = false, isSubmitted = false) => {
    isSubmitted && fetchUser();
    setState((prevState) => ({
      ...prevState,
      showChangePasswordDialog: show,
    }));
  };
  const handleLeavePage = (value = false) => {
    setState((prevState) => ({
      ...prevState,
      isLeavingPage: value,
    }));
  };

  useEffect(() => {
    fetchUser();
    fetchPaymentMethods();
  }, []);
  const isFormValidated = !validateFields();
  const isBillingFormValidated = !validateFields('', '', true);
  return (
    <MyAccountView
      userDetails={state.userDetails}
      fullName={state.fullName}
      billingDetails={state.billingDetails}
      billingErrors={state.billingErrors}
      errors={state.errors}
      isLoading={state.isLoading}
      isSaveLoading={state.isSaveLoading}
      isCancelLoading={state.isCancelLoading}
      isBillingDialogOpen={state.isBillingDialogOpen}
      isEditPlanOpen={state.isEditPlanOpen}
      products={state.products}
      isProductsLoading={state.isProductsLoading}
      choosenPlan={state.choosenPlan}
      isFormChanged={state.isFormChanged}
      isUploading={state.isUploading}
      isFormValidated={isFormValidated}
      isBillingFormValidated={isBillingFormValidated}
      isConfirmationOpen={state.isConfirmationOpen}
      isRemoveLoading={state.isRemoveLoading}
      paymentMethods={state.paymentMethods}
      isSwitchConfirmationLoading={state.isSwitchConfirmationLoading}
      isSwitchConfirmationOpen={state.isSwitchConfirmationOpen}
      hasBillingFormChanged={state.hasBillingFormChanged}
      isPaymentSettled={state.isPaymentSettled}
      showChangePasswordDialog={state.showChangePasswordDialog}
      isLeavingPage={state.isLeavingPage}
      isCancelConfirmationOpen={state.isCancelConfirmationOpen}
      isCancelConfirmed={state.isCancelConfirmed}
      handleCancelDialog={handleCancelDialog}
      handleFieldChange={handleFieldChange}
      handleBillingDialog={handleBillingDialog}
      handleBillingFieldChange={handleBillingFieldChange}
      handleEditUser={handleEditUser}
      handleEditPlanDialog={handleEditPlanDialog}
      handleUpdatePlan={handleUpdatePlan}
      handleCancelPlan={handleCancelPlan}
      handleUploadPicture={handleUploadPicture}
      handleConfirmationDialog={handleConfirmationDialog}
      handleRemoveCard={handleRemoveCard}
      handleSwitchConfirmation={handleSwitchConfirmation}
      handleReactivatePlan={handleReactivatePlan}
      onToggleChangePasswordDialog={handleChangePasswordDialog}
      handleLeavePage={handleLeavePage}
      handleNext={handleNext}
    />
  );
};

export default MyAccountModule;
