import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import format from "date-fns/format";
import { Dropdown } from "@client/shared/components";
import { queryStringBuilder } from "@client/utils";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SurveysView from "./view";
import Services from "./service";

let gridHelper = null;

const defaultState = {
  surveys: [],
  surveyDetails: [],
  isLoading: false,
  isSurveyLoading: false,
  isButtonLoading: false,
  isSurveySent: false,
  isSurveySuccess: false,
  surveyLink: "",
  availableSurveyCount: 0,
  isLinkCopied: false,
  pageNumber: 1,
  pageSize: 5,
  totalRows: 0,
  order: null,
  orderBy: "",
  isViewOpen: false,
  viewPageNumber: 1,
  viewPageSize: 5,
  viewTotalRows: 0,
  viewOrder: null,
  viewOrderBy: "",
  filters: [],
  selectedRow: null,
  isDialogOpen: false,
  isFiltering: false,
  isConfirmationOpen: false,
  isRescheduleOpen: false,
  rescheduleDetails: {},
  isMoneyTalkOpen: false,
  moneyTalkData: [],
  isMoneyTalkLoading: false,
  isLinksDialogOpen: false,
  surveyLinks: [],
  isLinksLoading: false,
};

const Surveys = () => {
  const [state, setState] = useState(defaultState);
  //Fetch Surveys
  const fetchSurveys = useCallback(
    async (
      pageNumber = 1,
      pageSize = 5,
      order = "",
      orderBy = "",
      filters = []
    ) => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const queryString = queryStringBuilder(
        pageSize,
        (pageNumber - 1) * pageSize,
        [],
        filters,
        { field: orderBy, order }
      );
      const { data, error } = await Services.getSurveys(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].error || error[0].message : error
        );
      }
      setState((prevState) => ({
        ...prevState,
        surveys: data.surveys,
        totalRows: data.count,
        availableSurveyCount: data.availableSurveyCount,
        isLoading: false,
      }));
    },
    []
  );
  const fetchPendingLinks = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isLinksLoading: true,
    }));
    const { data, error } = await Services.getSurveyqLinks();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLinksLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].error || error[0].message : error
      );
    }
    setState((prevState) => ({
      ...prevState,
      surveyLinks: data.surveys,
      isLinksLoading: false,
    }));
  }, []);

  //Fetch details of each survey
  const fetchSurveysDetails = useCallback(
    async (
      clientId,
      surveyType,
      pageNumber = 1,
      pageSize = 5,
      order = "",
      orderBy = "",
      filters = []
    ) => {
      setState((prevState) => ({
        ...prevState,
        isSurveyLoading: true,
      }));
      const queryString = queryStringBuilder(
        pageSize,
        (pageNumber - 1) * pageSize,
        [],
        filters,
        { field: orderBy, order }
      );
      const { data, error } = await Services.getSurveyDetails(
        clientId,
        surveyType,
        queryString
      );
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isSurveyLoading: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].error || error[0].message : error
        );
      }
      return setState((prevState) => ({
        ...prevState,
        surveyDetails: data.records,
        viewTotalRows: data.count || data.records.length,
        isSurveyLoading: false,
      }));
    },
    []
  );
  const fetchMoneyTalkDetails = useCallback(async (clientId, surveyType) => {
    setState((prevState) => ({
      ...prevState,
      isMoneyTalkLoading: true,
    }));
    const { data, error } = await Services.getMoneyTalkDetails(
      clientId,
      surveyType
    );
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isMoneyTalkLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].error || error[0].message : error
      );
    }
    return setState((prevState) => ({
      ...prevState,
      isMoneyTalkLoading: false,
      moneyTalkData: data,
    }));
  }, []);

  //Create new Surveys
  const handleSendSurvey = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: true,
    }));

    const { data, error } = await Services.postSurvey({});
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isButtonLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].error || error[0].message : error
      );
    }
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: false,
      isSurveySent: true,
      surveyLink: data.url,
    }));
    fetchSurveys();
    fetchPendingLinks();
    window.open(data.url, "_blank");
  }, []);

  //Reschedule Surveys
  const handleRescheduleSurvey = useCallback(
    async (rescheduleDetails, currentRow, action = "Reschedule") => {
      const surveyDate = currentRow.sendDate;
      if (
        action == "Reschedule" &&
        new Date(surveyDate).getTime() <= new Date().getTime()
      ) {
        return toast.error(
          "Can't reschedule surveys that are in the past or scheduled for today."
        );
      }
      if (
        action == "Cancel" &&
        new Date(surveyDate).getTime() <= new Date().getTime()
      ) {
        return toast.error(
          "Can't cancel surveys that are in the past or scheduled for today."
        );
      }
      setState((prevState) => ({
        ...prevState,
        isRescheduleButtonLoading: true,
      }));
      const payload = {
        action,
        ...(rescheduleDetails.rescheduleDate && {
          rescheduleDate: format(
            new Date(rescheduleDetails.rescheduleDate),
            "yyyy-MM-dd"
          ),
        }),
        notes: rescheduleDetails.notes ? rescheduleDetails.notes : "",
        responseId: currentRow.surveyResponseId,
        surveyType: currentRow.surveyType,
      };
      const { error } = await Services.rescheduleSurvey(
        payload,
        currentRow?.surveyCollectorId
      );
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isRescheduleButtonLoading: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].error || error[0].message : error
        );
      }
      setTimeout(() => fetchSurveys(), 2000);
      handleRescheduleDialog(false);
      setState((prevState) => ({
        ...prevState,
        isRescheduleButtonLoading: false,
        isSurveySuccess: action === "Reschedule",
        pageNumber: defaultState.pageNumber,
      }));
      handleConfirmationDialog(false);
      return toast.success(
        action === "Cancel"
          ? "Survey cancelled successfully. Please check after sometime."
          : "Survey rescheduled successfully!"
      );
    },
    []
  );

  //Fetch the survey Url and copy it to the clipboard
  const handleCopyUrl = useCallback(async (row = {}) => {
    // const { data, error } = await Services.getSurveyUrl(id);
    // if (error) {
    //   return toast.error(
    //     Array.isArray(error) ? error[0].error || error[0].message : error
    //   );
    // }
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(
          `${row.me_portal_survey?.surveyUrl}`
        );
        handleCopiedLink(true);
        setTimeout(() => handleCopiedLink(false), 2000);
        toast.info("Link copied to clipboard.");
      } catch (exception) {
        console.error("Failed to copy link:", exception);
      }
    }
  }, []);

  const handleCopiedLink = (value) => {
    setState((prevState) => ({
      ...prevState,
      isLinkCopied: value,
    }));
  };
  const handleSuccessClose = () => {
    setState((prevState) => ({
      ...prevState,
      isSurveySent: false,
      isSurveySuccess: false,
    }));
  };

  //Grid handlers
  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order,
      orderBy: order ? fieldObj.field || fieldObj.fieldName : null,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  //View dialog Grid handlers
  const handleViewPageNumberChange = useCallback((viewPageNumber) => {
    setState((prevState) => ({
      ...prevState,
      viewPageNumber,
    }));
  }, []);

  const handleViewPageSizeChange = useCallback((viewPageSize) => {
    setState((prevState) => ({
      ...prevState,
      viewPageSize,
      viewPageNumber: defaultState.viewPageNumber,
    }));
  }, []);

  const handleViewSortChange = useCallback((fieldObj, viewOrder) => {
    setState((prevState) => ({
      ...prevState,
      viewOrder,
      viewOrderBy: viewOrder ? fieldObj.field || fieldObj.fieldName : null,
    }));
  }, []);

  const handleSelectionChange = useCallback((selectedRow) => {
    setState((prevState) => ({
      ...prevState,
      selectedRow: selectedRow[selectedRow.length - 1],
    }));
    gridHelper.setSelection([selectedRow[selectedRow.length - 1]]);
  }, []);

  //Dialog handlers
  const handleConfirmationDialog = useCallback((value = false, row) => {
    setState((prevState) => ({
      ...prevState,
      isConfirmationOpen: value,
      currentRow: row,
    }));
  }, []);
  const handleViewSurvey = useCallback((value = false, row = {}) => {
    value &&
      fetchSurveysDetails(
        row.me_portal_surveyClient?.id,
        row.surveyType,
        state.viewPageNumber,
        state.viewPageSize,
        state.viewOrder,
        state.viewOrderBy
      );
    setState((prevState) => ({
      ...prevState,
      isViewOpen: value,
      currentRow: row,
    }));
  }, []);
  const handleMoneyTalkDialog = useCallback((value = false, row = {}) => {
    value &&
      fetchMoneyTalkDetails(row.me_portal_surveyClient?.id, row.surveyType);
    setState((prevState) => ({
      ...prevState,
      isMoneyTalkOpen: value,
      currentRow: row,
    }));
  }, []);
  const handleRescheduleDialog = useCallback((value = false, row = {}) => {
    setState((prevState) => ({
      ...prevState,
      isRescheduleOpen: value,
      currentRow: row,
      rescheduleDetails: !value
        ? defaultState.rescheduleDetails
        : prevState.rescheduleDetails,
    }));
  }, []);
  const handleLinksDialog = useCallback((value = false) => {
    setState((prevState) => ({
      ...prevState,
      isLinksDialogOpen: value,
    }));
  }, []);

  const handleFieldChange = (evt, type = "") => {
    const { name, value } =
      type === "date" ? evt : evt.currentTarget || evt.target;
    setState((prevState) => ({
      ...prevState,
      rescheduleDetails: {
        ...prevState.rescheduleDetails,
        [name]: value,
      },
    }));
  };

  useEffect(() => {
    fetchSurveys(
      state.pageNumber,
      state.pageSize,
      state.order,
      state.orderBy,
      state.filters
    );
  }, [
    fetchSurveys,
    state.pageNumber,
    state.pageSize,
    state.order,
    state.orderBy,
    state.filters,
  ]);
  useEffect(() => {
    fetchPendingLinks();
  }, []);

  const moreOptions = [
    {
      id: 1,
      label: "VIEW SURVEYS",
      onClick: (row) => handleViewSurvey(true, row),
    },
    {
      id: 2,
      label: "RESCHEDULE SURVEY",
      onClick: (row) => handleRescheduleDialog(true, row),
    },
    {
      id: 3,
      label: "CANCEL SURVEY",
      onClick: (row) => handleConfirmationDialog(true, row),
    },
    {
      id: 4,
      label: "MONEY TALK LINK",
      onClick: (row) => handleMoneyTalkDialog(true, row),
    },
    // {
    //   id: 5,
    //   label: state.isLinkCopied
    //     ? "COPIED LINK TO CLIPBOARD"
    //     : "COPY SURVEY URL",
    //   onClick: (row) => {
    //     handleCopyUrl(row);
    //   },
    // },
  ];
  const columnConfig = [
    {
      id: "clientFirstName",
      field: "clientFirstName",
      label: "FULL NAME",
      canSort: true,
      noResize: false,
      render: (row) => {
        const email = row.me_portal_surveyClient.clientFirstName
          ? row.me_portal_surveyClient.clientFirstName +
              " " +
              row.me_portal_surveyClient.clientLastName || ""
          : "";
        return (
          <Tooltip title={email ? email : ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {email || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "clientEmail",
      field: "clientEmail",
      label: "EMAIL ADDRESS",
      canSort: true,
      noResize: false,
      render: (row) => {
        return (
          <Tooltip
            title={
              row.me_portal_surveyClient.clientEmail
                ? row.me_portal_surveyClient.clientEmail
                : ""
            }
            placement="top-start"
          >
            <Typography variant="body1" noWrap>
              {row.me_portal_surveyClient.clientEmail || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "surveyType",
      field: "surveyType",
      label: "SURVEY TYPE",
      canSort: true,
      noResize: false,
      render: (row) => {
        return (
          <Tooltip
            title={row.surveyType ? row.surveyType : ""}
            placement="top-start"
          >
            <Typography variant="body1" noWrap>
              {row.surveyType || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "sendDate",
      field: "sendDate",
      label: "SEND DATE",
      canSort: true,
      noResize: false,
      render: (row) => {
        let date = row.sendDate
          ? new Date(row.sendDate)
              ?.toISOString()
              ?.substring(0, 10)
              ?.split("-")
              .reverse()
          : "";
        if (date.length) {
          [date[0], date[1]] = [date[1], date[0]];
          date = date.join("/");
        }
        return (
          <Tooltip title={date} placement="top-start">
            <Typography variant="body1" noWrap>
              {date || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "surveyResponseStatus",
      field: "surveyResponseStatus",
      label: "STATUS",
      canSort: true,
      noResize: false,
      render: (row) => {
        const status = row.surveyResponseStatus ? row.surveyResponseStatus : "";
        return (
          <Tooltip title={status} placement="top-start">
            <Typography variant="body1" noWrap>
              {status || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "survey_count",
      field: "survey_count",
      label: "SURVEY COUNT",
      noResize: false,
      render: (row) => {
        const surveyCount = `${row.totalObserverSurveyCount} total / ${row.sentObserverSurveyCount} sent / ${row.completedObserverSurveyCount} completed`;
        return (
          <Tooltip title={surveyCount ? surveyCount : ""} placement="top-start">
            <Typography variant="body1" noWrap>
              {surveyCount || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "col-action",
      label: "",
      render: (row) => {
        return (
          <>
            {
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Dropdown
                  customToggle={
                    <IconButton>
                      <MoreHorizIcon color="primary" fontSize="large" />
                    </IconButton>
                  }
                  options={[
                    ...moreOptions.map((option) => {
                      const isRescheduledDisabled =
                        (option.id == 2 || option.id == 3) &&
                        (row.surveyResponseStatus == "Cancelled" ||
                          row.surveyResponseStatus == "Completed" ||
                          row.surveyResponseStatus == "Partially completed" ||
                          row.surveyResponseStatus == "Partially sent" ||
                          row.surveyResponseStatus == "Sent");
                      const isMoneyTalkDisabled =
                        option.id == 4 && row.surveyType == "Midpoint";

                      return {
                        label: (
                          <Tooltip title={option.label} placement="top-start">
                            <Typography
                              variant="body2"
                              sx={{
                                mx: "20px",
                                my: "4px",
                                width: "100%",
                                height: "100%",
                                fontWeight: "bold",
                                color:
                                  isRescheduledDisabled || isMoneyTalkDisabled
                                    ? "grey.400"
                                    : "primary.main",
                              }}
                            >
                              {option.label}
                            </Typography>
                          </Tooltip>
                        ),
                        disabled: isRescheduledDisabled || isMoneyTalkDisabled,
                        onClick: () =>
                          (!isRescheduledDisabled || !isMoneyTalkDisabled) &&
                          option.onClick(row),
                      };
                    }),
                  ]}
                />
              </Box>
            }
          </>
        );
      },
    },
  ];
  const linksColumnConfig = [
    {
      id: "surveyName",
      field: "surveyName",
      label: "SURVEY NAME",
      // canSort: true,
      noResize: false,
      render: (row) => {
        return (
          <Tooltip
            title={row.surveyName ? row.surveyName : ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.surveyName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "createdAt",
      field: "createdAt",
      label: "CREATED AT",
      // canSort: true,
      noResize: false,
      render: (row) => {
        const date = row.createdAt
          ? format(new Date(row.createdAt), "MM/dd/yyyy")
          : "";
        return (
          <Tooltip title={date} placement="top-start">
            <Typography variant="body2" noWrap>
              {date || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "surveyUrl",
      field: "surveyUrl",
      label: "SURVEY URL",
      // canSort: true,
      noResize: false,
      render: (row) => {
        const surveyUrl = row.surveyUrl ? row.surveyUrl : "";
        return (
          <Tooltip title={surveyUrl} placement="top-start">
            <Typography
              variant="body2"
              color="primary"
              noWrap
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => window.open(`${surveyUrl}`, "_blank")}
            >
              {surveyUrl || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];
  const viewColumnConfig = [
    {
      id: "Respondent Email",
      field: "Respondent Email",
      label: "OBSERVER EMAIL",
      // canSort: true,
      noResize: false,
      render: (row) => {
        const email = row["Respondent Email"] ? row["Respondent Email"] : "";
        return (
          <Tooltip title={email ? email : ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {email || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "status",
      field: "status",
      label: "STATUS",
      // canSort: true,
      noResize: false,
      render: (row) => {
        const status = row["Status"] ? row["Status"] : "";
        return (
          <Tooltip title={status} placement="top-start">
            <Typography variant="body2" noWrap>
              {status || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "role",
      field: "role",
      label: "ROLE",
      // canSort: true,
      noResize: false,
      render: (row) => {
        const role = row["Role"] ? row["Role"] : "";
        return (
          <Tooltip title={role} placement="top-start">
            <Typography variant="body2" noWrap>
              {role || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "coachee_name",
      field: "coachee_name",
      label: "COACHEE NAME",
      // canSort: true,
      noResize: false,
      render: (row) => {
        const coacheeName = row["Coachee First Name"]
          ? row["Coachee First Name"]
          : "";
        return (
          <Tooltip title={coacheeName} placement="top-start">
            <Typography variant="body2" noWrap>
              {coacheeName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "url",
      field: "url",
      label: "URL",
      // canSort: true,
      noResize: false,
      render: (row) => {
        const url = row["URL"] ? row["URL"] : "";
        return (
          <Tooltip title={url} placement="top-start">
            <Link
              href={url}
              target="_blank"
              sx={{
                textDecoration: "underline",
                fontWeight: "light",
                color: "primary.800",
              }}
              noWrap
            >
              {url || "-"}
            </Link>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <SurveysView
      rows={state.surveys}
      columnConfig={columnConfig}
      isLoading={state.isLoading}
      pageNumber={state.pageNumber}
      pageSize={state.pageSize}
      order={state.order}
      orderBy={state.orderBy}
      totalRows={state.totalRows}
      isSurveyLoading={state.isSurveyLoading}
      isButtonLoading={state.isButtonLoading}
      isSurveySuccess={state.isSurveySuccess}
      isSurveySent={state.isSurveySent}
      surveyLink={state.surveyLink}
      availableSurveyCount={state.availableSurveyCount}
      isLinkCopied={state.isLinkCopied}
      isConfirmationOpen={state.isConfirmationOpen}
      isViewOpen={state.isViewOpen}
      isRescheduleOpen={state.isRescheduleOpen}
      rescheduleDetails={state.rescheduleDetails}
      isRescheduleButtonLoading={state.isRescheduleButtonLoading}
      currentRow={state.currentRow}
      viewColumnConfig={viewColumnConfig}
      surveyDetails={state.surveyDetails}
      viewPageNumber={state.viewPageNumber}
      viewPageSize={state.viewPageSize}
      viewOrder={state.viewOrder}
      viewOrderBy={state.viewOrderBy}
      viewTotalRows={state.viewTotalRows}
      isMoneyTalkOpen={state.isMoneyTalkOpen}
      moneyTalkData={state.moneyTalkData}
      isMoneyTalkLoading={state.isMoneyTalkLoading}
      isLinksDialogOpen={state.isLinksDialogOpen}
      surveyLinks={state.surveyLinks}
      isLinksLoading={state.isLinksLoading}
      linksColumnConfig={linksColumnConfig}
      handleConfirmationDialog={handleConfirmationDialog}
      handleSendSurvey={handleSendSurvey}
      handleSuccessClose={handleSuccessClose}
      handleViewSurvey={handleViewSurvey}
      handleCopiedLink={handleCopiedLink}
      handlePageNumberChange={handlePageNumberChange}
      handlePageSizeChange={handlePageSizeChange}
      handleSortChange={handleSortChange}
      handleSelectionChange={handleSelectionChange}
      handleRescheduleDialog={handleRescheduleDialog}
      handleFieldChange={handleFieldChange}
      handleRescheduleSurvey={handleRescheduleSurvey}
      handleViewPageNumberChange={handleViewPageNumberChange}
      handleViewPageSizeChange={handleViewPageSizeChange}
      handleViewSortChange={handleViewSortChange}
      handleMoneyTalkDialog={handleMoneyTalkDialog}
      handleLinksDialog={handleLinksDialog}
      handleGridReady={(instance) => (gridHelper = instance)}
    />
  );
};

export default Surveys;
