import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";

import styles from "./styles";

export default function SidebarHeader({
  drawerList = [],
  drawerListBottom = [],
  children = <></>,
  hasSidebar = true,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const isActiveItem = (itemRoute) => {
    let isActive = false;
    if (
      `/${currentPath.split("/")[1]}` === itemRoute ||
      currentPath === `${itemRoute}/`
    ) {
      isActive = true;
    }
    return isActive;
  };

  return (
    <>
      {" "}
      {!hasSidebar ? (
        <></>
      ) : (
        <Drawer
          PaperProps={{
            sx: styles.drawerOpen,
          }}
          ModalProps={{
            keepMounted: true,
          }}
          variant="permanent"
          elevation={0}
        >
          <Box sx={styles.container}>
            <Box sx={styles.logoContainer}>
              <img
                src="/assets/dashboard-logo.svg"
                alt="my-excelia"
                width={210}
              />
            </Box>
            <Divider
              width="100%"
              color="white"
              sx={{
                mt: 2,
                borderBottomWidth: 3,
                borderColor: "common.white",
              }}
            />
            <Box sx={styles.drawerList}>
              <Box>
                {drawerList.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      sx={{
                        ...(isActiveItem(item.to)
                          ? styles.drawerButtonActive
                          : styles.drawerButtonInActive),
                      }}
                      onClick={() => navigate(`${item.to}`)}
                    >
                      {item.label}
                    </Button>
                  );
                })}
              </Box>
              <Box>
                {drawerListBottom.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      sx={{
                        ...(isActiveItem(item.to)
                          ? styles.drawerButtonActive
                          : styles.drawerButtonInActive),
                      }}
                      onClick={() => navigate(`${item.to}`)}
                    >
                      {item.label}
                    </Button>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Drawer>
      )}
      <Box sx={hasSidebar && styles.content}>{children}</Box>
    </>
  );
}
