import { noop } from '@client/shared/constants';
import { Footer } from '@client/shared/components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import MyClientsGrid from '../my-clients/clients-grid';
import SurveySuccessView from '../surveys/success';
import BgImageLeft from '../../assets/images/gradients_light_teal.png';
import BgImageTop from '../../assets/images/gradients_bright_blue.png';

const DashboardView = ({
  rows = [],
  isLoading = false,
  pageNumber = 1,
  pageSize = 5,
  order = null,
  orderBy = '',
  totalRows = 0,
  isSurveyLoading = false,
  availableSurveyCount = 0,
  isButtonLoading = false,
  isLinkCopied = 0,
  isSurveySent = false,
  surveyLink = '',
  quote = '',
  updates = [],
  isQuoteLoading = false,
  isUpdateLoading = false,
  fullName = '',
  isLoadingName = false,
  handlePageNumberChange = noop,
  handlePageSizeChange = noop,
  handleSortChange = noop,
  handleSelectionChange = noop,
  handleGridReady = noop,
  handleSendSurvey = noop,
  handleCopiedLink = noop,
  handleSuccessClose = noop,
  handleSearch = noop,
}) => {
  const latestUpdates = updates.slice(0, 10);
  return (
    <>
      <Box
        sx={{
          height: '100vh',
          backgroundImage: `url(${BgImageLeft}), url(${BgImageTop})`,
          backgroundPosition: 'left bottom, right top',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box
          sx={{
            minHeight: '95%',
            pt: 6,
            pl: 15,
          }}
        >
          {isLoadingName ? (
            <Skeleton height={50} width={200} />
          ) : (
            <Typography color="primary" variant="h3" sx={{}}>
              Welcome, {fullName || ''}!
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 12,
              pt: 6,
            }}
          >
            <Box
              component={Paper}
              elevation={2}
              width="25%"
              sx={{
                p: 3,
                minHeight: '196px',
                maxHeight: '200px',
                overflow: 'auto',
                backgroundColor: 'grey.light',
              }}
            >
              <Typography variant="body2" color="primary" sx={{ fontWeight: 'bold', mb: 1 }}>
                INSPIRATION
              </Typography>
              <Typography variant="subtitle1" color="primary">
                {isQuoteLoading ? <Skeleton height={50} /> : quote}
              </Typography>
            </Box>
            <Box
              component={Paper}
              elevation={2}
              width="25%"
              sx={{
                p: 2,
                minHeight: '196px',
                maxHeight: '200px',
                overflow: 'auto',
                backgroundColor: 'grey.light',
              }}
            >
              <Typography variant="body2" color="primary" sx={{ fontWeight: 'bold', mb: '6px' }}>
                UPDATES
              </Typography>
              <>
                {isUpdateLoading ? (
                  <Skeleton height={50} />
                ) : (
                  <>
                    {latestUpdates.length ? (
                      <>
                        {latestUpdates.map((update) => (
                          <Box>
                            <Typography
                              variant="body1"
                              color="primary"
                              sx={{
                                p: '6px',
                                fontSize: '16px',
                              }}
                            >
                              {update.event}
                            </Typography>
                            {latestUpdates.indexOf(update) !== latestUpdates.length - 1 && (
                              <Divider
                                sx={{
                                  m: 1,
                                  borderBottomWidth: 1,
                                  borderColor: 'grey.300',
                                }}
                              />
                            )}
                          </Box>
                        ))}
                      </>
                    ) : (
                      <Typography variant="body1" sx={{ textAlign: 'center', mt: 3 }}>
                        No latest updates
                      </Typography>
                    )}
                  </>
                )}
              </>
            </Box>
            <Box
              component={Paper}
              elevation={2}
              width="24%"
              sx={{
                p: 2,
                minHeight: '196px',
                maxHeight: '200px',
                overflow: 'auto',
                backgroundColor: 'grey.light',
              }}
            >
              <Typography
                variant="body2"
                color="primary"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                SURVEY COUNT
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                }}
              >
                <Typography
                  // variant="title"
                  color="primary"
                  sx={{ fontSize: '50px' }}
                >
                  {isSurveyLoading ? <Skeleton height={54} width={32} /> : availableSurveyCount}
                </Typography>
                <Typography color="primary" sx={{ fontSize: '18px' }} variant="h6">
                  {availableSurveyCount == '1' ? 'Survey' : 'Surveys'} left in your plan.
                </Typography>
              </Box>
              {availableSurveyCount >= '1' ? (
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    mt: 2,
                    boxShadow: 1,
                    backgroundColor: 'white',
                  }}
                  disabled={isButtonLoading || !availableSurveyCount}
                  onClick={handleSendSurvey}
                >
                  {isButtonLoading ? <CircularProgress color="inherit" size="2rem" /> : 'SEND NEW SURVEY'}{' '}
                </Button>
              ) : (
                <>
                  {isSurveyLoading ? (
                    <Skeleton height={54} width="100%" />
                  ) : (
                    <Typography variant="body1" color="primary" sx={{ textAlign: 'center', fontWeight: 'medium' }}>
                      Need additional surveys?
                      <br /> Contact us at <br />
                      <a href="mailto:support@myexcelia.com"> support@myexcelia.com</a>
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Box>
          <MyClientsGrid
            rows={rows}
            pageNumber={pageNumber}
            pageSize={pageSize}
            order={order}
            orderBy={orderBy}
            totalRows={totalRows}
            isLoading={isLoading}
            handlePageNumberChange={handlePageNumberChange}
            handlePageSizeChange={handlePageSizeChange}
            handleSortChange={handleSortChange}
            handleSelectionChange={handleSelectionChange}
            handleGridReady={handleGridReady}
            handleSearch={handleSearch}
          />{' '}
        </Box>
        <Footer />
      </Box>
      {/* Success Survey Dialog */}
      <SurveySuccessView
        isSurveySent={isSurveySent}
        surveyLink={surveyLink}
        isLinkCopied={isLinkCopied}
        handleSuccessClose={handleSuccessClose}
        handleCopiedLink={handleCopiedLink}
      />
    </>
  );
};

export default DashboardView;
