import { noop } from "@client/shared/constants";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
const SurveySuccessView = ({
  isSurveySent = false,
  surveyLink = "",
  isLinkCopied = 0,
  handleSuccessClose = noop,
  handleCopiedLink = noop,
}) => {
  return (
    <>
      {/*Successfully Sent Survey Dialog */}
      {!!isSurveySent && (
        <Dialog open={isSurveySent} onClose={handleSuccessClose}>
          <DialogTitle
            sx={{
              textAlign: "end",
            }}
          >
            <IconButton onClick={handleSuccessClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              minWidth: "500px",
              minHeight: "200px",
              p: 4,
              mb: 4,
            }}
          >
            <Typography variant="h4" color="primary">
              Survey Created Successfully!
            </Typography>
            <Typography variant="h5" sx={{ mt: 3, color: "common.black" }}>
              Copy survey link below
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid",
                borderColor: "grey.600",
                borderRadius: "5px",
                px: 2,
                py: 1,
                mt: 2,
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => window.open(surveyLink, "_blank").focus()}
              >
                {surveyLink || ""}
              </Typography>
              <Button
                sx={{ borderLeft: "1px solid", pl: 3 }}
                onClick={async () => {
                  if (navigator.clipboard) {
                    try {
                      await navigator.clipboard.writeText(`${surveyLink}`);
                      handleCopiedLink(true);
                      setTimeout(() => handleCopiedLink(false), 2000);
                      toast.info("Link copied to clipboard.");
                    } catch (exception) {
                      console.error("Failed to copy link:", exception);
                    }
                  }
                }}
              >
                {isLinkCopied ? <CheckIcon /> : "Copy"}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default SurveySuccessView;
