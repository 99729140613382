import { noop } from "@client/shared/constants";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const LogoutView = ({
  isRConfirmationLoading = false,
  handleLogout = noop,
}) => {
  return (
    <>
      <Dialog open={true} maxWidth="lg">
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ mt: 1, fontWeight: "bold" }}>LOGOUT</Typography>
          <IconButton onClick={() => handleLogout(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            minHeight: "100px",
          }}
        >
          <Typography variant="h5" sx={{ mt: 2, color: "common.black" }}>
            Are you sure you want to logout from MyExcelia Dashboard?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            sx={{ color: "grey.500" }}
            onClick={() => handleLogout(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "error.main" }}
            disabled={isRConfirmationLoading}
            onClick={() => handleLogout(true)}
          >
            {" "}
            {isRConfirmationLoading ? (
              <CircularProgress color="inherit" size="1.5rem" />
            ) : (
              "Logout"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LogoutView;
