import { useCallback } from 'react';
import { Grid, Footer, Dropdown } from '@client/shared/components';
import { noop } from '@client/shared/constants';
import { debounce } from '@client/utils';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';

const SurveysView = ({
  rows = [],
  columnConfig = [],
  pageNumber = 1,
  pageSize = 30,
  order = null,
  orderBy = '',
  totalRows = 0,
  isLoading = false,
  currentRow = {},
  isCreateButtonLoading = false,
  handlePageNumberChange = noop,
  handlePageSizeChange = noop,
  handleSortChange = noop,
  handleSelectionChange = noop,
  handleGridReady = noop,
  handleFieldChange = noop,
  handleCreateOrganization = noop,
  handleCreateDialog = noop,
  isCreateOpen = false,
  organizationName = '',
  isActive = true,
  hasFormChanged = false,
  analysisTypesList = [],
  analysisType = {},
  userDetails = {},
  handleSearch = noop,
}) => {
  const handleChange = useCallback(
    debounce((evt) => handleSearch(evt?.target?.value), 300),
    [handleSearch]
  );
  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={{ minHeight: '95%' }}>
        <Box
          sx={{
            pt: 6,
            pl: 10,
            pr: 10,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography color="primary" variant="h3">
              Organizations
            </Typography>
            {!!userDetails.permissions?.createOrg && (
              <Button
                variant="contained"
                sx={{ backgroundColor: 'primary.main' }}
                startIcon={<AddIcon />}
                onClick={() => {
                  handleCreateDialog(true);
                }}
              >
                CREATE
              </Button>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: 3 }}>
            <TextField
              size="small"
              placeholder="Search..."
              name="search"
              sx={{
                minWidth: '350px',
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'grey.50',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
            />
          </Box>
          <Box
            component={Paper}
            elevation={2}
            sx={{
              mt: 3,
              p: 3,
              borderRadius: 2,
            }}
          >
            <Grid
              hasPagination={true}
              hasSelection={false}
              rows={rows}
              totalRows={totalRows}
              isLoading={isLoading}
              columns={columnConfig}
              pageSize={pageSize}
              pageNumber={pageNumber}
              order={order}
              orderBy={orderBy}
              onPageNumberChange={handlePageNumberChange}
              onPageSizeChange={handlePageSizeChange}
              onSortChange={handleSortChange}
              onSelectionChange={handleSelectionChange}
              onReady={handleGridReady}
              hasSelectAll={false}
              hasSelectMultiple={false}
              classes={{
                container: { 'max-height': '600px !important' },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Footer />

      {/* Create Organization Dialog */}
      {!!isCreateOpen && (
        <Dialog open={isCreateOpen} maxWidth="lg">
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {' '}
            <Typography sx={{ mt: 1, fontWeight: 'bold' }}>
              {currentRow.organizationName ? 'EDIT' : 'CREATE'} ORGANIZATION
            </Typography>
            <IconButton onClick={() => handleCreateDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              minWidth: '500px',
              minHeight: '100px',
              p: 4,
            }}
          >
            <Typography variant="body1">Name*</Typography>
            <TextField
              size="small"
              margin="dense"
              placeholder="Organization Name"
              value={organizationName || ''}
              name="organizationName"
              fullWidth
              onChange={(event) => handleFieldChange(event)}
            />{' '}
            <Typography sx={{ mt: 2, mb: 1 }} variant="body1">
              Default Analysis Type*
            </Typography>
            <Dropdown
              customToggle={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'space-between',
                    padding: '8px',
                    border: '2px solid #d2d2d2',
                    borderRadius: '8px',
                    width: '430px',
                  }}
                >
                  <Typography variant="body1">
                    {analysisType.name ? analysisType.name : 'Select Default Analysis Type'}
                  </Typography>
                  <KeyboardArrowDownIcon />
                </Box>
              }
              classes={{ menu: { minWidth: '430px' } }}
              options={[
                ...analysisTypesList.map((option) => {
                  return {
                    label: (
                      <Typography
                        key={option.id}
                        sx={{
                          ml: 4,
                          mr: 4,
                        }}
                      >
                        {option.name}
                      </Typography>
                    ),
                    onClick: (e) => handleFieldChange({ name: 'analysisType', value: option }, 'dropdown'),
                  };
                }),
              ]}
            />
            {currentRow.organizationName && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 1, mt: 1 }}>
                <Typography variant="body1">Is Active:</Typography>
                <Switch
                  checked={isActive}
                  onChange={() => handleFieldChange({ name: 'isActive', value: !isActive }, 'switch')}
                />{' '}
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button sx={{ color: 'grey.500' }} onClick={() => handleCreateDialog(false)}>
              Close
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'primary.main' }}
              disabled={isCreateButtonLoading || !organizationName.trim()?.length || !hasFormChanged}
              onClick={() =>
                handleCreateOrganization(
                  { organizationName: organizationName.trim(), isActive, defaultAnalysisType: analysisType.id },
                  !!currentRow.organizationName,
                  currentRow.id
                )
              }
            >
              {isCreateButtonLoading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : currentRow.organizationName ? (
                'Edit'
              ) : (
                'Create'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default SurveysView;
