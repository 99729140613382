import { http } from "@client/shared/services";
import { responseFormatter } from "@client/utils";

const login = (payload) => {
  return responseFormatter(http.post(`/logIn`, payload));
};

const Services = {
  login,
};

export default Services;
