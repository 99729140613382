import { noop } from "@client/shared/constants";
import { Grid } from "@client/shared/components";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

const SurveyViewModule = ({
  isViewOpen = false,
  currentRow = {},
  columnConfig = [],
  viewPageNumber = 1,
  viewPageSize = 5,
  viewTotalRows = 0,
  viewOrder = null,
  viewOrderBy = "",
  isSurveyLoading = false,
  surveyDetails = [],
  handleViewPageNumberChange = noop,
  handleViewPageSizeChange = noop,
  handleViewSortChange = noop,
  handleGridReady = noop,
  handleViewSurvey = noop,
}) => {
  return (
    <>
      {/*View each Survey Dialog */}
      {!!isViewOpen && (
        <Dialog open={isViewOpen} maxWidth="xl" fullWidth>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="primary" ml="8px">
              Survey: {currentRow.survey?.surveyName}
            </Typography>
            <IconButton onClick={() => handleViewSurvey(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              p: 4,
              mb: 3,
            }}
          >
            <Box
              component={Paper}
              elevation={2}
              sx={{
                mt: 2,
                p: 3,
                borderRadius: 2,
              }}
            >
              <Grid
                hasPagination={false}
                hasSelection={false}
                rows={surveyDetails}
                totalRows={viewTotalRows}
                isLoading={isSurveyLoading}
                columns={columnConfig}
                pageSize={viewPageSize}
                pageNumber={viewPageNumber}
                order={viewOrder}
                orderBy={viewOrderBy}
                onPageNumberChange={handleViewPageNumberChange}
                onPageSizeChange={handleViewPageSizeChange}
                onSortChange={handleViewSortChange}
                onReady={handleGridReady}
                entriesText="Events"
                classes={{
                  container: {
                    "max-height": "600px !important",
                  },
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default SurveyViewModule;
