import { noop } from "@client/shared/constants";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";

const MoneyTalkView = ({
  isMoneyTalkOpen = false,
  moneyTalkData = [],
  isLinkCopied = false,
  handleCopiedLink = noop,
  isMoneyTalkLoading = false,
  handleMoneyTalkDialog = noop,
}) => {
  const isCompleted = new RegExp("Completed").test(moneyTalkData.moneyTalkLink);
  return (
    <>
      {/*Money Talk Dialog */}
      {!!isMoneyTalkOpen && (
        <Dialog open={isMoneyTalkOpen}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="primary" ml="8px">
              MONEY TALK LINK
            </Typography>
            <IconButton onClick={() => handleMoneyTalkDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              p: 2,
              mb: 3,
              minWidth: "520px",
              minHeight: "180px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isMoneyTalkLoading ? (
              <CircularProgress color="inherit" size="2rem" />
            ) : (
              <>
                {isCompleted ? (
                  <Box>
                    {" "}
                    <Typography variant="h6">
                      {moneyTalkData.moneyTalkLink}
                    </Typography>
                  </Box>
                ) : moneyTalkData.moneyTalkLink ? (
                  <Box>
                    {" "}
                    <Typography
                      variant="h5"
                      sx={{ mt: 2, mb: 1, color: "common.black" }}
                    >
                      Copy money talk link below
                    </Typography>
                    <TextField
                      margin="normal"
                      value={moneyTalkData.moneyTalkLink || ""}
                      name="link"
                      disabled={isLinkCopied}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <Button
                            sx={{ borderLeft: "1px solid" }}
                            onClick={async () => {
                              if (navigator.clipboard) {
                                try {
                                  await navigator.clipboard.writeText(
                                    `${moneyTalkData.moneyTalkLink}`
                                  );
                                  handleCopiedLink(true);
                                  setTimeout(
                                    () => handleCopiedLink(false),
                                    2000
                                  );
                                  toast.info("Link copied to clipboard.");
                                } catch (exception) {
                                  console.error(
                                    "Failed to copy link:",
                                    exception
                                  );
                                }
                              }
                            }}
                          >
                            {isLinkCopied ? <CheckIcon /> : "Copy"}
                          </Button>
                        ),
                      }}
                    />
                  </Box>
                ) : (
                  <Typography variant="h6">Not Available</Typography>
                )}
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default MoneyTalkView;
