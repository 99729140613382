import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@client/shared/components";
import { noop } from "@client/shared/constants";
import { debounce } from "@client/utils";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";

const MyClientsGrid = ({
  rows = [],
  isClientsPage = false,
  pageNumber = 1,
  pageSize = 5,
  order = null,
  orderBy = "",
  isLoading = false,
  totalRows = 0,
  handlePageNumberChange = noop,
  handlePageSizeChange = noop,
  handleSortChange = noop,
  handleSelectionChange = noop,
  handleGridReady = noop,
  handleSearch = noop,
}) => {
  const navigate = useNavigate();
  const handleChange = useCallback(
    debounce((evt) => handleSearch(evt?.target?.value), 300),
    [handleSearch]
  );
  const columnConfig = [
    {
      id: "clientFirstName",
      field: "clientFirstName",
      label: "FULL NAME",
      canSort: true,
      noResize: false,
      render: (row) => {
        const fullname = row.clientFirstName + " " + row.clientLastName;
        return (
          <Tooltip title={fullname ? fullname : ""} placement="top-start">
            <Typography
              onClick={() => {
                navigate(`/clients/${row.id}`);
              }}
              sx={{ fontWeight: "medium", cursor: "pointer" }}
              variant="body2"
              noWrap
            >
              {fullname || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "clientEmail",
      field: "clientEmail",
      label: "EMAIL",
      canSort: true,
      noResize: false,
      render: (row) => {
        return (
          <Tooltip
            title={row.clientEmail ? row.clientEmail : ""}
            placement="top-start"
          >
            <Typography variant="body1" noWrap>
              {row.clientEmail || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "clientStatus",
      field: "clientStatus",
      label: "STATUS",
      canSort: true,
      noResize: false,

      render: (row) => {
        return (
          <Button
            variant="contained"
            sx={{
              backgroundColor:
                row.clientStatus === "Inactive" ? "error.light" : "primary.100",
              color:
                row.clientStatus === "Inactive" ? "grey.50" : "primary.400",
            }}
          >
            {row.clientStatus}
          </Button>
        );
      },
    },
    // {
    //   id: "phoneNumber",
    //   field: "phoneNumber",
    //   label: "NUMBER",
    //   canSort: true,
    //   noResize: false,
    //   render: (row) => {
    //     return (
    //       <Tooltip
    //         title={row.phoneNumber ? row.phoneNumber : ""}
    //         placement="top-start"
    //       >
    //         <Typography variant="body2" noWrap>
    //           {row.phoneNumber || "-"}
    //         </Typography>
    //       </Tooltip>
    //     );
    //   },
    // },
  ];
  return (
    <Box
      component={Paper}
      elevation={2}
      sx={{
        mt: 6,
        p: 3,
        borderRadius: 2,
        maxWidth: "86%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: isClientsPage ? "end" : "space-between",
        }}
      >
        {!isClientsPage && (
          <Typography color="primary" variant="h3">
            My Clients
          </Typography>
        )}
        <TextField
          size="small"
          placeholder="Search..."
          name="search"
          sx={{
            minWidth: "350px",
            "& .MuiOutlinedInput-root": {
              backgroundColor: "grey.100",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ mt: 5 }}>
        <Grid
          hasSelection={false}
          hasPagination={true}
          rows={rows}
          totalRows={totalRows}
          isLoading={isLoading}
          columns={columnConfig}
          pageSize={pageSize}
          pageNumber={pageNumber}
          order={order}
          orderBy={orderBy}
          onPageNumberChange={handlePageNumberChange}
          onPageSizeChange={handlePageSizeChange}
          onSortChange={handleSortChange}
          onSelectionChange={handleSelectionChange}
          onReady={handleGridReady}
          entriesText="Events"
          hasSelectAll={false}
          hasSelectMultiple={false}
          classes={{
            container: { "max-height": isClientsPage && "600px !important" },
          }}
        />
      </Box>
    </Box>
  );
};

export default MyClientsGrid;
