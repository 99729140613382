import { useEffect, useState, useCallback } from "react";
import { queryStringBuilder } from "@client/utils";
import { toast } from "react-toastify";
import MyClientsView from "./view";
import Services from "./service";

const defaultState = {
  clients: [],
  isLoading: false,
  pageNumber: 1,
  pageSize: 5,
  order: null,
  orderBy: "",
  totalRows: 0,
  searchFilter: [],
  selectedRow: null,
};
let gridHelper = null;

const MyClients = () => {
  const [state, setState] = useState(defaultState);
  //Fetch User Clients
  const fetchClients = useCallback(
    async (
      pageNumber = 1,
      pageSize = 5,
      order = "",
      orderBy = "",
      searchFilter = []
    ) => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const queryString = queryStringBuilder(
        pageSize,
        (pageNumber - 1) * pageSize,
        searchFilter,
        [],
        { field: orderBy, order }
      );
      const { data, error } = await Services.getClients(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].error || error[0].message : error
        );
      }
      setState((prevState) => ({
        ...prevState,
        clients: data.clients,
        totalRows: data.count,
        isLoading: false,
      }));
    },
    []
  );

  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order,
      orderBy: order ? fieldObj.field || fieldObj.fieldName : null,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);
  const handleSearch = (keyword = "") => {
    const searchFields = [
      "clientFirstName",
      "clientEmail",
      "clientStatus",
      "phoneNumber",
    ];
    let searchFilter = keyword
      ? searchFields.map((element) => {
          return { field: element, value: keyword };
        })
      : [];

    setState((prevState) => ({
      ...prevState,
      searchFilter,
      isSearching: true,
    }));
  };

  const handleSelectionChange = useCallback((selectedRow) => {
    setState((prevState) => ({
      ...prevState,
      selectedRow: selectedRow[selectedRow.length - 1],
    }));
    gridHelper.setSelection([selectedRow[selectedRow.length - 1]]);
  }, []);

  useEffect(() => {
    fetchClients(
      state.pageNumber,
      state.pageSize,
      state.order,
      state.orderBy,
      state.searchFilter
    );
  }, [
    fetchClients,
    state.pageNumber,
    state.pageSize,
    state.order,
    state.orderBy,
    state.searchFilter,
  ]);

  return (
    <MyClientsView
      rows={state.clients}
      isLoading={state.isLoading}
      pageNumber={state.pageNumber}
      pageSize={state.pageSize}
      order={state.order}
      orderBy={state.orderBy}
      totalRows={state.totalRows}
      handlePageNumberChange={handlePageNumberChange}
      handlePageSizeChange={handlePageSizeChange}
      handleSortChange={handleSortChange}
      handleSelectionChange={handleSelectionChange}
      handleSearch={handleSearch}
      handleGridReady={(instance) => (gridHelper = instance)}
    />
  );
};

export default MyClients;
