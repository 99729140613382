import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import SubscriptionsView from "./view";
import Services from "./service";

const defaultState = {
  products: [],
  isLoading: false,
};

const Subscriptions = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(defaultState);

  //Fetch Subscriptions
  const fetchProducts = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const { data, error } = await Services.getSubscriptions();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].error || error[0].message : error
      );
    }
    setState((prevState) => ({
      ...prevState,
      products: data.products,
      isLoading: false,
    }));
  }, []);

  const handleChooseSubscription = (product) => {
    localStorage.setItem("productId", JSON.stringify(product));
    navigate("/details");
  };
  useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <SubscriptionsView
      products={state.products}
      isLoading={state.isLoading}
      handleChooseSubscription={handleChooseSubscription}
    />
  );
};

export default Subscriptions;
