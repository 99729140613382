import { useNavigate } from "react-router-dom";
import {
  removeToken,
  removeUserToken,
  removeRefreshToken,
  removeUserData,
} from "@client/utils";

import LogoutView from "./view";

const LogoutModule = () => {
  const navigate = useNavigate();
  const handleLogout = (isLoggingOut = false) => {
    if (!isLoggingOut) {
      return navigate(-1);
    }
    removeUserToken();
    removeToken();
    removeRefreshToken();
    removeUserData();
    return navigate("/login");
  };

  return <LogoutView handleLogout={handleLogout} />;
};

export default LogoutModule;
