import { noop } from "@client/shared/constants";
import { Grid } from "@client/shared/components";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const PendingLinksDialog = ({
  isLinksDialogOpen = false,
  totalLinks = 0,
  isLoading = false,
  surveyLinks = [],
  columnConfig = [],
  pageNumber = 1,
  pageSize = 5,
  order = null,
  orderBy = "",
  handleLinksDialog = noop,
  handlePageNumberChange = noop,
  handlePageSizeChange = noop,
  handleSortChange = noop,
  handleSelectionChange = noop,
  handleGridReady = noop,
}) => {
  return (
    <>
      {/*Pending Survey Links*/}
      {!!isLinksDialogOpen && (
        <Dialog
          open={isLinksDialogOpen}
          onClose={() => handleLinksDialog(false)}
          maxWidth="lg"
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {" "}
            <Typography color="primary" variant="h6">
              PENDING SURVEYS
            </Typography>
            <IconButton onClick={() => handleLinksDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ minWidth: "800px" }}>
            <Box
              component={Paper}
              elevation={2}
              sx={{
                p: 3,
                borderRadius: 2,
              }}
            >
              <Grid
                hasPagination={false}
                hasSelection={false}
                rows={surveyLinks}
                totalRows={totalLinks}
                isLoading={isLoading}
                columns={columnConfig}
                pageSize={pageSize}
                pageNumber={pageNumber}
                order={order}
                orderBy={orderBy}
                onPageNumberChange={handlePageNumberChange}
                onPageSizeChange={handlePageSizeChange}
                onSortChange={handleSortChange}
                onSelectionChange={handleSelectionChange}
                onReady={handleGridReady}
                hasSelectAll={false}
                hasSelectMultiple={false}
                classes={{
                  container: { "max-height": "440px !important" },
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default PendingLinksDialog;
