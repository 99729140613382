import { http } from "@client/shared/services";
import { responseFormatter } from "@client/utils";

const getClients = (queryString = "") => {
  return responseFormatter(
    http.get(`/user/clients${queryString}`, {
      setAuthHeader: true,
    })
  );
};

const getClientDetails = (id = "") => {
  return responseFormatter(
    http.get(`/user/client/${id}`, {
      setAuthHeader: true,
    })
  );
};

const Service = { getClients, getClientDetails };

export default Service;
