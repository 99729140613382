import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Footer, Dropdown } from '@client/shared/components';
import { noop } from '@client/shared/constants';
import { debounce } from '@client/utils';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const SurveysView = ({
  rows = [],
  columnConfig = [],
  pageNumber = 1,
  pageSize = 30,
  order = null,
  orderBy = '',
  totalRows = 0,
  isLoading = false,
  currentRow = {},
  selectedRows = [],
  isCreateButtonLoading = false,
  handlePageNumberChange = noop,
  handlePageSizeChange = noop,
  handleSortChange = noop,
  handleSelectionChange = noop,
  handleGridReady = noop,
  handleFieldChange = noop,
  handleCreateUsers = noop,
  handleCreateDialog = noop,
  isCreateOpen = false,
  userDetails = {},
  handleDeleteDialog = noop,
  isDeleteOpen = false,
  isButtonLoading = false,
  handleDeleteUsers = noop,
  analysisTypesList = [],
  userData = {},
  errors = {},
  isFormValid = false,
  handleSearch = noop,
}) => {
  const handleChange = useCallback(
    debounce((evt) => handleSearch(evt?.target?.value), 300),
    [handleSearch]
  );
  const navigate = useNavigate();
  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={{ minHeight: '95%' }}>
        <Box
          sx={{
            pt: 6,
            pl: 10,
            pr: 10,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon sx={{ color: 'grey.600' }} />
              </IconButton>
              <Typography color="primary" variant="h3">
                Organization Users
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {!!selectedRows?.length && (
                <Button
                  variant="contained"
                  sx={{ backgroundColor: 'error.main' }}
                  startIcon={<Delete />}
                  onClick={() => {
                    handleDeleteDialog(true);
                  }}
                >
                  DELETE
                </Button>
              )}
              {!!userData.permissions?.createOrgUsers && (
                <Button
                  variant="contained"
                  sx={{ backgroundColor: 'primary.main' }}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    handleCreateDialog(true);
                  }}
                >
                  CREATE
                </Button>
              )}
            </Box>{' '}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: 3 }}>
            <TextField
              size="small"
              placeholder="Search..."
              name="search"
              sx={{
                minWidth: '350px',
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'grey.50',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
            />
          </Box>
          <Box
            component={Paper}
            elevation={2}
            sx={{
              mt: 4,
              p: 3,
              borderRadius: 2,
            }}
          >
            <Grid
              hasPagination={true}
              hasSelection={true}
              rows={rows}
              totalRows={totalRows}
              isLoading={isLoading}
              columns={columnConfig}
              pageSize={pageSize}
              pageNumber={pageNumber}
              order={order}
              orderBy={orderBy}
              onPageNumberChange={handlePageNumberChange}
              onPageSizeChange={handlePageSizeChange}
              onSortChange={handleSortChange}
              onSelectionChange={handleSelectionChange}
              onReady={handleGridReady}
              hasSelectAll={false}
              hasSelectMultiple={false}
              classes={{
                container: { 'max-height': '600px !important' },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Footer />

      {/* Create Organization Users Dialog */}
      {!!isCreateOpen && (
        <Dialog open={isCreateOpen} maxWidth="lg">
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {' '}
            <Typography sx={{ mt: 1, fontWeight: 'bold' }}>{currentRow.firstName ? 'EDIT' : 'CREATE'} USER</Typography>
            <IconButton onClick={() => handleCreateDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              minWidth: '720px',
              minHeight: '100px',
              p: 4,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <Box sx={{ minWidth: '300px' }}>
                <Typography variant="body1">First Name*</Typography>
                <TextField
                  size="small"
                  margin="dense"
                  placeholder="First Name"
                  value={userDetails.firstName || ''}
                  name="firstName"
                  error={!!errors.firstName?.trim()}
                  helperText={errors.firstName || ' '}
                  fullWidth
                  onChange={(event) => handleFieldChange(event)}
                />
              </Box>
              <Box sx={{ minWidth: '300px' }}>
                <Typography variant="body1">Last Name*</Typography>
                <TextField
                  size="small"
                  margin="dense"
                  placeholder="Last Name"
                  value={userDetails.lastName || ''}
                  name="lastName"
                  error={!!errors.lastName?.trim()}
                  helperText={errors.lastName || ' '}
                  fullWidth
                  onChange={(event) => handleFieldChange(event)}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <Box sx={{ minWidth: '300px' }}>
                <Typography variant="body1">Email*</Typography>
                <TextField
                  size="small"
                  margin="dense"
                  placeholder="Email"
                  value={userDetails.email || ''}
                  name="email"
                  fullWidth
                  error={!!errors.email?.trim()}
                  helperText={errors.email || ' '}
                  onChange={(event) => handleFieldChange(event)}
                />
              </Box>
              <Box sx={{ minWidth: '300px' }}>
                <Typography variant="body1">Phone Number</Typography>
                <TextField
                  size="small"
                  margin="dense"
                  placeholder="Phone Number"
                  value={userDetails.phoneNumber || ''}
                  name="phoneNumber"
                  fullWidth
                  error={!!errors.phoneNumber?.trim()}
                  helperText={errors.phoneNumber || ' '}
                  onChange={(event) => handleFieldChange(event)}
                />
              </Box>
            </Box>
            <Typography sx={{ mt: 1, mb: 1 }} variant="body1">
              Analysis Type*
            </Typography>
            <Dropdown
              customToggle={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'space-between',
                    padding: '8px',
                    border: '2px solid #d2d2d2',
                    borderRadius: '8px',
                    width: '300px',
                  }}
                >
                  <Typography variant="body1">
                    {userDetails.analysisType.name ? userDetails.analysisType?.name : 'Select Analysis Type'}
                  </Typography>
                  <KeyboardArrowDownIcon />
                </Box>
              }
              classes={{ menu: { minWidth: '300px' } }}
              options={[
                ...analysisTypesList.map((option) => {
                  return {
                    label: (
                      <Typography
                        key={option.id}
                        sx={{
                          ml: 4,
                          mr: 4,
                        }}
                      >
                        {option.name}
                      </Typography>
                    ),
                    onClick: (e) => handleFieldChange({ name: 'analysisType', value: option }, 'dropdown'),
                  };
                }),
              ]}
            />
            {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 1, mt: 1 }}>
              <Typography variant="body1">Is Organization Admin:</Typography>
              <Switch
                checked={userDetails.isAdmin}
                onChange={() => handleFieldChange({ name: 'isAdmin', value: !userDetails.isAdmin }, 'switch')}
              />{' '}
            </Box> */}
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button sx={{ color: 'grey.500' }} onClick={() => handleCreateDialog(false)}>
              Close
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'primary.main' }}
              disabled={isCreateButtonLoading || !isFormValid || !userDetails.analysisType.name}
              onClick={() => handleCreateUsers(userDetails, currentRow.id)}
            >
              {isCreateButtonLoading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : currentRow.firstName ? (
                'Edit'
              ) : (
                'Create'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* Delete User Dialog */}
      {!!isDeleteOpen && (
        <Dialog open={isDeleteOpen} maxWidth="md">
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {' '}
            <Typography variant="body1" sx={{ mt: 1, fontWeight: 'bold' }}>
              DELETE USER(S)
            </Typography>
            <IconButton onClick={() => handleDeleteDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Are you sure you want to delete the user(s)?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button sx={{ color: 'grey.500' }} onClick={() => handleDeleteDialog(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'error.main' }}
              disabled={isButtonLoading}
              onClick={() => handleDeleteUsers(currentRow.id ? [currentRow.id] : selectedRows)}
            >
              {' '}
              {isButtonLoading ? <CircularProgress color="inherit" size="1.5rem" /> : 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default SurveysView;
