import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

const Footer = ({ style = {} }) => {
  return (
    <Box
      sx={{
        py: 1,
        textAlign: "center",

        width: "100%",
      }}
    >
      <Typography variant="body1" sx={{ color: "grey.800" }}>
        Need support? Email us at{" "}
        <a href="mailto:support@myexcelia.com"> support@myexcelia.com</a> ©
        Copyright 2023 Excelia. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
