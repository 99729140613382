import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { DefaultLayout, PrivateRoute } from '@client/shared/components';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Subscriptions from './subscriptions';
import Typography from '@mui/material/Typography';
import DetailsModule from './details';
import LoginModule from './login';
import Dashboard from './dashboard';
import MyClients from './my-clients';
import ClientModule from './my-clients/client-data';
import MyAccountModule from './my-account';
import ResourcesModule from './resources';
import PaymentSuccess from './payment-successful';
import Surveys from './surveys';
import LogoutModule from './logout';
import AddPaymentMethod from './add-payment-method';
import ForgotPassword from './forgot-password';
import ResetPassoword from './reset-password';
import { AppService } from '../shared/services';
import { getToken, isJWTExpired } from '../utils';
import ControlPanel from './control-panel';
import OrganizationUsers from './control-panel/org-users';

let syncTimeout = null;
const App = () => {
  let userDetails = localStorage.getItem('userDetails');
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  } else {
    userDetails = {};
  }
  const hasSidebar = (currentPath = '') => {
    return (
      currentPath !== '/' &&
      currentPath !== '/login' &&
      currentPath !== '/login/' &&
      currentPath !== '/subscriptions' &&
      currentPath !== '/subscriptions/' &&
      currentPath !== '/details' &&
      currentPath !== '/details/' &&
      currentPath !== '/payment-successful/' &&
      currentPath !== '/payment-successful' &&
      currentPath !== '/payment-successful/existing' &&
      currentPath !== '/payment-successful/existing/' &&
      currentPath !== '/forgot-password/' &&
      currentPath !== '/forgot-password' &&
      currentPath !== '/reset-password/' &&
      currentPath !== '/reset-password'
    );
  };
  const handleSync = async () => {
    const { data, error } = await AppService.sync();
    if (error) {
      return toast.error(Array.isArray(error) ? error[0].message : error.message || error);
    }
  };

  const subscribeSync = async () => {
    clearInterval(syncTimeout);
    syncTimeout = setInterval(() => {
      const isLoggedOut = !!isJWTExpired(getToken()) || !hasSidebar(window.location.pathname);
      if (!isLoggedOut) {
        handleSync();
      }
    }, 15 * 1000);
  };
  const setDrawerList = () => {
    let drawerListArray = [];
    drawerListArray = [
      {
        to: '/dashboard',
        label: 'DASHBOARD',
      },
    ];
    userDetails.permissions?.readMyClients &&
      drawerListArray.push({
        to: '/clients',
        label: 'MY CLIENTS',
      });
    drawerListArray.push({
      to: '/resources',
      label: 'RESOURCES',
    });
    (userDetails.permissions?.readSurveys || userDetails.permissions?.createSurveys) &&
      drawerListArray.push({
        to: '/surveys',
        label: 'SURVEYS',
      });
    (userDetails.permissions?.createOrg ||
      userDetails.permissions?.readOrg ||
      userDetails.permissions?.readOrgUsers ||
      userDetails.permissions?.createOrgUsers) &&
      drawerListArray.push({
        to: '/control-panel',
        label: 'CONTROL PANEL',
      });
    return drawerListArray;
  };

  let drawerListBottom = [
    {
      to: '/account',
      label: 'MY ACCOUNT',
    },
    {
      to: '/logout',
      label: 'LOGOUT',
    },
  ];
  useEffect(() => {
    subscribeSync();
    return () => {
      clearInterval(syncTimeout);
      syncTimeout = null;
    };
  }, []);
  const notFound = (
    <Box
      sx={{
        height: '96vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography color="primary" variant="h1">
        404 Not Found
      </Typography>
      <Typography color="primary" variant="h6">
        The requested resourse could not be found on this server!
      </Typography>
    </Box>
  );
  const location = useLocation();
  return (
    <DefaultLayout
      drawerList={setDrawerList()}
      drawerListBottom={drawerListBottom}
      hasSidebar={hasSidebar(location.pathname)}
    >
      <Box>
        <Routes>
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/details" element={<DetailsModule />} />
          <Route path="/details/existing" element={<DetailsModule isExistingCustomer={true} />} />
          <Route path="/details/upgrade" element={<DetailsModule isUpgrading={true} />} />
          <Route path="/details/new" element={<DetailsModule isNewPlan={true} />} />
          <Route path="/payment-successful" element={<PaymentSuccess />} />
          <Route path="/payment-successful/existing" element={<PaymentSuccess isExistingCustomeer={true} />} />
          <Route path="/login" element={<LoginModule />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassoword />} />
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/clients" element={<PrivateRoute element={<MyClients />} />} />
          <Route path="/clients/:id" element={<PrivateRoute element={<ClientModule />} />} />
          <Route path="/resources" element={<PrivateRoute element={<ResourcesModule />} />} />
          <Route path="/surveys" element={<PrivateRoute element={<Surveys />} />} />
          <Route path="/account" element={<PrivateRoute element={<MyAccountModule />} />} />
          <Route path="/control-panel" element={<PrivateRoute element={<ControlPanel />} />} />
          <Route path="/control-panel/:id" element={<PrivateRoute element={<OrganizationUsers />} />} />
          <Route path="/logout" element={<PrivateRoute element={<LogoutModule />} />} />
          <Route path="/payment-method" element={<PrivateRoute element={<AddPaymentMethod />} />} />
          <Route path="/" element={<Navigate to="/subscriptions" />} />
          <Route path="*" element={notFound} />
        </Routes>
      </Box>
    </DefaultLayout>
  );
};

export default App;
