import React from "react";
import config from "@client/config";
import { getRedirectUri, getToken, isJWTExpired } from "@client/utils";

/**
 * Route component that ensures authorization for protected routes.
 */
export default ({ element = <></> }) => {
  const isAuthenticated = !isJWTExpired(getToken());
  const { appUrl } = config;
  if (isAuthenticated) {
    return element;
  } else {
    window.location.replace(getRedirectUri(appUrl));
    return null;
  }
};
