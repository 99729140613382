import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Dropdown } from '@client/shared/components';
import { VALIDATIONS, REGEX } from '@client/shared/constants';
import { validate as validator, queryStringBuilder } from '@client/utils';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OrgUsersView from './view';
import Services from './service';
import OrgServices from '../service';

let gridHelper = null;

const defaultState = {
  users: [],
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    // isAdmin: '',
    analysisType: {},
  },
  errors: {},
  isLoading: false,
  isButtonLoading: false,
  pageNumber: 1,
  pageSize: 30,
  totalRows: 0,
  order: null,
  orderBy: '',
  filters: [],
  selectedRows: [],
  isCreateOpen: false,
  isCreateButtonLoading: false,
  currentRow: {},
  isDeleteOpen: false,
  analysisTypesList: [],
  searchFilter: [],
};

const OrgUsers = () => {
  let defaultAnalysisType = localStorage.getItem('defaultAnalysisType');
  if (defaultAnalysisType) {
    defaultAnalysisType = JSON.parse(defaultAnalysisType);
  } else {
    defaultAnalysisType = {};
  }
  const [state, setState] = useState({
    ...defaultState,
    userDetails: {
      ...defaultState.userDetails,
      analysisType: defaultAnalysisType,
    },
  });
  const params = useParams();
  let userData = localStorage.getItem('userDetails');
  if (userData) {
    userData = JSON.parse(userData);
  }

  //Fetch Organization Users
  const fetchUsers = useCallback(
    async (pageNumber = 1, pageSize = 30, order = '', orderBy = '', searchFilter = [], filters = []) => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const queryString = queryStringBuilder(pageSize, (pageNumber - 1) * pageSize, searchFilter, filters, {
        field: orderBy,
        order,
      });
      const { data, error } = await Services.getOrganizationUsers(params.id, queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
      }
      setState((prevState) => ({
        ...prevState,
        users: data?.users,
        totalRows: data.count || data.users?.length,
        availableSurveyCount: data.availableSurveyCount,
        isLoading: false,
      }));
    },
    []
  );

  //Fetch Analysis Types.
  const fetchAnalysisTypes = useCallback(async () => {
    const { data, error } = await OrgServices.getAnalysisType();
    if (error) {
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      analysisTypesList: data?.analysisTypes,
    }));
  }, []);

  //Create new Organization Users
  const handleCreateUsers = useCallback(async (userDetails = {}, id) => {
    setState((prevState) => ({
      ...prevState,
      isCreateButtonLoading: true,
    }));
    const payload = !id
      ? { ...userDetails, analysisTypeId: userDetails.analysisType?.id }
      : {
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
          phoneNumber: userDetails.phoneNumber,
          // isAdmin: userDetails.isAdmin,
          analysisTypeId: userDetails.analysisType?.id,
        };
    const api = !!id ? 'patchUser' : 'createUser';
    const { error } = await Services[api](payload, params.id, id);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isCreateButtonLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      isCreateButtonLoading: false,
      userDetails: defaultState.userDetails,
    }));
    handleCreateDialog(false);
    fetchUsers();
    return toast.success(`User ${!id ? 'created' : 'edited'} successfully.`);
  }, []);

  //Delete Organization Users
  const handleDeleteUsers = useCallback(async (ids = '') => {
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: true,
    }));
    const { error } = await Services.deleteUser(params.id, ids.join(','));
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isButtonLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: false,
      userDetails: defaultState.userDetails,
      selectedRows: defaultState.selectedRows,
    }));
    handleDeleteDialog(false);
    fetchUsers();
    return toast.success(`User deleted successfully.`);
  }, []);

  //Grid handlers
  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order,
      orderBy: order ? fieldObj.field || fieldObj.fieldName : null,
      selectedRows: defaultState.selectedRows,
      pageNumber: defaultState.pageNumber,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
    // gridHelper.setSelection([selectedRows[selectedRows.length - 1]]);
  }, []);

  //Dialog handlers
  const handleCreateDialog = useCallback((value = false, row) => {
    setState((prevState) => ({
      ...prevState,
      isCreateOpen: value,
      currentRow: row,
      errors: defaultState.errors,
      userDetails: {
        firstName: !!row ? row.firstName : '',
        lastName: !!row ? row.lastName : '',
        email: !!row ? row.email : '',
        phoneNumber: !!row ? row.phoneNumber : '',
        analysisType: !!value && !!row ? row.userAnalysis[0]?.analysisType : defaultAnalysisType,
        // isAdmin: !!value && !!row && row.userOrganizationMapping?.isAdmin,
      },
    }));
  }, []);
  const handleDeleteDialog = useCallback((value = false, row = {}) => {
    setState((prevState) => ({
      ...prevState,
      isDeleteOpen: value,
      currentRow: row,
    }));
  }, []);

  const handleFieldChange = (evt, type = '') => {
    const { name, value } = !!type ? evt : evt.target;
    let errorMessage = validateFields(name, value) || ' ';
    setState((prevState) => ({
      ...prevState,
      userDetails: {
        ...prevState.userDetails,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: errorMessage,
      },
    }));
  };
  const validateFields = (field, value = '') => {
    let errorMessage = '';
    const fieldValidatorMap = {
      firstName: [{ type: VALIDATIONS.REQUIRED, value: true }],
      lastName: [{ type: VALIDATIONS.REQUIRED, value: true }],
      email: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.EMAILS, value: true },
      ],
      phoneNumber: [{ type: VALIDATIONS.REGEX, value: new RegExp(REGEX.PHONE) }],
    };

    if (fieldValidatorMap[field]) {
      const validationResult = fieldValidatorMap[field].map((validation) =>
        validator(value, validation.type, validation.value, validation.inputType || 'string', validation.message)
      );
      errorMessage = validationResult.filter((error) => !error?.isValid).map((error) => error?.errorMessage)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validateFields(key, state.userDetails[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleSearch = (keyword = '') => {
    const searchFields = ['firstName', 'lastName', 'email'];
    let searchFilter = keyword
      ? searchFields.map((element) => {
          return { field: element, value: keyword };
        })
      : [];

    setState((prevState) => ({
      ...prevState,
      searchFilter,
    }));
  };

  useEffect(() => {
    fetchUsers(state.pageNumber, state.pageSize, state.order, state.orderBy, state.searchFilter, state.filters);
  }, [fetchUsers, state.pageNumber, state.pageSize, state.order, state.orderBy, state.searchFilter, state.filters]);

  useEffect(() => {
    fetchAnalysisTypes();
  }, []);
  const moreOptions = [
    {
      id: 2,
      label: 'Edit',
      onClick: (row) => handleCreateDialog(true, row),
    },
    {
      id: 3,
      label: 'Delete',
      onClick: (row) => handleDeleteDialog(true, row),
    },
  ];
  const columnConfig = [
    {
      id: 'firstName',
      field: 'firstName',
      label: 'FIRST NAME',
      canSort: true,
      noResize: false,
      render: (row) => {
        return (
          <Tooltip title={row.firstName ? row.firstName : ''} placement="top-start">
            <Typography noWrap>{row.firstName || '-'}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: 'lastName',
      field: 'lastName',
      label: 'LAST NAME',
      canSort: true,
      noResize: false,
      render: (row) => {
        return (
          <Tooltip title={row.lastName ? row.lastName : ''} placement="top-start">
            <Typography noWrap>{row.lastName || '-'}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: 'email',
      field: 'email',
      label: 'EMAIL',
      canSort: true,
      noResize: false,
      render: (row) => {
        return (
          <Tooltip title={row.email ? row.email : ''} placement="top-start">
            <Typography noWrap>{row.email || '-'}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: 'phoneNumber',
      field: 'phoneNumber',
      label: 'PHONE NUMBER',
      canSort: true,
      noResize: false,
      render: (row) => {
        return (
          <Tooltip title={row.phoneNumber ? row.phoneNumber : ''} placement="top-start">
            <Typography noWrap>{row.phoneNumber || '-'}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: 'analysisType',
      field: 'analysisType',
      label: 'ANALYSIS TYPE',
      canSort: true,
      noResize: false,
      render: (row) => {
        const userAnalysisType = row.userAnalysis[0]?.analysisType?.name ? row.userAnalysis[0]?.analysisType?.name : '';
        return (
          <Tooltip title={userAnalysisType} placement="top-start">
            <Typography variant="body2" noWrap>
              {userAnalysisType || '-'}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: 'createdAt',
      field: 'createdAt',
      label: 'CREATED AT',
      canSort: true,
      noResize: false,
      render: (row) => {
        const date = row.createdAt ? format(new Date(row.createdAt), 'MMMM	dd, yyyy') : '';
        return (
          <Tooltip title={date ? date : ''} placement="top-start">
            <Typography variant="body2" noWrap>
              {date || '-'}
            </Typography>
          </Tooltip>
        );
      },
    },
    // {
    //   id: 'isAdmin',
    //   field: 'isAdmin',
    //   label: 'IS ORG ADMIN',
    //   canSort: true,
    //   noResize: false,
    //   render: (row) => {
    //     const isOrgAdmin = row.userOrganizationMapping?.isAdmin;
    //     return (
    //       <Tooltip
    //         title={!userData.permissions?.updateOrgUsers ? 'You do not have permission to perform this action' : ''}
    //         placement="top"
    //       >
    //         <Box>
    //           <Switch
    //             checked={isOrgAdmin}
    //             sx={{ m: 1 }}
    //             disabled={!userData.permissions?.updateOrgUsers}
    //             onChange={() => handleCreateUsers({ isAdmin: !isOrgAdmin }, row.id)}
    //           />{' '}
    //         </Box>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      id: 'col-action',
      label: '',
      isHidden: !userData.permissions?.updateOrgUsers,
      render: (row) => {
        return (
          <>
            {
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Dropdown
                  customToggle={
                    <IconButton>
                      <MoreHorizIcon color="primary" fontSize="large" />
                    </IconButton>
                  }
                  options={[
                    ...moreOptions.map((option) => {
                      const isRescheduledDisabled =
                        (option.id == 2 || option.id == 3) &&
                        (row.surveyResponseStatus == 'Cancelled' ||
                          row.surveyResponseStatus == 'Completed' ||
                          row.surveyResponseStatus == 'Partially completed' ||
                          row.surveyResponseStatus == 'Partially sent' ||
                          row.surveyResponseStatus == 'Sent');
                      const isMoneyTalkDisabled = option.id == 4 && row.surveyType == 'Midpoint';

                      return {
                        label: (
                          <Tooltip title={option.label} placement="top-start">
                            <Typography
                              variant="body2"
                              sx={{
                                mx: '20px',
                                my: '4px',
                                width: '100%',
                                height: '100%',
                                fontWeight: 'bold',
                                color: isRescheduledDisabled || isMoneyTalkDisabled ? 'grey.400' : 'primary.main',
                              }}
                            >
                              {option.label}
                            </Typography>
                          </Tooltip>
                        ),
                        disabled: isRescheduledDisabled || isMoneyTalkDisabled,
                        onClick: () => (!isRescheduledDisabled || !isMoneyTalkDisabled) && option.onClick(row),
                      };
                    }),
                  ]}
                />
              </Box>
            }
          </>
        );
      },
    },
  ];
  return (
    <OrgUsersView
      rows={state.users}
      columnConfig={columnConfig}
      isLoading={state.isLoading}
      pageNumber={state.pageNumber}
      pageSize={state.pageSize}
      order={state.order}
      orderBy={state.orderBy}
      totalRows={state.totalRows}
      isButtonLoading={state.isButtonLoading}
      currentRow={state.currentRow}
      selectedRows={state.selectedRows}
      handlePageNumberChange={handlePageNumberChange}
      handlePageSizeChange={handlePageSizeChange}
      handleSortChange={handleSortChange}
      handleSelectionChange={handleSelectionChange}
      handleFieldChange={handleFieldChange}
      handleGridReady={(instance) => (gridHelper = instance)}
      handleCreateDialog={handleCreateDialog}
      isCreateOpen={state.isCreateOpen}
      handleCreateUsers={handleCreateUsers}
      userDetails={state.userDetails}
      isCreateButtonLoading={state.isCreateButtonLoading}
      isDeleteOpen={state.isDeleteOpen}
      handleDeleteDialog={handleDeleteDialog}
      handleDeleteUsers={handleDeleteUsers}
      analysisTypesList={state.analysisTypesList}
      userData={userData}
      errors={state.errors}
      isFormValid={!validateFields()}
      handleSearch={handleSearch}
    />
  );
};

export default OrgUsers;
