import { http } from "@client/shared/services";
import { responseFormatter } from "@client/utils";

const get = () => {
  return responseFormatter(
    http.get(`/user/account`, {
      setAuthHeader: true,
    })
  );
};

const post = (payload) => {
  return responseFormatter(
    http.post(`/profile-picture/upload `, payload, {
      setAuthHeader: true,
    })
  );
};

const patchUser = (payload) => {
  return responseFormatter(
    http.patch(`/user`, payload, {
      setAuthHeader: true,
    })
  );
};

const patchPlan = (payload) => {
  return responseFormatter(
    http.patch(`/user/plan`, payload, {
      setAuthHeader: true,
    })
  );
};
const uploadPicture = (payload) => {
  return responseFormatter(
    http.post(`/profile-picture/upload`, payload, {
      setAuthHeader: true,
    })
  );
};
const cancelPlan = (payload = {}) => {
  return responseFormatter(
    http.post(`/subscription/cancel`, payload, {
      setAuthHeader: true,
    })
  );
};
const cancelScheduledPlan = (payload = {}) => {
  return responseFormatter(
    http.post(`/subscription-schedule/cancel`, payload, {
      setAuthHeader: true,
    })
  );
};

const getPaymentMethods = () => {
  return responseFormatter(
    http.get(`/payment-method`, {
      setAuthHeader: true,
    })
  );
};
const removePaymentMethod = (payload = {}) => {
  return responseFormatter(
    http.post(`/payment-method/remove`, payload, {
      setAuthHeader: true,
    })
  );
};
const reactivatePlan = (payload = {}) => {
  return responseFormatter(
    http.post(`/subscription/reactivate`, payload, {
      setAuthHeader: true,
    })
  );
};

const Service = {
  get,
  post,
  patchUser,
  patchPlan,
  cancelPlan,
  uploadPicture,
  removePaymentMethod,
  getPaymentMethods,
  reactivatePlan,
  cancelScheduledPlan,
};

export default Service;
