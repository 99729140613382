import {
  noop,
  USER_CONFIG_STORAGE_KEY,
  statesList,
} from "@client/shared/constants";
import { useNavigate } from "react-router-dom";
import { Footer } from "src/client/shared/components";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import PaymentMethod from "./payment-method";
import BgImage from "../../assets/images/background-top.png";

let paymentReference = null;
const DetailsView = ({
  tabs = [],
  currentTab = "1",
  userDetails = {},
  errors = {},
  productDetails = {},
  isFormValidated = false,
  isButtonLoading = false,
  isCheckoutLoading = false,
  isPaymentButtonLoading = false,
  checkoutError = "",
  isExistingCustomer = false,
  isUpgrading = false,
  isNewPlan = false,
  handleCreateSubscription = noop,
  handleCheckoutError = noop,
  handleTabChange = noop,
  handleFieldChange = noop,
  handleCreateUser = noop,
  handleCheckoutLoading = noop,
  handleAttatchPaymentMethod = noop,
}) => {
  const navigate = useNavigate();
  let userData = localStorage.getItem(USER_CONFIG_STORAGE_KEY);
  if (userData) {
    userData = JSON.parse(userData);
  }
  let product = localStorage.getItem("productId");
  if (product) {
    product = JSON.parse(product);
  }
  let subscription = sessionStorage.getItem("subscription");
  if (subscription) {
    subscription = JSON.parse(subscription);
  }

  const orderDetails = [
    {
      title: "Full Name",
      value: userDetails?.firstName
        ? userDetails?.firstName + " " + userDetails?.lastName
        : userData?.firstName + " " + userData?.lastName,
    },
    { title: "Email Address", value: userDetails?.email || userData?.email },
    {
      title: "Billing Address",
      value: userDetails.addressLine1
        ? userDetails.addressLine1 +
          " " +
          userDetails.addressLine2 +
          " " +
          userDetails.state +
          " " +
          userDetails.zipCode
        : userData?.addressLine1 +
          " " +
          userData?.addressLine2 +
          " " +
          userData?.state +
          " " +
          userData?.zipCode,
    },
  ];
  return (
    <Box sx={{ height: "100vh" }}>
      <Box sx={{ minHeight: "95%" }}>
        <Box
          sx={{
            height: "200px",
            backgroundImage: `url(${BgImage})`,
            backgroundSize: "100% 100%",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            p: 3,
          }}
        >
          {" "}
          {!isExistingCustomer &&
            !isUpgrading &&
            !isNewPlan &&
            currentTab == 1 && (
              <Box>
                <img
                  src="/assets/white-logo.svg"
                  alt="my-excelia"
                  width={220}
                  onClick={() => navigate("/subscriptions")}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            )}
          {!isExistingCustomer &&
            !isUpgrading &&
            !isNewPlan &&
            currentTab == 1 && (
              <Box>
                <Typography
                  sx={{
                    fontWeight: "medium",
                    color: "common.white",
                    fontSize: "1.25rem",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/subscriptions")}
                  variant="body1"
                >
                  Change Plan
                </Typography>
              </Box>
            )}
        </Box>
        <Box
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isExistingCustomer && !isUpgrading && !isNewPlan && (
            <Typography color="primary" variant="h3" sx={{ fontSize: "38px" }}>
              Let's get started with MyExcelia.
            </Typography>
          )}
          <Box
            component={Paper}
            elevation={2}
            sx={{
              backgroundColor: "grey.light",
              minHeight: "550px",
              maxWidth: "800px",
              minWidth: "700px",
              mt: "20px",
              padding: "24px 42px 32px 24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              {tabs.map((tab, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 1,
                    }}
                    // onClick={() => handleTabChange(tab.label)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        height: "20px",
                        width: "14px",
                        minWidth: "14px",
                        px: "10px",
                        cursor: "default",
                        backgroundColor:
                          currentTab === tab.label
                            ? "primary.main"
                            : "grey.400",
                      }}
                    >
                      <Typography variant="text" sx={{ color: "common.white" }}>
                        {" "}
                        {tab.label}
                      </Typography>
                    </Box>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontWeight: "medium" }}
                      color={currentTab === tab.label ? "primary" : "grey"}
                    >
                      {tab.title}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{ display: currentTab !== "2" && "none", maxWidth: "800px" }}
            >
              <PaymentMethod
                isExistingCustomer={isExistingCustomer}
                handleCreateSubscription={handleCreateSubscription}
                handleCheckoutError={handleCheckoutError}
                handleCheckoutLoading={handleCheckoutLoading}
                onReady={(e) => (paymentReference = e)}
                isPaymentButtonLoading={isPaymentButtonLoading}
                handleAttatchPaymentMethod={handleAttatchPaymentMethod}
              />
            </Box>
            {currentTab === "1" ? (
              <Box
                sx={{
                  pointerEvents: isExistingCustomer && "none",
                  minWidth: "760px",
                }}
              >
                <Typography variant="body1">Tell us about yourself:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: "12px",
                  }}
                >
                  <Box sx={{ mr: 3, minWidth: "47%" }}>
                    <Typography variant="subtitle2">First Name</Typography>
                    <TextField
                      size="small"
                      margin="dense"
                      placeholder="John "
                      value={userDetails.firstName || ""}
                      error={!!errors.firstName?.trim()}
                      helperText={errors.firstName || " "}
                      name="firstName"
                      fullWidth
                      onChange={(event) => handleFieldChange(event)}
                    />
                  </Box>
                  <Box sx={{ minWidth: "47%" }}>
                    <Typography variant="subtitle2">Last Name</Typography>
                    <TextField
                      size="small"
                      margin="dense"
                      placeholder="Smith"
                      value={userDetails.lastName || ""}
                      error={!!errors.lastName?.trim()}
                      helperText={errors.lastName || " "}
                      name="lastName"
                      fullWidth
                      onChange={(event) => handleFieldChange(event)}
                    />
                  </Box>
                </Box>
                <Box sx={{ maxWidth: "97%" }}>
                  <Typography variant="subtitle2">Email Address</Typography>
                  <TextField
                    size="small"
                    margin="dense"
                    placeholder="johnsmith1@gmail.com"
                    value={userDetails.email || ""}
                    error={!!errors.email?.trim()}
                    helperText={errors.email || " "}
                    name="email"
                    fullWidth
                    onChange={(event) => handleFieldChange(event)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ mr: 3, minWidth: "47%" }}>
                    <Typography variant="subtitle2">Password</Typography>
                    <TextField
                      size="small"
                      margin="dense"
                      type="password"
                      placeholder="Password"
                      value={userDetails.password || ""}
                      error={!!errors.password?.trim()}
                      helperText={errors.password || " "}
                      name="password"
                      autoComplete="new-password"
                      fullWidth
                      onChange={(event) => handleFieldChange(event)}
                    />
                  </Box>
                  <Box sx={{ minWidth: "47%" }}>
                    <Typography variant="subtitle2">
                      Confirm Password
                    </Typography>
                    <TextField
                      size="small"
                      margin="dense"
                      placeholder="Confirm Password"
                      type="password"
                      value={userDetails.confirmPassword || ""}
                      error={!!errors.confirmPassword?.trim()}
                      helperText={errors.confirmPassword || " "}
                      name="confirmPassword"
                      autoComplete="new-password"
                      fullWidth
                      onChange={(event) => handleFieldChange(event)}
                    />
                  </Box>
                </Box>
                <Typography variant="subtitle2">Billing Address</Typography>
                <Box sx={{ mt: 1, maxWidth: "97%" }}>
                  <Typography variant="subtitle2">Address Line 1</Typography>
                  <TextField
                    size="small"
                    margin="dense"
                    placeholder="364 Brewery Lane"
                    fullWidth
                    value={userDetails.addressLine1 || ""}
                    error={!!errors.addressLine1?.trim()}
                    helperText={errors.addressLine1 || " "}
                    name="addressLine1"
                    onChange={(event) => handleFieldChange(event)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    maxWidth: "97%",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2">Address Line 2</Typography>
                    <TextField
                      size="small"
                      margin="dense"
                      placeholder="Apt 204"
                      value={userDetails.addressLine2 || ""}
                      error={!!errors.addressLine2?.trim()}
                      helperText={errors.addressLine2 || " "}
                      name="addressLine2"
                      onChange={(event) => handleFieldChange(event)}
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">Zip Code</Typography>
                    <TextField
                      size="small"
                      margin="dense"
                      placeholder="11385"
                      value={userDetails.zipCode || ""}
                      error={!!errors.zipCode?.trim()}
                      helperText={errors.zipCode || " "}
                      name="zipCode"
                      onChange={(event) => handleFieldChange(event)}
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">State:</Typography>
                    <Autocomplete
                      size="small"
                      disablePortal={false}
                      options={statesList}
                      freeSolo
                      autoSelect
                      getOptionLabel={(option) => `${option.name}` || ""}
                      value={{ name: userDetails.state }}
                      sx={{ width: "260px", mb: 2 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          placeholder="Select State"
                        />
                      )}
                      onChange={(event, value) => {
                        if (!event || !value) return;
                        handleFieldChange(
                          {
                            name: "state",
                            value:
                              typeof value == "object" ? value.name : value,
                          },
                          "dropdown"
                        );
                      }}
                    />
                  </Box>
                </Box>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    display: "flex",
                    textAlign: "bottom",
                    backgroundColor: "white",
                    mt: 1,
                    boxShadow: 1,
                  }}
                  disabled={!isFormValidated || isButtonLoading}
                  onClick={handleCreateUser}
                >
                  {isButtonLoading ? (
                    <CircularProgress color="inherit" size="2rem" />
                  ) : (
                    "NEXT"
                  )}
                </Button>
              </Box>
            ) : currentTab === "2" ? (
              <></>
            ) : (
              <Box
                sx={{
                  p: 2,
                  minHeight: isNewPlan || isUpgrading ? "480px" : "452px",
                  position: "relative",
                }}
              >
                <Typography variant="h6">Your order summary:</Typography>
                <Box sx={{ mt: 2 }}>
                  {orderDetails.map((detail) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 1,
                      }}
                    >
                      <Typography sx={{ minWidth: "140px" }}>
                        {detail.title}:
                      </Typography>
                      <Typography>{detail.value}</Typography>
                    </Box>
                  ))}
                </Box>
                <Box
                  sx={{ mt: 4, border: "1px solid", borderColor: "grey.200" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: "20px",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                      {productDetails.productName}
                    </Typography>
                    <Typography variant="body1">
                      $
                      {isNewPlan || isUpgrading
                        ? parseInt(product?.price).toLocaleString()
                        : parseInt(productDetails.price).toLocaleString()}
                    </Typography>
                  </Box>
                  <Divider />
                  {(isNewPlan || isUpgrading) && (
                    <>
                      {" "}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          p: "20px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "medium" }}
                        >
                          Balance Adjustment
                        </Typography>
                        <Typography>
                          $
                          {parseInt(subscription?.credit).toLocaleString() ||
                            "0"}
                        </Typography>
                      </Box>
                      <Divider />
                    </>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: "20px",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                      Total Amount
                    </Typography>
                    <Typography sx={{ fontWeight: "medium" }}>
                      $
                      {isNewPlan || isUpgrading
                        ? parseInt(subscription?.amount).toLocaleString() || "0"
                        : parseInt(productDetails.price).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: "14px",
                    color: "grey.700",
                    fontWeight: "light",
                  }}
                >
                  By purchasing, you agree to these{" "}
                  <Link
                    href="https://myexcelia.mykajabi.com/pages/terms"
                    sx={{
                      textDecoration: "underline",
                      fontWeight: "light",
                      color: "primary.800",
                    }}
                    target="_blank"
                  >
                    terms{" "}
                  </Link>
                  ,{" "}
                  <Link
                    href="https://myexcelia.mykajabi.com/pages/privacy-policy"
                    sx={{
                      textDecoration: "underline",
                      fontWeight: "light",
                      color: "primary.800",
                    }}
                    target="_blank"
                  >
                    privacy policy{" "}
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="https://myexcelia.mykajabi.com/pages/earnings-disclaimer"
                    sx={{
                      textDecoration: "underline",
                      fontWeight: "light",
                      color: "primary.800",
                    }}
                    target="_blank"
                  >
                    earnings disclaimer
                  </Link>{" "}
                  .
                </Typography>
                {checkoutError && (
                  <Typography color="error" sx={{ mt: 2, mb: 3 }}>
                    {checkoutError}
                  </Typography>
                )}
                <Button
                  variant="outlined"
                  disabled={isCheckoutLoading}
                  onClick={() => {
                    isUpgrading || isNewPlan
                      ? paymentReference?.handleUpgrade &&
                        paymentReference.handleUpgrade()
                      : paymentReference?.handleCheckout &&
                        paymentReference.handleCheckout();
                  }}
                  sx={{
                    backgroundColor: "white",
                    boxShadow: 1,
                    position: "absolute",
                    bottom: 0,
                    minWidth: "95%",
                  }}
                >
                  {isCheckoutLoading ? (
                    <CircularProgress color="inherit" size="2rem" />
                  ) : (
                    "CHECKOUT"
                  )}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default DetailsView;
