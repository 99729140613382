import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { noop } from "@client/shared/constants";
import { removeUserData } from "@client/utils";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import config from "@client/config";

const stripePromise = loadStripe(config.stripePublicKey);

const options = {
  mode: "payment",
  amount: 1099,
  currency: config.currency,
  setup_future_usage: "off_session",
  payment_method_types: ["card"],
  paymentMethodCreation: "manual",
};
const defaultState = {
  errorMessage: "",
  clientSecret: "",
  isButtonLoading: false,
};
const CheckoutForm = ({
  isExistingCustomer = false,
  handleCreateSubscription = noop,
  handleCheckoutError = noop,
  handleCheckoutLoading = noop,
  onReady = noop,
  isPaymentButtonLoading = noop,
  handleAttatchPaymentMethod = noop,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [state, setState] = useState(defaultState);
  const navigate = useNavigate();
  let subscription = sessionStorage.getItem("subscription");
  if (subscription) {
    subscription = JSON.parse(subscription);
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: true,
    }));
    if (submitError) {
      return setState((prevState) => ({
        ...prevState,
        isButtonLoading: false,
        errorMessage: submitError.message,
      }));
    }
    //Create a payment method on stripe
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      elements,
    });
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isButtonLoading: false,
      }));
      return toast.error(error.message);
    } else {
      if (paymentMethod.id) {
        const { error } = await handleAttatchPaymentMethod(paymentMethod.id);
        if (error) {
          return setState((prevState) => ({
            ...prevState,
            isButtonLoading: false,
          }));
        }
        setState((prevState) => ({
          ...prevState,
          isButtonLoading: false,
        }));
        toast.success("Payment Method added successfully!");
      }
    }
    const { clientSecret: secret } = await handleCreateSubscription();
    return setState((prevState) => ({
      ...prevState,
      clientSecret: secret,
    }));
  };
  const handleCheckout = useCallback(async () => {
    handleCheckoutLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: state.clientSecret,
      confirmParams: {
        return_url: isExistingCustomer
          ? `${config.appUrl}/payment-successful/existing`
          : `${config.appUrl}/payment-successful`,
      },
    });
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment.
      handleCheckoutError(error.message);
      handleCheckoutLoading(false);
    } else {
      sessionStorage.removeItem("subscription");
      removeUserData();
      handleCheckoutLoading(false);
      isExistingCustomer && navigate("/account");
      return toast.success("Payment successful.");
    }
  });

  const handleUpgrade = useCallback(async () => {
    handleCheckoutLoading(true);
    const { error } = await stripe.confirmCardPayment(
      subscription?.clientSecret,
      {
        payment_method: subscription?.paymentMethodId,
        return_url: `${config.appUrl}/payment-successful/existing`,
      }
    );
    if (error) {
      handleCheckoutError(error.message);
      handleCheckoutLoading(false);
    } else {
      sessionStorage.removeItem("subscription");
      handleCheckoutLoading(false);
      navigate("/account");
      return toast.success("Payment successful.");
    }
  });

  onReady({
    handleCheckout,
    handleUpgrade,
  });
  return (
    <Box sx={{ p: 3 }}>
      {" "}
      <Typography
        variant="body1"
        color="primary"
        sx={{ fontWeight: "medium", mb: 2 }}
      >
        Create Payment Method
      </Typography>
      <PaymentElement />
      {state.errorMessage && (
        <Typography color="error" sx={{ mt: 2 }}>
          {state.errorMessage}
        </Typography>
      )}
      <Button
        disabled={
          !stripe ||
          !elements ||
          isPaymentButtonLoading ||
          state.isButtonLoading
        }
        onClick={handleSubmit}
        variant="outlined"
        fullWidth
        sx={{
          display: "flex",
          textAlign: "bottom",
          backgroundColor: "white",
          mt: 4,
          boxShadow: 1,
        }}
      >
        {isPaymentButtonLoading || state.isButtonLoading ? (
          <CircularProgress color="inherit" size="2rem" />
        ) : (
          "NEXT"
        )}
      </Button>
    </Box>
  );
};

const PaymentMethod = ({
  handleCreateSubscription = noop,
  onReady = noop,
  handleCheckoutError = noop,
  handleCheckoutLoading = noop,
  handleAttatchPaymentMethod = noop,
  isPaymentButtonLoading = false,
  isExistingCustomer = false,
}) => {
  // const fonts = [
  //   {
  //     family: "ff-scala-sans-pro-regular",
  //     src: "url(https://use.typekit.net/af/395f1b/0000000000000000774ff1eb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3.woff)",
  //   },
  // ];
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        handleCreateSubscription={handleCreateSubscription}
        handleCheckoutLoading={handleCheckoutLoading}
        handleCheckoutError={handleCheckoutError}
        onReady={onReady}
        isPaymentButtonLoading={isPaymentButtonLoading}
        handleAttatchPaymentMethod={handleAttatchPaymentMethod}
        isExistingCustomer={isExistingCustomer}
      />
    </Elements>
  );
};

export default PaymentMethod;
