import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken, isJWTExpired } from "@client/utils";

import PaymentSuccessView from "./view";

const PaymentSuccess = ({ isExistingCustomeer = false }) => {
  const navigate = useNavigate();
  const isAuthenticated = !isJWTExpired(getToken());
  const handleRedirect = () => {
    if (isAuthenticated && isExistingCustomeer) {
      navigate("/account");
    } else {
      return;
    }
  };

  useEffect(() => {
    setTimeout(() => handleRedirect(), 2000);
  }, []);

  return <PaymentSuccessView isExistingCustomeer={isExistingCustomeer} />;
};

export default PaymentSuccess;
