import { useEffect, useState, useCallback } from 'react';
import { queryStringBuilder } from '@client/utils';
import { toast } from 'react-toastify';
import ClientServices from '../my-clients/service';
import SurveyServices from '../surveys/service';
import DashboardView from './view';
import Service from './service';

const defaultState = {
  clients: [],
  isLoading: false,
  pageNumber: 1,
  pageSize: 5,
  order: null,
  orderBy: '',
  totalRows: 0,
  searchFilter: [],
  selectedRow: null,
  isFiltering: false,
  isSurveyLoading: false,
  isButtonLoading: false,
  isLinkCopied: false,
  availableSurveyCount: 0,
  isSurveySent: false,
  isQuoteLoading: false,
  surveyLink: '',
  quote: '',
  updates: [],
  isUpdateLoading: false,
  isLoadingName: false,
};

let gridHelper = null;

const Dashboard = () => {
  const [state, setState] = useState(defaultState);
  let userDetails = localStorage.getItem('userDetails');
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  } else {
    userDetails = {};
  }
  //Fetch User Clients
  const fetchClients = useCallback(
    async (pageNumber = 1, pageSize = 5, order = '', orderBy = '', searchFilter = []) => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const queryString = queryStringBuilder(pageSize, (pageNumber - 1) * pageSize, searchFilter, [], {
        field: orderBy,
        order,
      });
      const { data, error } = await ClientServices.getClients(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
      }
      setState((prevState) => ({
        ...prevState,
        clients: data.clients,
        totalRows: data.count,
        isLoading: false,
      }));
    },
    []
  );
  //Fetch Random inspiration quote
  const fetchQuote = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isQuoteLoading: true,
    }));
    const { data, error } = await Service.getQuote();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isQuoteLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    return setState((prevState) => ({
      ...prevState,
      quote: data.quote,
      isQuoteLoading: false,
    }));
  }, []);

  //Fetch latest survey updates
  const fetchUpdates = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isUpdateLoading: true,
    }));
    const { data, error } = await Service.getUpdates();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isUpdateLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    return setState((prevState) => ({
      ...prevState,
      updates: data.auditHistory,
      isUpdateLoading: false,
    }));
  }, []);

  //Fetch Surveys
  const fetchSurveys = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isSurveyLoading: true,
    }));
    const { data, error } = await SurveyServices.getSurveys();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isSurveyLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      availableSurveyCount: data.availableSurveyCount,
      isSurveyLoading: false,
    }));
  }, []);

  //Create new Surveys
  const handleSendSurvey = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: true,
    }));
    const { data, error } = await SurveyServices.postSurvey({});
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isButtonLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: false,
      isSurveySent: true,
      surveyLink: data.url,
    }));
    fetchSurveys();
    window.open(data.url, '_blank');
  }, []);

  //Grid handlers
  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order,
      orderBy: order ? fieldObj.field || fieldObj.fieldName : null,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSearch = (keyword = '') => {
    const searchFields = ['clientFirstName', 'clientEmail', 'clientStatus', 'phoneNumber'];
    let searchFilter = keyword
      ? searchFields.map((element) => {
          return { field: element, value: keyword };
        })
      : [];

    setState((prevState) => ({
      ...prevState,
      searchFilter,
      isSearching: true,
    }));
  };

  const handleSelectionChange = useCallback((selectedRow) => {
    setState((prevState) => ({
      ...prevState,
      selectedRow: selectedRow[selectedRow.length - 1],
    }));
    gridHelper.setSelection([selectedRow[selectedRow.length - 1]]);
  }, []);

  const handleCopiedLink = (value) => {
    setState((prevState) => ({
      ...prevState,
      isLinkCopied: value,
    }));
  };
  const handleSuccessClose = () => {
    setState((prevState) => ({
      ...prevState,
      isSurveySent: false,
    }));
  };

  useEffect(() => {
    if (userDetails.permissions?.readMyClients) {
      fetchClients(state.pageNumber, state.pageSize, state.order, state.orderBy, state.searchFilter);
    }
  }, [fetchClients, state.pageNumber, state.pageSize, state.order, state.orderBy, state.searchFilter]);
  useEffect(() => {
    fetchSurveys();
    fetchQuote();
    fetchUpdates();
  }, []);
  return (
    <DashboardView
      rows={state.clients}
      pageNumber={state.pageNumber}
      pageSize={state.pageSize}
      order={state.order}
      orderBy={state.orderBy}
      totalRows={state.totalRows}
      isLoading={state.isLoading}
      availableSurveyCount={state.availableSurveyCount}
      isSurveyLoading={state.isSurveyLoading}
      isButtonLoading={state.isButtonLoading}
      surveyLink={state.surveyLink}
      isSurveySent={state.isSurveySent}
      isLinkCopied={state.isLinkCopied}
      quote={state.quote}
      isQuoteLoading={state.isQuoteLoading}
      isUpdateLoading={state.isUpdateLoading}
      updates={state.updates}
      fullName={userDetails.firstName + ' ' + userDetails.lastName}
      isLoadingName={state.isLoadingName}
      handlePageNumberChange={handlePageNumberChange}
      handlePageSizeChange={handlePageSizeChange}
      handleSortChange={handleSortChange}
      handleSelectionChange={handleSelectionChange}
      handleSendSurvey={handleSendSurvey}
      handleGridReady={(instance) => (gridHelper = instance)}
      handleCopiedLink={handleCopiedLink}
      handleSuccessClose={handleSuccessClose}
      handleSearch={handleSearch}
    />
  );
};

export default Dashboard;
