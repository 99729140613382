import { useNavigate } from "react-router-dom";
import { Footer } from "@client/shared/components";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import styles from "./style";

const ForgotPasswordView = ({
  email = "",
  error = "",
  isFormValid = false,
  isButtonLoading = false,
  handleFieldChange = () => {},
  handleForgotPassword = () => {},
}) => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      component="main"
      sx={{
        backgroundImage:
          "linear-gradient(60deg, #6DACD7 0%, #94B7BD 30%, #94B7BD 60%, #6DACD7 90%);",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          minHeight: "95%",
        }}
      >
        <Grid
          item
          sm={12}
          md={6}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 6,
            color: "common.white",
          }}
        >
          <Typography variant="h6">
            Enter the email address associated with your account and we'll send
            you a link to reset your password.
          </Typography>
          <Box sx={{ p: 2 }}>
            <TextField
              value={email}
              onChange={handleFieldChange}
              margin="dense"
              name="email"
              placeholder="Email"
              variant="outlined"
              fullWidth
              autoComplete="new-password"
              error={!!error?.trim()}
              helperText={error || " "}
              sx={styles.input}
            />

            <Button
              variant="contained"
              onClick={() => handleForgotPassword(email)}
              fullWidth
              disabled={isFormValid || !email || isButtonLoading}
              sx={{ mt: 3, bgcolor: "primary.dark" }}
              size="large"
            >
              {isButtonLoading ? (
                <CircularProgress color="inherit" size="2rem" />
              ) : (
                "Continue"
              )}
            </Button>

            <Typography sx={{ mt: 2 }}>
              Don't have an account yet?
              <Button
                sx={{
                  textDecoration: "underline",
                  color: "common.white",
                  display: "inline",
                  mb: "4px",
                }}
                onClick={() => navigate("/")}
                size="large"
              >
                {" "}
                Sign Up
              </Button>{" "}
            </Typography>
          </Box>{" "}
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default ForgotPasswordView;
