import { useEffect, useState, useCallback } from "react";
import format from "date-fns/format";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ClientDataView from "./view";
import Services from "../service";

let gridHelper = null;
const defaultState = { clientData: {}, isLoading: false };
const ClientModule = () => {
  const params = useParams();

  const [state, setState] = useState(defaultState);

  //Fetch Survey Details of a Client
  const fetchClientDetails = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const { data, error } = await Services.getClientDetails(params.id);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].error || error[0].message : error
      );
    }
    setState((prevState) => ({
      ...prevState,
      clientData: data.clients,
      isLoading: false,
    }));
  }, []);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order,
      orderBy: order ? fieldObj.field || fieldObj.fieldName : null,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);
  const handleSelectionChange = useCallback((selectedRow) => {
    setState((prevState) => ({
      ...prevState,
      selectedRow: selectedRow[selectedRow.length - 1],
    }));
    gridHelper.setSelection([selectedRow[selectedRow.length - 1]]);
  }, []);

  const columnConfig = [
    {
      id: "survey",
      field: "survey",
      label: "SURVEY",
      // canSort: true,
      noResize: false,
      render: (row) => {
        return (
          <Tooltip
            title={row.surveyType ? row.surveyType : ""}
            placement="top-start"
          >
            <Typography sx={{ fontWeight: "medium" }} variant="body2" noWrap>
              {row.surveyType || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "status",
      field: "status",
      label: "STATUS",
      // canSort: true,
      noResize: false,

      render: (row) => {
        const status = row.surveyResponseStatus ? row.surveyResponseStatus : "";
        return (
          <Button
            variant="contained"
            sx={{
              backgroundColor:
                status === "Cancelled" ? "error.light" : "primary.light",
              color: status === "Cancelled" ? "grey.50" : "primary.400",
            }}
          >
            {status || "-"}
          </Button>
        );
      },
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      label: "LAST UPDATED",
      // canSort: true,
      noResize: false,
      render: (row) => {
        const date = row.updatedAt
          ? format(new Date(row.updatedAt), "MMMM	dd, yyyy")
          : "";
        return (
          <Tooltip title={date} placement="top-start">
            <Typography sx={{ fontWeight: "medium" }} variant="body2" noWrap>
              {date || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    fetchClientDetails();
  }, []);
  return (
    <ClientDataView
      rows={state.clientData.me_portal_clientResponseMeta}
      columnConfig={columnConfig}
      clientData={state.clientData}
      isLoading={state.isLoading}
      handlePageNumberChange={handlePageNumberChange}
      handlePageSizeChange={handlePageSizeChange}
      handleSortChange={handleSortChange}
      handleSelectionChange={handleSelectionChange}
      handleGridReady={(instance) => (gridHelper = instance)}
    />
  );
};

export default ClientModule;
