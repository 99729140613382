import { http } from "@client/shared/services";
import { responseFormatter } from "@client/utils";

const addPaymentMethod = (payload, isLoggedIn = false) => {
  const header = isLoggedIn
    ? {
        setAuthHeader: true,
      }
    : {
        setUserAuthHeader: true,
      };
  return responseFormatter(http.post(`/payment-method/add`, payload, header));
};
const Services = {
  addPaymentMethod,
};

export default Services;
