import { http } from "@client/shared/services";
import { responseFormatter } from "@client/utils";

const getSubscriptions = () => {
  return responseFormatter(http.get(`/products`));
};

const Services = { getSubscriptions };

export default Services;
