import { noop, statesList, roleList } from '@client/shared/constants';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import { Footer } from 'src/client/shared/components';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import UpdatePlanDialog from './update-plan';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';
import WarningIcon from '@mui/icons-material/Warning';
import ChangePassword from './change-password';

const MyAccountView = ({
  userDetails = {},
  fullName = '',
  errors = {},
  isLoading = false,
  isSaveLoading = false,
  isCancelLoading = false,
  isBillingDialogOpen = false,
  billingDetails = {},
  products = [],
  isEditPlanOpen = false,
  isProductsLoading = false,
  choosenPlan = '',
  isFormChanged = false,
  isFormValidated = false,
  isUploading = false,
  isConfirmationOpen = false,
  isRemoveLoading = false,
  isSwitchConfirmationOpen = false,
  isSwitchConfirmationLoading = false,
  hasBillingFormChanged = false,
  paymentMethods = {},
  billingErrors = {},
  isBillingFormValidated = false,
  isPaymentSettled = false,
  showChangePasswordDialog = false,
  isLeavingPage = false,
  isCancelConfirmationOpen = false,
  isCancelConfirmed = false,
  handleFieldChange = noop,
  handleBillingDialog = noop,
  handleBillingFieldChange = noop,
  handleEditUser = noop,
  handleEditPlanDialog = noop,
  handleUpdatePlan = noop,
  handleCancelPlan = noop,
  handleUploadPicture = noop,
  handleConfirmationDialog = noop,
  handleRemoveCard = noop,
  handleSwitchConfirmation = noop,
  handleReactivatePlan = noop,
  onToggleChangePasswordDialog = noop,
  handleLeavePage = noop,
  handleCancelDialog = noop,
  handleNext = noop,
}) => {
  const navigate = useNavigate();
  const getPaymentMethod = (isConfirmation = false) => {
    return (
      <Box>
        {' '}
        {!isConfirmation && (
          <>
            {' '}
            <Typography variant="body1">Payment Method</Typography>
            <Divider
              sx={{
                m: '12px 0px',
                borderBottomWidth: 1,
                borderColor: 'grey.200',
              }}
            />
          </>
        )}
        {paymentMethods?.card ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 1,
            }}
          >
            <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
              <Box variant="h6">
                {paymentMethods?.card?.brand === 'visa' ? (
                  <img src="/assets/visa.svg" alt="visa" />
                ) : paymentMethods?.card?.brand === 'mastercard' ? (
                  <img src="/assets/mastercard.svg" alt="" />
                ) : paymentMethods?.card?.brand === 'amex' ? (
                  <img src="/assets/american-express.svg" alt="" />
                ) : (
                  <Typography>{paymentMethods?.card?.brand}</Typography>
                )}
              </Box>
              <Typography variant="h6">************{paymentMethods?.card?.last4}</Typography>
            </Box>{' '}
            {!isConfirmation && (
              <Tooltip title="Remove card" placement="top-start">
                <IconButton onClick={() => handleConfirmationDialog(true)}>
                  <HighlightOffIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 1,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
              No Payment Methods found
            </Typography>
            <Button
              variant="outlined"
              onClick={() => (hasBillingFormChanged ? handleLeavePage(true) : navigate('/payment-method'))}
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: 'linear-gradient(160deg, #D4DDDC 0%, #FFFFFF 60%, #FFFFFF 80%, #F8E6E4 90%);',
          height: '100vh',
          pt: 6,
          pl: 12,
          pr: 12,
        }}
      >
        <Box sx={{ minHeight: '95%' }}>
          <Typography color="primary" variant="h3">
            My Account
          </Typography>
          <Box
            component={Paper}
            sx={{
              p: 2,
              mt: 3,
              minHeight: '760px',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Avatar
                  alt={fullName}
                  sx={{ width: 52, height: 52 }}
                  src={userDetails.profileImage ? `data:image/jpeg;base64,${userDetails.profileImage}` : ''}
                />
                {isLoading ? (
                  <Skeleton sx={{ height: '50px', minWidth: '200px' }} />
                ) : (
                  <Typography variant="body1">{fullName}</Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                {' '}
                <input
                  style={{ display: 'none' }}
                  id="profile-picture"
                  type="file"
                  accept="image/*"
                  onChange={handleUploadPicture}
                />
                <Button
                  component="label"
                  htmlFor="profile-picture"
                  sx={{ backgroundColor: 'primary.50', px: 4 }}
                  disabled={isUploading}
                >
                  {isUploading ? <CircularProgress color="inherit" size="1.5rem" /> : 'Upload New Picture'}
                </Button>
                <Button sx={{ backgroundColor: 'primary.50', px: 4 }} onClick={() => handleBillingDialog(true)}>
                  Update Billing Info
                </Button>
              </Box>
            </Box>
            <Divider
              sx={{
                m: 1,
                borderBottomWidth: 1,
                borderColor: 'grey.300',
              }}
            />
            <Box sx={{ p: 2, mt: 2 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 2 }}>
                Basic Information
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: '12px',
                  justifyContent: 'space-between',
                }}
              >
                {!isLoading ? (
                  <Box sx={{ minWidth: '22%' }}>
                    <Typography variant="subtitle2">First Name</Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      placeholder="First Name "
                      value={userDetails?.firstName || ''}
                      error={!!errors.firstName?.trim()}
                      helperText={errors.firstName || ' '}
                      name="firstName"
                      onChange={(event) => handleFieldChange(event)}
                      fullWidth
                    />
                  </Box>
                ) : (
                  <Skeleton sx={{ height: '50px', minWidth: '200px' }} />
                )}
                {!isLoading ? (
                  <Box sx={{ minWidth: '22%' }}>
                    <Typography variant="subtitle2">Last Name</Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      placeholder="Last Name"
                      value={userDetails?.lastName || ''}
                      error={!!errors.lastName?.trim()}
                      helperText={errors.lastName || ' '}
                      name="lastName"
                      onChange={(event) => handleFieldChange(event)}
                      fullWidth
                    />
                  </Box>
                ) : (
                  <Skeleton sx={{ height: '50px', minWidth: '200px' }} />
                )}
                {!isLoading ? (
                  <Box sx={{ minWidth: '22%' }}>
                    <Typography variant="subtitle2" sx={{ color: 'grey.500' }}>
                      Email Address
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      placeholder="Email"
                      value={userDetails?.email || ''}
                      error={!!errors.email?.trim()}
                      helperText={errors.email || ' '}
                      name="email"
                      autoComplete="new-password"
                      // onChange={(event) => handleFieldChange(event)}
                      fullWidth
                      disabled
                    />
                  </Box>
                ) : (
                  <Skeleton sx={{ height: '50px', minWidth: '200px' }} />
                )}
                {!isLoading ? (
                  <Box sx={{ minWidth: '22%' }}>
                    <Typography variant="subtitle2">Phone Number</Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      placeholder="+0987654321"
                      value={userDetails?.phoneNumber || ''}
                      error={!!errors.phoneNumber?.trim()}
                      helperText={errors.phoneNumber || ' '}
                      name="phoneNumber"
                      onChange={(event) => handleFieldChange(event)}
                      fullWidth
                    />
                  </Box>
                ) : (
                  <Skeleton sx={{ height: '50px', minWidth: '200px' }} />
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: '12px',
                  justifyContent: 'space-between',
                  width: '48%',
                }}
              >
                {' '}
                {!isLoading ? (
                  <Box sx={{ minWidth: '46%' }}>
                    <Typography variant="subtitle2" sx={{ color: 'grey.500' }}>
                      Role
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      // placeholder="Role"
                      value={userDetails?.userRole ? userDetails?.userRole[0]?.role?.roleName : ''}
                      error={!!errors.role?.trim()}
                      helperText={errors.role || ' '}
                      name="role"
                      autoComplete="new-password"
                      disabled
                      // onChange={(event) => handleFieldChange(event)}
                      fullWidth
                    />
                    {/* <Autocomplete
                      size="small"
                      autoSelect
                      disablePortal={false}
                      options={roleList}
                      getOptionLabel={(option) => `${option}` || ''}
                      sx={{ maxWidth: '240px', mb: 2 }}
                      renderInput={(params) => <TextField {...params} label="" placeholder="Select Role" />}
                      value={billingDetails.role}
                      onChange={(event, value) => {
                        if (!event || !value) return;
                        handleFieldChange(
                          {
                            name: 'role',
                            value: value,
                          },
                          'dropdown'
                        );
                      }}
                    /> */}
                  </Box>
                ) : (
                  <Skeleton sx={{ height: '50px', minWidth: '200px' }} />
                )}
                {!isLoading ? (
                  <Box sx={{ minWidth: '46%' }}>
                    <Typography variant="subtitle2">Website</Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      placeholder="Website"
                      value={userDetails?.website || ''}
                      error={!!errors.website?.trim()}
                      helperText={errors.website || ' '}
                      name="website"
                      onChange={(event) => handleFieldChange(event)}
                      fullWidth
                    />
                  </Box>
                ) : (
                  <Skeleton sx={{ height: '50px', minWidth: '200px' }} />
                )}
                {/* {!isLoading ? (
                <Box sx={{ minWidth: "22%", maxWidth: "27%" }}>
                  <Typography variant="subtitle2">Password</Typography>
                  <TextField
                    size="small"
                    margin="normal"
                    placeholder="Password"
                    type="password"
                    value={userDetails?.password || ""}
                    error={!!errors.password?.trim()}
                    helperText={errors.password || " "}
                    name="password"
                    autoComplete="new-password"
                    onChange={(event) => handleFieldChange(event)}
                    fullWidth
                  />
                </Box>
              ) : (
                <Skeleton sx={{ height: "50px", minWidth: "200px" }} />
              )}
              {!isLoading ? (
                <Box
                  sx={{
                    minWidth: "22%",
                    display: !userDetails.password && "none",
                  }}
                >
                  <Typography variant="subtitle2">Confirm password</Typography>
                  <TextField
                    size="small"
                    margin="normal"
                    placeholder="Confirm password"
                    type="password"
                    value={userDetails?.confirmPassword || ""}
                    error={!!errors.confirmPassword?.trim()}
                    helperText={errors.confirmPassword || " "}
                    name="confirmPassword"
                    onChange={(event) => handleFieldChange(event)}
                    fullWidth
                  />
                </Box>
              ) : (
                <Skeleton sx={{ height: "50px", minWidth: "200px" }} />
              )} */}
                {/* {!isLoading ? (
                <Box
                  sx={{
                    minWidth: "22%",
                    display: userDetails.password && "none",
                  }}
                />
              ) : (
                <Skeleton sx={{ height: "50px", minWidth: "200px" }} />
              )} */}
              </Box>
            </Box>
            <Box>
              <Button sx={{ textDecoration: 'underline', ml: 1 }} onClick={() => onToggleChangePasswordDialog(true)}>
                Change Password
              </Button>
            </Box>
            <Box
              sx={{
                backgroundColor: 'primary.50',
                p: 2,
                mt: 3,
                borderRadius: '12px',
              }}
            >
              <Typography variant="body2">Your Current Plan</Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: 1,
                  mb: 1,
                }}
              >
                {isLoading ? (
                  <Skeleton sx={{ height: '40px', minWidth: '400px' }} />
                ) : (
                  <Typography variant="h3" color={userDetails.currentSubscription ? 'primary' : 'grey'}>
                    {userDetails.currentSubscription
                      ? userDetails?.currentSubscription?.me_portal_product?.productName
                      : 'No Active Subscriptions'}
                  </Typography>
                )}
                <Button sx={{ textDecoration: 'underline' }} onClick={() => handleEditPlanDialog(true)}>
                  Edit Plan Details
                </Button>
              </Box>
              {isLoading ? (
                <Skeleton sx={{ height: '40px', maxWidth: '200px' }} />
              ) : (
                <Typography variant="subtitle2">
                  {userDetails.currentSubscription ? userDetails?.currentSubscription?.availableSurveyCount : 0}{' '}
                  {userDetails?.currentSubscription?.availableSurveyCount == '1' ? 'survey' : 'surveys'} remaining
                </Typography>
              )}
              <Typography variant="body1" sx={{ mt: 1 }}>
                {userDetails.currentSubscription &&
                !userDetails.currentSubscription?.isCancelled &&
                !userDetails.scheduledSubscription?.subscriptionId
                  ? `Renews on ${format(new Date(userDetails.currentSubscription?.endDate), 'MM/dd/yyyy')}`
                  : userDetails.currentSubscription
                  ? `Expires on ${format(new Date(userDetails.currentSubscription?.endDate), 'MM/dd/yyyy')}`
                  : ''}
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'primary.main',
                // color: "primary.400",
                position: 'absolute',
                bottom: 30,
                right: 30,
              }}
              size="large"
              onClick={() =>
                handleEditUser({
                  firstName: userDetails.firstName,
                  lastName: userDetails.lastName,
                  email: userDetails.email,
                  phoneNumber: userDetails.phoneNumber ? userDetails.phoneNumber : null,
                  website: userDetails.website ? userDetails.website : null,
                })
              }
              disabled={isSaveLoading || !isFormChanged || !isFormValidated}
            >
              {isSaveLoading ? <CircularProgress color="inherit" size="1.5rem" /> : 'Save Changes'}
            </Button>
          </Box>
        </Box>
        <Footer />
      </Box>

      {/* Billing Info Edit Dialog */}
      {!!isBillingDialogOpen && (
        <Dialog open={isBillingDialogOpen} maxWidth="xl">
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {' '}
            <Typography variant="body1" sx={{ mt: 1, fontWeight: 'bold' }}>
              EDIT BILLING INFO
            </Typography>
            <IconButton onClick={() => handleBillingDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              minHeight: '240px',
              mt: 2,
              p: 4,
              minWidth: '90%',
            }}
          >
            {' '}
            <Typography variant="body1">Billing Address</Typography>
            <Divider
              sx={{
                m: '12px 0px',
                borderBottomWidth: 1,
                borderColor: 'grey.200',
              }}
            />
            <Box>
              <Typography variant="subtitle2">Address Line 1</Typography>
              <TextField
                size="small"
                margin="dense"
                placeholder="364 Brewery Lane"
                fullWidth
                value={billingDetails?.addressLine1 || ''}
                error={!!billingErrors?.addressLine1?.trim()}
                helperText={billingErrors?.addressLine1 || ' '}
                name="addressLine1"
                onChange={(event) => handleBillingFieldChange(event)}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Box>
                <Typography variant="subtitle2">Address Line 2</Typography>
                <TextField
                  size="small"
                  margin="dense"
                  placeholder="Apt 204"
                  value={billingDetails?.addressLine2 || ''}
                  error={!!billingErrors?.addressLine2?.trim()}
                  helperText={billingErrors?.addressLine2 || ' '}
                  name="addressLine2"
                  onChange={(event) => handleBillingFieldChange(event)}
                />
              </Box>
              <Box sx={{ ml: 3, mr: 3 }}>
                <Typography variant="subtitle2">Zip Code</Typography>
                <TextField
                  size="small"
                  margin="dense"
                  placeholder="11385"
                  value={billingDetails?.zipCode || ''}
                  error={!!billingErrors?.zipCode?.trim()}
                  helperText={billingErrors?.zipCode || ' '}
                  name="zipCode"
                  onChange={(event) => handleBillingFieldChange(event)}
                />
              </Box>

              <Box>
                <Typography variant="subtitle2">State:</Typography>
                <Autocomplete
                  size="small"
                  freeSolo
                  autoSelect
                  disablePortal={false}
                  options={statesList}
                  getOptionLabel={(option) => `${option.name}` || ''}
                  sx={{ width: '260px', mb: 2 }}
                  renderInput={(params) => <TextField {...params} label="" placeholder="Select State" />}
                  value={{ name: billingDetails.state || '' }}
                  onChange={(event, value) => {
                    if (!event || !value) return;
                    handleBillingFieldChange(
                      {
                        name: 'state',
                        value: typeof value == 'object' ? value.name : value,
                      },
                      'dropdown'
                    );
                  }}
                />
              </Box>
            </Box>
            {!userDetails.firstTimeUser && getPaymentMethod()}
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button sx={{ color: 'grey.500' }} onClick={() => handleBillingDialog(false)}>
              Close
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'primary.main' }}
              disabled={isSaveLoading || !hasBillingFormChanged || !isBillingFormValidated}
              onClick={() =>
                handleEditUser({
                  addressLine1: billingDetails.addressLine1,
                  addressLine2: billingDetails.addressLine2,
                  state: billingDetails.state,
                  zipCode: billingDetails.zipCode,
                })
              }
            >
              {isSaveLoading ? <CircularProgress color="inherit" size="1.5rem" /> : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Subscription Plan Edit Dialog */}
      <UpdatePlanDialog
        isEditPlanOpen={isEditPlanOpen}
        products={products}
        userDetails={userDetails}
        isLoading={isProductsLoading}
        isSaveLoading={isSaveLoading}
        isCancelLoading={isCancelLoading}
        choosenPlan={choosenPlan}
        isConfirmationLoading={isSwitchConfirmationLoading}
        isConfirmationOpen={isSwitchConfirmationOpen}
        isCancelConfirmed={isCancelConfirmed}
        paymentMethods={paymentMethods}
        handleEditPlanDialog={handleEditPlanDialog}
        handleUpdatePlan={handleUpdatePlan}
        handleCancelPlan={handleCancelPlan}
        handleConfirmationDialog={handleSwitchConfirmation}
        handleReactivatePlan={handleReactivatePlan}
        getPaymentMethod={getPaymentMethod}
        isCancelConfirmationOpen={isCancelConfirmationOpen}
        handleCancelDialog={handleCancelDialog}
        handleNext={handleNext}
      />
      {!!isConfirmationOpen && (
        <Dialog open={isConfirmationOpen} maxWidth="md">
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {' '}
            <Typography variant="body1" sx={{ mt: 1, fontWeight: 'bold' }}>
              REMOVE PAYMENT METHOD
            </Typography>
            <IconButton onClick={() => handleConfirmationDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Are you sure you want to remove this payment method? Removing it means that you won't be able to use this
              payment method without adding it again.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button sx={{ color: 'grey.500' }} onClick={() => handleConfirmationDialog(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'error.main' }}
              disabled={isRemoveLoading}
              onClick={() => handleRemoveCard(paymentMethods.id)}
            >
              {' '}
              {isRemoveLoading ? <CircularProgress color="inherit" size="1.5rem" /> : 'Remove'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {!!isPaymentSettled && (
        <Dialog open={isPaymentSettled} maxWidth="md">
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {' '}
            <Typography variant="body1" sx={{ mt: 1, fontWeight: 'bold' }}>
              PAYMENT SETTLED
            </Typography>
            <IconButton onClick={() => handleConfirmationDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Payment for first month has been deducted from your account balance. {choosenPlan.productName || '-'} has
              been activated
            </Typography>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button sx={{ color: 'grey.500' }} onClick={() => handleConfirmationDialog(false)}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {!!isLeavingPage && (
        <Dialog open={isLeavingPage}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {' '}
            <IconButton onClick={() => handleLeavePage(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ minWidth: '450px' }}>
            <Typography variant="h6">Do you want to leave this page?</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 2,
              }}
            >
              {' '}
              <WarningIcon color="warning" sx={{ mr: 2 }} />
              <Typography variant="body1">Change you have made will not be saved.</Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button sx={{ color: 'grey.500' }} onClick={() => handleLeavePage(false)}>
              Stay
            </Button>
            <Button
              sx={{ backgroundColor: 'error.main' }}
              onClick={() => navigate('/payment-method')}
              variant="contained"
            >
              Leave
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {showChangePasswordDialog && <ChangePassword onToggleChangePasswordDialog={onToggleChangePasswordDialog} />}
    </>
  );
};

export default MyAccountView;
