import { noop } from '@client/shared/constants';
import { useNavigate } from 'react-router-dom';
import { Footer } from '@client/shared/components';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import BgImage from '../../assets/images/background-top.png';

import CheckIcon from '@mui/icons-material/Check';

const SubscriptionsView = ({ products = [], isLoading = false, handleChooseSubscription = noop }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={{ minHeight: '95%' }}>
        {' '}
        <Box
          sx={{
            height: '220px',
            backgroundImage: `url(${BgImage})`,
            backgroundSize: '100% 100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            p: 3,
          }}
        >
          <Box>
            <img src="/assets/white-logo.svg" alt="my-excelia" width={220} />
          </Box>
          <Box>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'medium',
                color: 'common.white',
                fontSize: '1.25rem',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/login')}
            >
              Log In
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            p: 9,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography color="primary" variant="body2" sx={{ fontWeight: 'bold' }}>
            WELCOME!
          </Typography>
          <Typography color="primary" variant="h3" sx={{ fontSize: '42px' }}>
            Let's get started with MyExcelia.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 6,
              mt: !isLoading && 5,
              flexWrap: 'wrap',
            }}
          >
            <>
              {isLoading ? (
                <>
                  {['1', '2'].map((item) => (
                    <Skeleton key={item} sx={{ height: '560px', minWidth: '380px' }} />
                  ))}
                </>
              ) : (
                <>
                  {products.map((product, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative',
                          backgroundColor: 'primary.50',
                          alignItems: 'center',
                          padding: 5,
                          color: 'primary.main',
                          minHeight: '400px',
                          minWidth: '350px',
                        }}
                      >
                        <Typography variant="h4">{product.productName}</Typography>
                        <Divider
                          width="70%"
                          sx={{
                            m: 2,
                            borderBottomWidth: 2,
                            borderColor: 'primary.main',
                          }}
                        />
                        <Box>
                          {product.features?.map((feature, index) => {
                            return (
                              <Box sx={{ display: 'flex', gap: 1, m: 2 }} key={index}>
                                <CheckIcon />
                                <Typography sx={{ fontWeight: 'medium' }}>{feature}</Typography>
                              </Box>
                            );
                          })}
                        </Box>
                        <Button
                          variant="outlined"
                          sx={{
                            display: 'flex',
                            textAlign: 'bottom',
                            backgroundColor: 'white',
                            mt: 5,
                            position: 'absolute',
                            bottom: 33,
                            boxShadow: 1,
                          }}
                          onClick={() => handleChooseSubscription(product)}
                        >
                          GET STARTED FOR ${parseInt(product.price).toLocaleString()}/
                          {product.duration == '1 year' ? 'YEAR' : 'MONTH'}
                        </Button>
                      </Box>
                    );
                  })}
                </>
              )}
            </>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default SubscriptionsView;
