import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { isJWTExpired } from "@client/utils";
import { VALIDATIONS } from "@client/shared/constants";
import { validate as validator } from "@client/utils";
import ResetPassowordView from "./view";
import Services from "./service";

const defaultState = {
  password: "",
  confirmPassword: "",
  errors: {},
  isButtonLoading: false,
};
const ResetPassoword = () => {
  const [state, setState] = useState(defaultState);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const isAuthenticated = !isJWTExpired(token);

  const handleFieldChange = (e) => {
    const field = e.currentTarget.name;
    const value = e.currentTarget.value;
    let errorMessage = validateFields(field, value) || " ";
    // if (field === "confirmPassword" && value && state?.password !== value) {
    //   errorMessage = "Password and Confirm Password must match";
    // }
    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };
  const validateFields = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      password: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.PASSWORD, value: true },
      ],
      confirmPassword: [{ type: VALIDATIONS.REQUIRED, value: true }],
    };
    if (fieldValidatorMap[field]) {
      const validationResult = fieldValidatorMap[field].map((validation) =>
        validator(
          value,
          validation.type,
          validation.value,
          validation.inputType || "string",
          validation.message
        )
      );
      errorMessage = validationResult
        .filter((error) => !error?.isValid)
        .map((error) => error?.errorMessage)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validateFields(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }
    return errorMessage;
  };

  const handleResetPassword = async (
    newPassword = "",
    confirmPassword = ""
  ) => {
    if (newPassword !== confirmPassword) {
      return toast.error("Password and Confirm password must match.");
    }
    if (!isAuthenticated) {
      return toast.error("Your Password Reset Link has been expired.");
    }
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: true,
    }));
    const { error } = await Services.resetPassword(
      {
        newPassword,
        confirmPassword,
      },
      token
    );
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isButtonLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message || error
      );
    }
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: false,
    }));
    toast.success(
      "Your password has been reset successfully! Redirecting to login."
    );
    setTimeout(() => navigate("/login"), 2000);
    return;
  };
  const isFormValidated =
    !validateFields() && !state.errors?.confirmPassword?.trim();
  return (
    <ResetPassowordView
      password={state.password}
      errors={state.errors}
      confirmPassword={state.confirmPassword}
      isButtonLoading={state.isButtonLoading}
      isFormValidated={isFormValidated}
      handleFieldChange={handleFieldChange}
      handleResetPassword={(password, confirmPassword) =>
        handleResetPassword(password, confirmPassword)
      }
    />
  );
};

export default ResetPassoword;
