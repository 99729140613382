import { http } from "@client/shared/services";
import { responseFormatter } from "@client/utils";

const getSurveys = (queryString = "") => {
  return responseFormatter(
    http.get(`/surveys${queryString}`, {
      setAuthHeader: true,
    })
  );
};

const getSurveyDetails = (id = "", type = "", queryString = "") => {
  return responseFormatter(
    http.get(`/survey/client/${id}/observers/${type}${queryString}`, {
      setAuthHeader: true,
    })
  );
};
const getMoneyTalkDetails = (id = "", type = "") => {
  return responseFormatter(
    http.get(`/survey/client/${id}/money-talk?type=${type}`, {
      setAuthHeader: true,
    })
  );
};
const postSurvey = (payload) => {
  return responseFormatter(
    http.post(`/survey`, payload, {
      setAuthHeader: true,
    })
  );
};

const rescheduleSurvey = (payload, collectorId = "") => {
  return responseFormatter(
    http.patch(`/survey/${collectorId}`, payload, {
      setAuthHeader: true,
    })
  );
};
const getSurveyUrl = (id) => {
  return responseFormatter(
    http.get(`/survey/${id}/url `, {
      setAuthHeader: true,
    })
  );
};
const getSurveyqLinks = (id) => {
  return responseFormatter(
    http.get(`/survey-url`, {
      setAuthHeader: true,
    })
  );
};

const Service = {
  getSurveys,
  postSurvey,
  getSurveyUrl,
  rescheduleSurvey,
  getSurveyDetails,
  getMoneyTalkDetails,
  getSurveyqLinks,
};

export default Service;
