import { useState } from "react";
import ResourcesView from "./view";
import { useNavigate } from "react-router-dom";

const defaultState = {};

const ResourcesModule = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(defaultState);

  return <ResourcesView />;
};

export default ResourcesModule;
