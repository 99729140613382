import { decodeJwt } from "jose";
import {
  TOKEN_STORAGE_KEY,
  USER_TOKEN_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from "@client/shared/constants";

let handleTokenUpdate = null;

export const getTokenName = () => TOKEN_STORAGE_KEY;
export const decodeToken = (access_token = "") => {
  if (!access_token) return {};
  const decodedTokenData = decodeJwt(access_token);
  const userData = decodedTokenData;
  return userData;
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_STORAGE_KEY);
};
export const getUserToken = () => {
  return localStorage.getItem(USER_TOKEN_KEY);
};
export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
};

export const getTokenData = () => {
  return decodeToken(getToken());
};

export const setToken = (token) => {
  localStorage.setItem(TOKEN_STORAGE_KEY, token);
  if (handleTokenUpdate) {
    handleTokenUpdate();
  }
};
export const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, token);
};
export const setUserToken = (token) => {
  localStorage.setItem(USER_TOKEN_KEY, token);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
};
export const removeUserToken = () => {
  localStorage.removeItem(USER_TOKEN_KEY);
};
export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
};

export const replaceToken = (token) => setToken(token);
export const replaceRefreshToken = (token) => setRefreshToken(token);

export const isJWTExpired = (token, alreadyDecoded, time = 0) => {
  try {
    const { exp } = alreadyDecoded ? token : decodeJwt(token);
    if (Date.now() >= exp * 1000 - time * 60000) {
      return true;
    }
  } catch (err) {
    return true;
  }
  return false;
};

// eslint-disable-next-line consistent-return
export const onTokenUpdate = (callBack, accountUid) => {
  if (callBack) {
    handleTokenUpdate = callBack;
  }

  if (!callBack && handleTokenUpdate) {
    return handleTokenUpdate(accountUid);
  }
};
export const setAuthData = (tokens) => {
  setToken(tokens);
};
export const removeAuthData = () => {
  removeToken();
};
