import { Grid, Footer } from "@client/shared/components";
import { noop } from "@client/shared/constants";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styles from "./style";
const ClientDataView = ({
  rows = [],
  columnConfig = [],
  clientData = {},
  isClientsPage = false,
  pageSize = 5,
  order = null,
  orderBy = "",
  isLoading = false,
  totalEntries = 0,
  handlePageSizeChange = noop,
  handleSortChange = noop,
  handleSelectionChange = noop,
  handleGridReady = noop,
}) => {
  const avatarColours = [
    "#EA1212",
    "#FF7E28",
    "#FFAC1C",
    "#6DD400",
    "#44D7B6",
    "#32C5FF",
    "#0091FF",
    "#6236FF",
    "#B620E0",
    "#F71762",
    "#D8D8D8",
    "#D8C6A7",
    "#B6C9A2",
    "#B0A496",
    "#7E7B8F",
  ];

  const clientName =
    (clientData.clientFirstName || "-") +
    " " +
    (clientData.clientLastName || "-");
  const userInitials = clientData?.clientFirstName
    ? clientData?.clientFirstName?.charAt(0) +
      clientData?.clientLastName?.charAt(0)
    : "";

  const charIndex = userInitials.toUpperCase()?.charCodeAt(0) - 64;
  return (
    <Box sx={{ ...styles.content }}>
      <Box
        sx={{
          pt: 10,
          pl: 10,
          minHeight: "95%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          <Avatar
            children={
              !clientData.profileImage ? userInitials.toUpperCase() : null
            }
            sx={{
              width: 100,
              height: 100,
              backgroundColor: !clientData.profileImage
                ? avatarColours[charIndex % avatarColours.length]
                : null,
            }}
            src={"data:image/jpeg;base64," + clientData.profileImage}
          />
          <Box>
            <Typography
              color="primary"
              variant="h3"
              sx={{ fontWeight: "medium" }}
            >
              {clientName}
            </Typography>
            {/* <Typography variant="body1" sx={{ color: "grey.500", my: 1 }}>
            Phone: {clientData.phoneNumber || "-"}
          </Typography> */}
            <Typography variant="body1" sx={{ color: "#3E5060", mt: 1 }}>
              Email: {clientData.clientEmail || "-"}
            </Typography>
          </Box>
        </Box>

        <Box
          component={Paper}
          elevation={2}
          sx={{
            mt: 6,
            p: 3,
            borderRadius: 2,
            maxWidth: "88%",
          }}
        >
          <Typography color="primary" variant="h6">
            SURVEY RESULTS
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Grid
              hasPagination={false}
              rows={rows}
              totalRows={rows.length}
              isLoading={isLoading}
              columns={columnConfig}
              pageSize={pageSize}
              order={order}
              orderBy={orderBy}
              onPageSizeChange={handlePageSizeChange}
              onSortChange={handleSortChange}
              onSelectionChange={handleSelectionChange}
              onReady={handleGridReady}
              hasSelectAll={false}
              hasSelectMultiple={false}
              hasSelection={false}
              classes={{
                container: {
                  "max-height": isClientsPage && "600px !important",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default ClientDataView;
