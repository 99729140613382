import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { noop } from "@client/shared/constants";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import config from "@client/config";

const stripePromise = loadStripe(config.stripePublicKey);

const options = {
  mode: "payment",
  amount: 1099,
  currency: config.currency,
  setup_future_usage: "off_session",
  payment_method_types: ["card"],
  paymentMethodCreation: "manual",
};
const defaultState = {
  errorMessage: "",
  clientSecret: "",
  isPaymentButtonLoading: false,
};
const CheckoutForm = ({ handleAttatchPaymentMethod = noop }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [state, setState] = useState(defaultState);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    setState((prevState) => ({
      ...prevState,
      isPaymentButtonLoading: true,
    }));
    if (submitError) {
      return setState((prevState) => ({
        ...prevState,
        errorMessage: submitError.message,
        isPaymentButtonLoading: false,
      }));
    }
    //Create a payment method on stripe
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      elements,
    });
    if (error) {
      toast.error(error.message);
      return setState((prevState) => ({
        ...prevState,
        isPaymentButtonLoading: false,
      }));
    } else {
      if (paymentMethod.id) {
        const { error } = await handleAttatchPaymentMethod(paymentMethod.id);
        if (error) {
          return setState((prevState) => ({
            ...prevState,
            isPaymentButtonLoading: false,
          }));
        }
        toast.success("Payment Method added successfully!");
        setState((prevState) => ({
          ...prevState,
          isPaymentButtonLoading: false,
        }));
        return navigate("/account");
      }
    }
  };
  return (
    <Box sx={{ p: 3, mt: 1 }}>
      <PaymentElement />{" "}
      {state.errorMessage && (
        <Typography color="error" sx={{ mt: 2 }}>
          {state.errorMessage}
        </Typography>
      )}
      <Button
        disabled={!stripe || !elements || state.isPaymentButtonLoading}
        onClick={handleSubmit}
        variant="outlined"
        fullWidth
        sx={{
          display: "flex",
          textAlign: "bottom",
          backgroundColor: "white",
          mt: 4,
          boxShadow: 1,
        }}
      >
        {state.isPaymentButtonLoading ? (
          <CircularProgress color="inherit" size="2rem" />
        ) : (
          "Add"
        )}
      </Button>
    </Box>
  );
};

const PaymentMethod = ({ handleAttatchPaymentMethod = noop }) => {
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm handleAttatchPaymentMethod={handleAttatchPaymentMethod} />
    </Elements>
  );
};

export default PaymentMethod;
