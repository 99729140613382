import { http } from '@client/shared/services';
import { responseFormatter } from '@client/utils';

const getOrganizationUsers = (id = '', queryString) => {
  return responseFormatter(
    http.get(`/organization/${id}/users${queryString}`, {
      setAuthHeader: true,
    })
  );
};

const createUser = (payload, id) => {
  return responseFormatter(
    http.post(`/organization/${id}/user`, payload, {
      setAuthHeader: true,
    })
  );
};

const patchUser = (payload, orgId = '', userId = '') => {
  return responseFormatter(
    http.patch(`/organization/${orgId}/user/${userId}`, payload, {
      setAuthHeader: true,
    })
  );
};
const deleteUser = (orgId = '', userIds = '') => {
  return responseFormatter(
    http.delete(`/organization/${orgId}/user?ids=${userIds}`, {
      setAuthHeader: true,
    })
  );
};

const Service = {
  getOrganizationUsers,
  createUser,
  patchUser,
  deleteUser,
};

export default Service;
