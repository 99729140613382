import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Dropdown } from '@client/shared/components';
import { queryStringBuilder } from '@client/utils';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SurveysView from './view';
import Services from './service';

let gridHelper = null;

const defaultState = {
  organizations: [],
  isLoading: false,
  isButtonLoading: false,
  pageNumber: 1,
  pageSize: 30,
  totalRows: 0,
  order: null,
  orderBy: '',
  filters: [],
  selectedRow: null,
  isCreateOpen: false,
  organizationName: '',
  isActive: false,
  isCreateButtonLoading: false,
  currentRow: {},
  hasFormChanged: false,
  analysisTypesList: [],
  analysisType: {},
  searchFilter: [],
};

const ControlPanel = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(defaultState);
  let userDetails = localStorage.getItem('userDetails');
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }
  //Fetch Organizations.
  const fetchOrganizations = useCallback(
    async (pageNumber = 1, pageSize = 30, order = '', orderBy = '', searchFilter = [], filters = []) => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const queryString = queryStringBuilder(pageSize, (pageNumber - 1) * pageSize, searchFilter, filters, {
        field: orderBy,
        order,
      });
      const { data, error } = await Services.getOrganizations(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
      }
      setState((prevState) => ({
        ...prevState,
        organizations: data?.organizations,
        totalRows: data.count || data?.organizations?.length,
        availableSurveyCount: data.availableSurveyCount,
        isLoading: false,
      }));
    },
    []
  );
  //Fetch Analysis Types.
  const fetchAnalysisTypes = useCallback(async () => {
    const { data, error } = await Services.getAnalysisType();
    if (error) {
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      analysisTypesList: data?.analysisTypes,
    }));
  }, []);

  //Create new Organizations
  const handleCreateOrganization = useCallback(async (payload = {}, isEdit = false, id) => {
    setState((prevState) => ({
      ...prevState,
      isCreateButtonLoading: true,
    }));
    const api = isEdit ? 'patch' : 'create';
    const { error } = await Services[api](payload, id);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isCreateButtonLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      isCreateButtonLoading: false,
      organizationName: defaultState.organizationName,
      analysisType: defaultState.analysisType,
    }));
    handleCreateDialog(false);
    fetchOrganizations();
    return toast.success(`Organization ${!isEdit ? 'created' : 'edited'} successfully.`);
  }, []);

  //Grid handlers
  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRow: defaultState.selectedRow,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order,
      orderBy: order ? fieldObj.field || fieldObj.fieldName : null,
      selectedRow: defaultState.selectedRow,
      pageNumber: defaultState.pageNumber,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback((selectedRow) => {
    setState((prevState) => ({
      ...prevState,
      selectedRow: selectedRow[selectedRow.length - 1],
    }));
    gridHelper.setSelection([selectedRow[selectedRow.length - 1]]);
  }, []);

  //Dialog handlers
  const handleCreateDialog = useCallback((value = false, row = {}) => {
    setState((prevState) => ({
      ...prevState,
      isCreateOpen: value,
      currentRow: row,
      organizationName: row.organizationName,
      analysisType: row.defaultTypeAnalysis ? row.defaultTypeAnalysis : {},
      isActive: row.isActive,
      hasFormChanged: false,
    }));
  }, []);

  const handleFieldChange = (evt, type = '') => {
    const { name, value } = !!type ? evt : evt.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      hasFormChanged: true,
    }));
  };

  const handleSearch = (keyword = '') => {
    const searchFields = ['organizationName'];
    let searchFilter = keyword
      ? searchFields.map((element) => {
          return { field: element, value: keyword };
        })
      : [];

    setState((prevState) => ({
      ...prevState,
      searchFilter,
    }));
  };
  useEffect(() => {
    fetchOrganizations(state.pageNumber, state.pageSize, state.order, state.orderBy, state.searchFilter, state.filters);
  }, [
    fetchOrganizations,
    state.pageNumber,
    state.pageSize,
    state.order,
    state.orderBy,
    state.searchFilter,
    state.filters,
  ]);

  useEffect(() => {
    fetchAnalysisTypes();
  }, []);
  const moreOptions = [
    {
      id: 2,
      label: 'Edit',
      onClick: (row) => handleCreateDialog(true, row),
    },
  ];
  const columnConfig = [
    {
      id: 'organizationName',
      field: 'organizationName',
      label: 'ORGANIZATION NAME',
      canSort: true,
      noResize: false,
      render: (row) => {
        return (
          <Tooltip title={row.organizationName ? row.organizationName : ''} placement="top-start">
            <Typography
              variant="body2"
              sx={{
                color: 'primary.main',
                textDecoration: userDetails.permissions?.readOrgUsers && 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (userDetails.permissions?.readOrgUsers) {
                  localStorage.setItem('defaultAnalysisType', JSON.stringify(row.defaultTypeAnalysis));
                  navigate(`/control-panel/${row.id}`);
                }
              }}
              noWrap
            >
              {row.organizationName || '-'}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: 'defaultAnalysisType',
      field: 'defaultAnalysisType',
      label: 'DEFAULT ANALYSIS TYPE',
      canSort: true,
      noResize: false,
      render: (row) => {
        const defaultTypeAnalysis = row.defaultTypeAnalysis.name ? row.defaultTypeAnalysis.name : '';
        return (
          <Tooltip title={defaultTypeAnalysis} placement="top-start">
            <Typography variant="body2" noWrap>
              {defaultTypeAnalysis || '-'}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: 'createdAt',
      field: 'createdAt',
      label: 'CREATED AT',
      canSort: true,
      noResize: false,
      render: (row) => {
        const date = row.createdAt ? format(new Date(row.createdAt), 'MMMM	dd, yyyy') : '';
        return (
          <Tooltip title={date ? date : ''} placement="top-start">
            <Typography variant="body2" noWrap>
              {date || '-'}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: 'createdBy',
      field: 'createdBy',
      label: 'CREATED BY',
      canSort: true,
      noResize: false,
      render: (row) => {
        const createdBy = row.createdByUser.firstName
          ? row.createdByUser.firstName + ' ' + row.createdByUser.lastName
          : '';
        return (
          <Tooltip title={createdBy ? createdBy : ''} placement="top-start">
            <Typography variant="body2" noWrap>
              {createdBy || '-'}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: 'count',
      field: 'count',
      label: 'USER COUNT',
      canSort: false,
      noResize: false,
      render: (row) => {
        const userCount = row.organizationUsersCount ? row.organizationUsersCount : '';
        return (
          <Tooltip title={userCount ? userCount : ''} placement="top-start">
            <Typography variant="body2" noWrap>
              {userCount || '-'}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: 'isActive',
      field: 'isActive',
      label: 'IS ACTIVE',
      canSort: false,
      noResize: false,
      render: (row) => {
        const isActive = row.isActive;
        return (
          <Tooltip
            title={!userDetails.permissions?.updateOrg ? 'You do not have permission to perform this action' : ''}
            placement="top"
          >
            <Box>
              <Switch
                checked={isActive}
                sx={{ m: 1 }}
                disabled={!userDetails.permissions?.updateOrg}
                onChange={() => handleCreateOrganization({ isActive: !isActive }, true, row.id)}
              />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      id: 'col-action',
      label: '',
      isHidden: !userDetails.permissions?.updateOrg,
      render: (row) => {
        return (
          <>
            {
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Dropdown
                  customToggle={
                    <IconButton>
                      <MoreHorizIcon color="primary" fontSize="large" />
                    </IconButton>
                  }
                  options={[
                    ...moreOptions.map((option) => {
                      return {
                        label: (
                          <Tooltip title={option.label} placement="top-start">
                            <Typography
                              variant="body2"
                              sx={{
                                mx: '20px',
                                my: '4px',
                                width: '100%',
                                height: '100%',
                                fontWeight: 'bold',
                                color: 'primary.main',
                              }}
                            >
                              {option.label}
                            </Typography>
                          </Tooltip>
                        ),
                        onClick: () => option.onClick(row),
                      };
                    }),
                  ]}
                />
              </Box>
            }
          </>
        );
      },
    },
  ];
  return (
    <SurveysView
      rows={state.organizations}
      columnConfig={columnConfig}
      isLoading={state.isLoading}
      pageNumber={state.pageNumber}
      pageSize={state.pageSize}
      order={state.order}
      orderBy={state.orderBy}
      totalRows={state.totalRows}
      isButtonLoading={state.isButtonLoading}
      currentRow={state.currentRow}
      handlePageNumberChange={handlePageNumberChange}
      handlePageSizeChange={handlePageSizeChange}
      handleSortChange={handleSortChange}
      handleSelectionChange={handleSelectionChange}
      handleFieldChange={handleFieldChange}
      handleGridReady={(instance) => (gridHelper = instance)}
      handleCreateDialog={handleCreateDialog}
      isCreateOpen={state.isCreateOpen}
      handleCreateOrganization={handleCreateOrganization}
      organizationName={state.organizationName}
      isCreateButtonLoading={state.isCreateButtonLoading}
      isActive={state.isActive}
      hasFormChanged={state.hasFormChanged}
      analysisTypesList={state.analysisTypesList}
      analysisType={state.analysisType}
      userDetails={userDetails}
      handleSearch={handleSearch}
    />
  );
};

export default ControlPanel;
