import { http } from "@client/shared/services";
import { responseFormatter } from "@client/utils";

const resetPassword = (payload, token) => {
  return responseFormatter(
    http.post(`/reset-password`, payload, {
      headers: { Authorization: token },
    })
  );
};

const Services = {
  resetPassword,
};

export default Services;
