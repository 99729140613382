import { noop } from "@client/shared/constants";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const RescheduleSuccess = ({
  isSurveySuccess = false,
  handleSuccessClose = noop,
}) => {
  return (
    <>
      {!!isSurveySuccess && (
        <Dialog open={isSurveySuccess} onClose={handleSuccessClose}>
          <DialogTitle
            sx={{
              textAlign: "end",
            }}
          >
            <IconButton onClick={handleSuccessClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "500px",
              minHeight: "280px",
              p: 4,
              mb: 3,
            }}
          >
            {" "}
            <img src="/assets/success.png" alt="" />
            <Typography variant="h4" color="primary" sx={{ mt: 2, mb: 1 }}>
              Nice job!
            </Typography>
            <Typography variant="h5" color="primary">
              Your survey has been rescheduled .{" "}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default RescheduleSuccess;
