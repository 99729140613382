import { globalStyles } from "@client/shared/constants";
const { display, flex, text } = globalStyles;

const styles = {
  drawerOpen: {
    width: "250px",
    backgroundColor: "grey.50",
    ...flex.justify.between,
    border: "2px solid rgb(224,224,224)",
  },
  container: {
    width: "100%",
    flexDirection: "column",
    marginTop: "18px",
    height: "100%",
    ...display.flex,
  },
  content: {
    marginLeft: "250px",
  },
  logoContainer: {
    ...display.flex,
    ...flex.justify.center,
    px: 2,
  },
  drawerList: {
    ...display.flex,
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "18px",
    marginBottom: "24px",
  },
  drawerButtonActive: {
    width: "100%",
    padding: "8px 34px",
    color: "primary.dark",
    fontSize: "14px",
    justifyContent: "start",
  },
  drawerButtonInActive: {
    width: "100%",
    padding: "8px 34px",
    color: "primary.main",
    justifyContent: "start",
    fontSize: "14px",
  },
};

export default styles;
