import { http } from "@client/shared/services";
import { responseFormatter } from "@client/utils";

const forgotPassword = (payload) => {
  return responseFormatter(http.post(`/reset-password/link`, payload));
};

const Services = {
  forgotPassword,
};

export default Services;
