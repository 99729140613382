import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import AddPaymentView from "./view";
import Services from "./service";

const defaultState = {
  userId: "",
  isButtonLoading: false,
  isPaymentButtonLoading: false,
};
const AddPaymentMethod = ({ isExistingCustomer = false }) => {
  const [state, setState] = useState(defaultState);
  let product = localStorage.getItem("productId");
  if (product) {
    product = JSON.parse(product);
  }
  let userId = localStorage.getItem("userId");
  if (userId) {
    userId = JSON.parse(userId);
  }

  //Post api to attath the payment method to a user
  const handleAttatchPaymentMethod = useCallback(async (id) => {
    const { error } = await Services.addPaymentMethod({ id }, true);
    if (error) {
      toast.error(
        Array.isArray(error) ? error[0].error || error[0].message : error
      );
      return { error: true };
    }
    return { error: false };
  });

  return (
    <AddPaymentView
      handleAttatchPaymentMethod={handleAttatchPaymentMethod}
      isPaymentButtonLoading={state.isPaymentButtonLoading}
    />
  );
};

export default AddPaymentMethod;
