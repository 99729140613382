import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAuthData, setRefreshToken } from '@client/utils';
import { VALIDATIONS } from '@client/shared/constants';
import { validate as validator } from '@client/utils';
import LoginView from './view';
import Services from './service';
import AccountServices from '../my-account/service';

const defaultState = {
  email: '',
  password: '',
  errors: {},
  isButtonLoading: false,
};
const LoginModule = () => {
  const [state, setState] = useState(defaultState);

  const navigate = useNavigate();

  const handleFieldChange = (e) => {
    const field = e.currentTarget.name;
    const value = e.currentTarget.value;
    let errorMessage = validateFields(field, value) || ' ';

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };
  const validateFields = (field, value) => {
    let errorMessage = '';
    const fieldValidatorMap = {
      email: [
        // { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.EMAILS, value: true },
      ],
      password: [
        { type: VALIDATIONS.REQUIRED, value: true },
        // { type: VALIDATIONS.PASSWORD, value: true },
      ],
    };
    if (fieldValidatorMap[field]) {
      const validationResult = fieldValidatorMap[field].map((validation) =>
        validator(value, validation.type, validation.value, validation.inputType || 'string', validation.message)
      );
      errorMessage = validationResult.filter((error) => !error?.isValid).map((error) => error?.errorMessage)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validateFields(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }
    return errorMessage;
  };

  // Fetch Current user details and permissions.
  const fetchUser = useCallback(async () => {
    const { data, error } = await AccountServices.get();
    if (error) {
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    if (data.user) {
      const { address, userOrganizationMapping, userRole, userAnalysis, ...rest } = data.user;
      const userData = { ...rest, permissions: data.user?.userRole[0]?.role };
      localStorage.setItem('userDetails', JSON.stringify(userData));
    }
  }, []);

  const handleLogin = async (email = '', password = '') => {
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: true,
    }));
    const { error, data } = await Services.login({
      email,
      password,
    });
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isButtonLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].message : error.message || error);
    }
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: false,
    }));
    setAuthData(data?.accessToken);
    setRefreshToken(data?.refreshToken);
    fetchUser();
    return setTimeout(() => navigate('/dashboard'), 600);
  };

  return (
    <LoginView
      email={state.email}
      password={state.password}
      errors={state.errors}
      isFormValid={!!validateFields()}
      isButtonLoading={state.isButtonLoading}
      handleFieldChange={handleFieldChange}
      handleLogin={(email, password) => handleLogin(email, password)}
    />
  );
};

export default LoginModule;
