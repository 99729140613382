import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const PaymentSuccessView = ({ isExistingCustomeer = false }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        alignItems: "center",
        gap: 3,
        mt: 15,
      }}
    >
      <img src="/assets/success.png" />
      <Typography color="primary" variant="h1">
        PAYMENT SUCCESSFUL
      </Typography>
      <Divider
        width="100%"
        sx={{
          m: 1,
          borderColor: "grey.600",
          borderStyle: "dashed",
          p: 2,
        }}
      />{" "}
      {isExistingCustomeer ? (
        <Typography color="grey" variant="h6" mt={2}>
          Your payment was successful!
        </Typography>
      ) : (
        <Typography color="grey" variant="h6" mt={2}>
          Your payment was successful. Now you can login here{" "}
          <Link
            href="/login"
            sx={{ textDecoration: "underline", color: "grey.600" }}
          >
            Redirect to Login
          </Link>
        </Typography>
      )}
    </Box>
  );
};

export default PaymentSuccessView;
