import { http } from "@client/shared/services";
import { responseFormatter } from "@client/utils";

const getQuote = () => {
  return responseFormatter(
    http.get(`/inspiration-quote`, {
      setAuthHeader: true,
    })
  );
};
const getUpdates = () => {
  return responseFormatter(
    http.get(`/audit-history`, {
      setAuthHeader: true,
    })
  );
};

const Service = { getQuote, getUpdates };

export default Service;
