import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { VALIDATIONS } from "@client/shared/constants";
import { validate as validator } from "@client/utils";
import ForgotPasswordView from "./view";
import Services from "./service";
import config from "@client/config";

const defaultState = {
  email: "",
  error: "",
  isButtonLoading: false,
};
const ForgotPassword = () => {
  const [state, setState] = useState(defaultState);
  const navigate = useNavigate();

  const handleFieldChange = (e) => {
    const field = e.currentTarget.name;
    const value = e.currentTarget.value;
    let errorMessage = validateFields(field, value) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      error: errorMessage,
    }));
  };
  const validateFields = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      email: [
        // { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.EMAILS, value: true },
      ],
    };
    if (fieldValidatorMap[field]) {
      const validationResult = fieldValidatorMap[field].map((validation) =>
        validator(
          value,
          validation.type,
          validation.value,
          validation.inputType || "string",
          validation.message
        )
      );
      errorMessage = validationResult
        .filter((error) => !error?.isValid)
        .map((error) => error?.errorMessage)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validateFields(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }
    return errorMessage;
  };
  const handleForgotPassword = async (email = "") => {
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: true,
    }));
    const { error, data } = await Services.forgotPassword({
      email,
      url: `${config.appUrl}/reset-password`,
    });
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isButtonLoading: false,
      }));
      return toast.error(
        Array.isArray(error)
          ? error[0].message
          : error.message || error.reject_reason || error
      );
    }
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: false,
    }));
    toast.success("Reset Password link sent to your registered email address.");
    setTimeout(() => navigate("/login"), 1000);
    return;
  };

  return (
    <ForgotPasswordView
      email={state.email}
      error={state.error}
      isFormValid={!!validateFields()}
      isButtonLoading={state.isButtonLoading}
      handleFieldChange={handleFieldChange}
      handleForgotPassword={(email) => handleForgotPassword(email)}
    />
  );
};

export default ForgotPassword;
