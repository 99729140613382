import { noop } from "@client/shared/constants";
import format from "date-fns/format";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
const UpdatePlanDialog = ({
  products = [],
  userDetails = {},
  isSaveLoading = false,
  isCancelLoading = false,
  isEditPlanOpen = false,
  isLoading = false,
  choosenPlan = "",
  isConfirmationOpen = false,
  isConfirmationLoading = false,
  paymentMethods = [],
  isCancelConfirmationOpen = false,
  isCancelConfirmed = false,
  handleEditPlanDialog = noop,
  handleCancelPlan = noop,
  handleUpdatePlan = noop,
  handleConfirmationDialog = noop,
  handleReactivatePlan = noop,
  getPaymentMethod = noop,
  handleCancelDialog = noop,
  handleNext = noop,
}) => {
  const currentPlan = userDetails.currentSubscription
    ? userDetails?.currentSubscription?.me_portal_product?.id
    : "";
  const currentSubscriptionId = userDetails.currentSubscription
    ? userDetails?.currentSubscription?.subscriptionId
    : "";
  const scheduledPlan = userDetails.scheduledSubscription
    ? userDetails?.scheduledSubscription?.me_portal_product?.id
    : "";
  const scheduledSubscriptionId = userDetails.scheduledSubscription
    ? userDetails?.scheduledSubscription?.subscriptionId
    : "";
  const isCurrentPlanCancelled =
    !!currentSubscriptionId &&
    userDetails.currentSubscription.isCancelled &&
    !scheduledSubscriptionId;
  const hasNoPlan = !scheduledSubscriptionId && !currentSubscriptionId;
  return (
    <>
      {/* Subscription Plan Edit Dialog */}
      {!!isEditPlanOpen && (
        <Dialog open={isEditPlanOpen} maxWidth="xl" fullWidth>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {" "}
            <Typography
              variant="body1"
              sx={{ mt: 1, ml: 3, fontWeight: "bold" }}
            >
              UPDATE SUBSCRIPTION PLAN
            </Typography>
            <IconButton onClick={() => handleEditPlanDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {" "}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                maxWidth: "96%",
                overflowY: "auto",
                mb: 4,
                gap: 6,
                p: 4,
                flexWrap: "wrap",
              }}
            >
              <>
                {isLoading ? (
                  <>
                    {["1", "2"].map((item) => (
                      <Skeleton
                        key={item}
                        sx={{ height: "500px", minWidth: "380px" }}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {products.map((product, index) => {
                      // const priceText = (
                      //   <div>
                      //     &nbsp; ${product.price.split(".")[0]}
                      //     <sup>{product.price.split(".")[1]}</sup>/
                      //     {product.duration == "1 year" ? "YEAR" : "MONTH"}
                      //   </div>
                      // );
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            backgroundColor: "primary.50",
                            alignItems: "center",
                            padding: 4,
                            color: "primary.main",
                            minHeight: "380px",
                            minWidth: "350px",
                          }}
                        >
                          <Typography variant="h4">
                            {product.productName}
                          </Typography>
                          <Divider
                            width="70%"
                            sx={{
                              m: 2,
                              borderBottomWidth: 2,
                              borderColor: "primary.main",
                            }}
                          />
                          <Box>
                            {product.features?.map((feature, index) => {
                              return (
                                <Box
                                  sx={{ display: "flex", gap: 1, m: "12px" }}
                                  key={index}
                                >
                                  <CheckIcon />
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "medium" }}
                                  >
                                    {feature}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                          <Button
                            variant="outlined"
                            disabled={
                              (currentPlan === product.id ||
                                choosenPlan.id == product.id ||
                                (scheduledPlan === product.id &&
                                  !!userDetails.scheduledSubscription
                                    ?.startDate)) &&
                              !isCurrentPlanCancelled
                            }
                            sx={{
                              display: "flex",
                              textAlign: "bottom",
                              backgroundColor: "white",
                              position: "absolute",
                              bottom: 65,
                              boxShadow: 1,
                            }}
                            onClick={() => {
                              userDetails.firstTimeUser
                                ? handleUpdatePlan(
                                    product,
                                    userDetails.firstTimeUser
                                  )
                                : handleConfirmationDialog(true, product);
                            }}
                          >
                            {isCurrentPlanCancelled &&
                            currentPlan === product.id ? (
                              "REACTIVATE CURRENT PLAN"
                            ) : currentPlan === product.id ? (
                              "CURRENT PLAN"
                            ) : scheduledPlan === product.id &&
                              !!userDetails.scheduledSubscription?.startDate ? (
                              "SCHEDULED PLAN"
                            ) : isSaveLoading &&
                              choosenPlan.id == product.id ? (
                              <CircularProgress color="inherit" size="1.5rem" />
                            ) : (
                              <>
                                {hasNoPlan
                                  ? "GET STARTED"
                                  : "SWITCH TO THIS PLAN"}{" "}
                                FOR ${parseInt(product.price).toLocaleString()}/
                                {product.duration == "1 year"
                                  ? "YEAR"
                                  : "MONTH"}
                              </>
                            )}
                          </Button>
                          <Typography
                            sx={{
                              position: "absolute",
                              bottom: 35,
                            }}
                          >
                            {currentPlan === product.id
                              ? `Valid until: ${format(
                                  new Date(
                                    userDetails.currentSubscription?.endDate
                                  ),
                                  "MM/dd/yyyy"
                                )}`
                              : scheduledPlan === product.id &&
                                !!userDetails.scheduledSubscription?.startDate
                              ? `Starting from: ${format(
                                  new Date(
                                    userDetails.scheduledSubscription?.startDate
                                  ),
                                  "MM/dd/yyyy"
                                )}`
                              : ""}
                          </Typography>
                          {((currentPlan === product.id &&
                            !scheduledSubscriptionId) ||
                            scheduledPlan === product.id) &&
                            !hasNoPlan &&
                            !(
                              !currentSubscriptionId &&
                              scheduledSubscriptionId &&
                              !userDetails.scheduledSubscription?.startDate
                            ) &&
                            !isCurrentPlanCancelled && (
                              <Typography
                                variant="body1"
                                onClick={() => {
                                  handleNext(false);
                                  handleCancelDialog(true);
                                }}
                                sx={{
                                  position: "absolute",
                                  bottom: 7,
                                  cursor:
                                    !scheduledSubscriptionId && !currentPlan
                                      ? "none"
                                      : "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                Cancel
                              </Typography>
                            )}
                        </Box>
                      );
                    })}
                  </>
                )}
              </>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button
              sx={{ color: "grey.500" }}
              onClick={() => handleEditPlanDialog(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {!!isConfirmationOpen && (
        <Dialog open={isConfirmationOpen} maxWidth="md">
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {" "}
            <Typography variant="body1" sx={{ mt: 1, fontWeight: "bold" }}>
              {isCurrentPlanCancelled && currentPlan == choosenPlan.id
                ? "REACTIVATE CURRENT PLAN"
                : "PAYMENT INFO"}
            </Typography>
            <IconButton onClick={() => handleConfirmationDialog(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {isCurrentPlanCancelled &&
            currentPlan == choosenPlan.id &&
            !!paymentMethods?.id ? (
              <Typography variant="h6" sx={{ mt: 2 }}>
                Are you sure you want to reactivate your current cancelled plan?
              </Typography>
            ) : paymentMethods && paymentMethods.id ? (
              <Box>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Your default payment method will be charged for switching to
                  the {choosenPlan.productName} plan. Are you sure you want to
                  switch to this plan?
                </Typography>
                {getPaymentMethod(true)}
                <Typography variant="body1" sx={{ color: "grey.700" }}>
                  If you want to switch the above payment method please change
                  the default payment method in "Update Billing Info" section.
                </Typography>
              </Box>
            ) : !paymentMethods || !paymentMethods.id ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {" "}
                <WarningIcon color="warning" sx={{ mr: 2 }} fontSize="large" />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  No payment methods were found. Please add a payment method in
                  "Update Billing Info" section.
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button
              sx={{ color: "grey.500" }}
              onClick={() => handleConfirmationDialog(false)}
            >
              {paymentMethods && paymentMethods.id ? "Cancel" : "Ok"}
            </Button>
            {!!paymentMethods && paymentMethods.id && (
              <Button
                variant="contained"
                sx={{ backgroundColor: "primary.main" }}
                disabled={isConfirmationLoading}
                onClick={() => {
                  isCurrentPlanCancelled && currentPlan == choosenPlan.id
                    ? handleReactivatePlan(currentSubscriptionId)
                    : handleUpdatePlan(choosenPlan, false, hasNoPlan);
                }}
              >
                {isConfirmationLoading ? (
                  <CircularProgress color="inherit" size="1.5rem" />
                ) : (
                  "Continue"
                )}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {!!isCancelConfirmationOpen && (
        <Dialog open={isCancelConfirmationOpen} maxWidth="lg">
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: 0,
            }}
          >
            <IconButton
              onClick={() => handleCancelDialog(false)}
              sx={{ pt: 2, pr: 2 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              minHeight: "140px",
              minWidth: "520px",
              textAlign: "center",
            }}
          >
            {" "}
            <Typography variant="h4" color="primary">
              We hate to see you go!
            </Typography>
            {isCancelConfirmed ? (
              <Typography color="primary" variant="body1" sx={{ mt: 5 }}>
                Are you sure you want to cancel your subscription?
              </Typography>
            ) : (
              <>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ mt: 5, mb: 1, fontWeight: "bold" }}
                >
                  Do you need additional support from the MyExcelia team?
                </Typography>
                <Typography color="primary" variant="body1">
                  We are here to help. Email us at{" "}
                  <a href="mailto:support@myexcelia.com">
                    support@myexcelia.com
                  </a>
                </Typography>
              </>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              p: isCancelConfirmed ? 0 : 4,
              pb: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            {isCancelConfirmed ? (
              <>
                <Button
                  sx={{
                    color: "primary.dark",
                    backgroundColor: "primary.200",
                    width: "65%",
                    ":hover": {
                      color: "primary.dark",
                      backgroundColor: "primary.200",
                      opacity: "0.7",
                    },
                  }}
                  onClick={() => {
                    handleNext(false);
                    handleCancelDialog(false);
                  }}
                  disabled={isCancelLoading}
                >
                  NO, I'D LIKE TO KEEP MY SUBSCRIPTION
                </Button>
                {isCancelLoading ? (
                  <CircularProgress color="inherit" size="1.5rem" />
                ) : (
                  <Typography
                    variant="body1"
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "error.dark",
                    }}
                    onClick={() => {
                      handleCancelPlan(
                        scheduledSubscriptionId
                          ? scheduledSubscriptionId
                          : currentSubscriptionId,
                        !!scheduledSubscriptionId
                      );
                    }}
                  >
                    Yes, please cancel my subscription
                  </Typography>
                )}
              </>
            ) : (
              <Button
                sx={{
                  color: "primary.dark",
                  backgroundColor: "primary.200",
                  width: "50%",
                  ":hover": {
                    color: "primary.dark",
                    backgroundColor: "primary.200",
                    opacity: "0.7",
                  },
                }}
                onClick={() => handleNext(true)}
              >
                NEXT
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UpdatePlanDialog;
