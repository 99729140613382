import { useState } from "react";
import { toast } from "react-toastify";
import { validate as validator } from "@client/utils";
import { noop, VALIDATIONS } from "@client/shared/constants";
import Services from "./service";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const defaultState = {
  isLoading: false,
  password: "",
  confirmPassword: "",
  showPassword: false,
  showConfirmPassword: false,
  errors: {
    password: " ",
    confirmPassword: " ",
  },
};

const ChangePassword = ({ onToggleChangePasswordDialog = noop }) => {
  const [state, setState] = useState(defaultState);

  const validateFields = (field, value, isBilling = false) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      password: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.PASSWORD, value: true },
      ],
      confirmPassword: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.PASSWORD, value: true },
      ],
    };
    if (fieldValidatorMap[field]) {
      const validationResult = fieldValidatorMap[field].map((validation) =>
        validator(
          value,
          validation.type,
          validation.value,
          validation.inputType || "string",
          validation.message
        )
      );
      errorMessage = validationResult
        .filter((error) => !error?.isValid)
        .map((error) => error?.errorMessage)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validateFields(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }
    return errorMessage;
  };

  const handleChangePassword = async (password) => {
    if (state.password !== state.confirmPassword) {
      return toast.error("Password and Confirm Password must match");
    }
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const { error } = await Services.patchUser({ password });
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].error || error[0].message : error
      );
    }
    toast.success("Password updated successfully.");
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    onToggleChangePasswordDialog(false, true);
  };

  const handleFieldChange = (evt) => {
    const { name, value } = evt.currentTarget || evt.target;
    let errorMessage = validateFields(name, value) || " ";
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: errorMessage,
      },
    }));
  };

  return (
    <Dialog open maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {" "}
        <Typography sx={{ mt: 1, fontWeight: "bold" }}>
          CHANGE PASSWORD
        </Typography>
        <IconButton onClick={() => onToggleChangePasswordDialog(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: 430,
          mt: 1,
          minHeight: 200,
        }}
      >
        {!state.isLoading ? (
          <Box>
            <Typography variant="subtitle2">Password</Typography>
            <TextField
              size="small"
              margin="normal"
              placeholder="Password"
              type={state.showPassword ? "text" : "password"}
              value={state.password}
              error={!!state.errors.password?.trim()}
              helperText={state.errors.password || " "}
              name="password"
              autoComplete="new-password"
              onChange={(event) => handleFieldChange(event)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        showPassword: !prevState.showPassword,
                      }));
                    }}
                  >
                    {state.showPassword ? (
                      <VisibilityOutlinedIcon sx={{ fontSize: 20 }} />
                    ) : (
                      <VisibilityOffOutlinedIcon sx={{ fontSize: 20 }} />
                    )}
                  </IconButton>
                ),
              }}
            />
          </Box>
        ) : (
          <Skeleton sx={{ height: 64 }} />
        )}
        {!state.isLoading ? (
          <Box>
            <Typography variant="subtitle2">Confirm Password</Typography>
            <TextField
              size="small"
              margin="normal"
              placeholder="Confirm Password"
              type={state.showConfirmPassword ? "text" : "password"}
              value={state.confirmPassword}
              error={!!state.errors.confirmPassword?.trim()}
              helperText={state.errors.confirmPassword || " "}
              name="confirmPassword"
              onChange={(event) => handleFieldChange(event)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        showConfirmPassword: !prevState.showConfirmPassword,
                      }));
                    }}
                  >
                    {state.showConfirmPassword ? (
                      <VisibilityOutlinedIcon sx={{ fontSize: 20 }} />
                    ) : (
                      <VisibilityOffOutlinedIcon sx={{ fontSize: 20 }} />
                    )}
                  </IconButton>
                ),
              }}
            />
          </Box>
        ) : (
          <Skeleton sx={{ height: 64 }} />
        )}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          sx={{ color: "grey.500" }}
          onClick={() => onToggleChangePasswordDialog(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            state.isLoading ||
            !!validateFields() ||
            Object.values(state.errors).some((value) => !!(value || "").trim())
          }
          onClick={() => handleChangePassword(state.password)}
          sx={{ backgroundColor: "primary.main" }}
        >
          {" "}
          {state.isLoading ? (
            <CircularProgress color="inherit" size="1.5rem" />
          ) : (
            "Change"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePassword;
